/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const deleteBox = /* GraphQL */ `
  mutation DeleteBox($bannerId: String!, $boxId: String!) {
    deleteBox(bannerId: $bannerId, boxId: $boxId) {
      bannerId
      boxId
      boxStatus
      description {
        en
        ita
        __typename
      }
      products {
        attribute
        certifications
        description {
          en
          ita
          __typename
        }
        discount {
          discountPercentage
          discountPrice
          __typename
        }
        format {
          boxType
          boxTypeQuantity
          boxWeight
          description {
            en
            ita
            __typename
          }
          formatQuantity
          formatType
          unit
          unitPrice
          unitQuantity
          __typename
        }
        isNew
        isSoldOut
        isThisYear
        line
        name {
          en
          ita
          __typename
        }
        price
        productId
        productStatus
        productType
        roleType
        selectedQuantity
        sizeType
        users
        __typename
      }
      status
      title {
        en
        ita
        __typename
      }
      __typename
    }
  }
`;
export const deleteCart = /* GraphQL */ `
  mutation DeleteCart($cartId: String!, $userId: String!) {
    deleteCart(cartId: $cartId, userId: $userId) {
      cartId
      products {
        attribute
        certifications
        description
        discount {
          discountPercentage
          discountPrice
          __typename
        }
        format {
          boxType
          boxTypeQuantity
          boxWeight
          description
          formatQuantity
          formatType
          unit
          unitPrice
          unitQuantity
          __typename
        }
        isNew
        isSoldOut
        isThisYear
        line
        name
        price
        productId
        productType
        selectedQuantity
        sizeType
        users
        __typename
      }
      userId
      __typename
    }
  }
`;
export const deleteFF = /* GraphQL */ `
  mutation DeleteFF($ffId: String!) {
    deleteFF(ffId: $ffId) {
      ff
      ffId
      users
      __typename
    }
  }
`;
export const deleteHomeCard = /* GraphQL */ `
  mutation DeleteHomeCard($homeCardId: String!) {
    deleteHomeCard(homeCardId: $homeCardId) {
      color
      homeCardId
      info {
        description {
          en
          ita
          __typename
        }
        navigateTo
        priority
        status
        title {
          en
          ita
          __typename
        }
        __typename
      }
      name {
        en
        ita
        __typename
      }
      priority
      status
      type
      users
      __typename
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder($orderId: String!, $userId: String!) {
    deleteOrder(orderId: $orderId, userId: $userId) {
      address {
        addressId
        city
        country
        email
        formattedAddress
        info
        isDefault
        lat
        lng
        number
        phone
        recipient
        region
        street
        userId
        zipCode
        __typename
      }
      endDate
      estimateDate
      info {
        en
        ita
        __typename
      }
      orderId
      orderName
      payment {
        creditCard {
          cardHolder
          cardNumber
          cvv
          expirationDate
          __typename
        }
        method
        paymentId
        status
        totalPrice
        totalProductsPrice
        totalShipping
        __typename
      }
      products {
        attribute
        certifications
        description {
          en
          ita
          __typename
        }
        discount {
          discountPercentage
          discountPrice
          __typename
        }
        format {
          boxType
          boxTypeQuantity
          boxWeight
          description {
            en
            ita
            __typename
          }
          formatQuantity
          formatType
          unit
          unitPrice
          unitQuantity
          __typename
        }
        isNew
        isSoldOut
        isThisYear
        line
        name {
          en
          ita
          __typename
        }
        price
        productId
        productStatus
        productType
        roleType
        selectedQuantity
        sizeType
        users
        __typename
      }
      role
      startDate
      status
      tracking {
        status
        update
        __typename
      }
      userId
      __typename
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct($productId: String!, $roleType: String!) {
    deleteProduct(productId: $productId, roleType: $roleType) {
      attribute
      certifications
      description {
        en
        ita
        __typename
      }
      discount {
        discountPercentage
        discountPrice
        __typename
      }
      format {
        boxType
        boxTypeQuantity
        boxWeight
        description {
          en
          ita
          __typename
        }
        formatQuantity
        formatType
        unit
        unitPrice
        unitQuantity
        __typename
      }
      isNew
      isSoldOut
      isThisYear
      line
      name {
        en
        ita
        __typename
      }
      price
      productId
      productStatus
      productType
      roleType
      selectedQuantity
      sizeType
      users
      __typename
    }
  }
`;
export const lambdaCheckoutOrderStripe = /* GraphQL */ `
  mutation LambdaCheckoutOrderStripe($order: OrderInput!) {
    lambdaCheckoutOrderStripe(order: $order)
  }
`;
export const lambdaUpdateAddress = /* GraphQL */ `
  mutation LambdaUpdateAddress($address: AddressInput) {
    lambdaUpdateAddress(address: $address) {
      addressId
      city
      country
      email
      formattedAddress
      info
      isDefault
      lat
      lng
      number
      phone
      recipient
      region
      street
      userId
      zipCode
      __typename
    }
  }
`;
export const lambdaUpdateCart = /* GraphQL */ `
  mutation LambdaUpdateCart($products: [ProductInput!]!, $role: String) {
    lambdaUpdateCart(products: $products, role: $role) {
      cartId
      products {
        attribute
        certifications
        description
        discount {
          discountPercentage
          discountPrice
          __typename
        }
        format {
          boxType
          boxTypeQuantity
          boxWeight
          description
          formatQuantity
          formatType
          unit
          unitPrice
          unitQuantity
          __typename
        }
        isNew
        isSoldOut
        isThisYear
        line
        name
        price
        productId
        productType
        selectedQuantity
        sizeType
        users
        __typename
      }
      userId
      __typename
    }
  }
`;
export const lambdaUpdateOrder = /* GraphQL */ `
  mutation LambdaUpdateOrder($order: OrderInput) {
    lambdaUpdateOrder(order: $order) {
      address {
        addressId
        city
        country
        email
        formattedAddress
        info
        isDefault
        lat
        lng
        number
        phone
        recipient
        region
        street
        userId
        zipCode
        __typename
      }
      endDate
      estimateDate
      info
      orderId
      orderName
      payment {
        creditCard {
          cardHolder
          cardNumber
          cvv
          expirationDate
          __typename
        }
        method
        paymentId
        status
        totalPrice
        totalProductsPrice
        totalShipping
        __typename
      }
      products {
        attribute
        certifications
        description
        discount {
          discountPercentage
          discountPrice
          __typename
        }
        format {
          boxType
          boxTypeQuantity
          boxWeight
          description
          formatQuantity
          formatType
          unit
          unitPrice
          unitQuantity
          __typename
        }
        isNew
        isSoldOut
        isThisYear
        line
        name
        price
        productId
        productType
        selectedQuantity
        sizeType
        users
        __typename
      }
      role
      startDate
      status
      tracking {
        status
        update
        __typename
      }
      userId
      __typename
    }
  }
`;
export const sendEmail = /* GraphQL */ `
  mutation SendEmail($email: EmailInput!) {
    sendEmail(email: $email)
  }
`;
export const updateAddress = /* GraphQL */ `
  mutation UpdateAddress($address: AddressInput!) {
    updateAddress(address: $address) {
      addressId
      city
      country
      email
      formattedAddress
      info
      isDefault
      lat
      lng
      number
      phone
      recipient
      region
      street
      userId
      zipCode
      __typename
    }
  }
`;
export const updateBox = /* GraphQL */ `
  mutation UpdateBox($box: FinalBoxInput!) {
    updateBox(box: $box) {
      bannerId
      boxId
      boxStatus
      description {
        en
        ita
        __typename
      }
      products {
        attribute
        certifications
        description {
          en
          ita
          __typename
        }
        discount {
          discountPercentage
          discountPrice
          __typename
        }
        format {
          boxType
          boxTypeQuantity
          boxWeight
          description {
            en
            ita
            __typename
          }
          formatQuantity
          formatType
          unit
          unitPrice
          unitQuantity
          __typename
        }
        isNew
        isSoldOut
        isThisYear
        line
        name {
          en
          ita
          __typename
        }
        price
        productId
        productStatus
        productType
        roleType
        selectedQuantity
        sizeType
        users
        __typename
      }
      status
      title {
        en
        ita
        __typename
      }
      __typename
    }
  }
`;
export const updateCart = /* GraphQL */ `
  mutation UpdateCart($cart: FinalCartInput!) {
    updateCart(cart: $cart) {
      cartId
      products {
        attribute
        certifications
        description {
          en
          ita
          __typename
        }
        discount {
          discountPercentage
          discountPrice
          __typename
        }
        format {
          boxType
          boxTypeQuantity
          boxWeight
          description {
            en
            ita
            __typename
          }
          formatQuantity
          formatType
          unit
          unitPrice
          unitQuantity
          __typename
        }
        isNew
        isSoldOut
        isThisYear
        line
        name {
          en
          ita
          __typename
        }
        price
        productId
        productStatus
        productType
        roleType
        selectedQuantity
        sizeType
        users
        __typename
      }
      userId
      __typename
    }
  }
`;
export const updateFF = /* GraphQL */ `
  mutation UpdateFF($ff: FFInput!) {
    updateFF(ff: $ff) {
      ff
      ffId
      users
      __typename
    }
  }
`;
export const updateHistoryOrder = /* GraphQL */ `
  mutation UpdateHistoryOrder($order: FinalOrderInput!) {
    updateHistoryOrder(order: $order) {
      address {
        addressId
        city
        country
        email
        formattedAddress
        info
        isDefault
        lat
        lng
        number
        phone
        recipient
        region
        street
        userId
        zipCode
        __typename
      }
      endDate
      estimateDate
      info {
        en
        ita
        __typename
      }
      orderId
      orderName
      payment {
        creditCard {
          cardHolder
          cardNumber
          cvv
          expirationDate
          __typename
        }
        method
        paymentId
        status
        totalPrice
        totalProductsPrice
        totalShipping
        __typename
      }
      products {
        attribute
        certifications
        description {
          en
          ita
          __typename
        }
        discount {
          discountPercentage
          discountPrice
          __typename
        }
        format {
          boxType
          boxTypeQuantity
          boxWeight
          description {
            en
            ita
            __typename
          }
          formatQuantity
          formatType
          unit
          unitPrice
          unitQuantity
          __typename
        }
        isNew
        isSoldOut
        isThisYear
        line
        name {
          en
          ita
          __typename
        }
        price
        productId
        productStatus
        productType
        roleType
        selectedQuantity
        sizeType
        users
        __typename
      }
      role
      startDate
      status
      tracking {
        status
        update
        __typename
      }
      userId
      __typename
    }
  }
`;
export const updateHomeCard = /* GraphQL */ `
  mutation UpdateHomeCard($homeCard: FinalHomeCardInput!) {
    updateHomeCard(homeCard: $homeCard) {
      color
      homeCardId
      info {
        description {
          en
          ita
          __typename
        }
        navigateTo
        priority
        status
        title {
          en
          ita
          __typename
        }
        __typename
      }
      name {
        en
        ita
        __typename
      }
      priority
      status
      type
      users
      __typename
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder($order: FinalOrderInput!) {
    updateOrder(order: $order) {
      address {
        addressId
        city
        country
        email
        formattedAddress
        info
        isDefault
        lat
        lng
        number
        phone
        recipient
        region
        street
        userId
        zipCode
        __typename
      }
      endDate
      estimateDate
      info {
        en
        ita
        __typename
      }
      orderId
      orderName
      payment {
        creditCard {
          cardHolder
          cardNumber
          cvv
          expirationDate
          __typename
        }
        method
        paymentId
        status
        totalPrice
        totalProductsPrice
        totalShipping
        __typename
      }
      products {
        attribute
        certifications
        description {
          en
          ita
          __typename
        }
        discount {
          discountPercentage
          discountPrice
          __typename
        }
        format {
          boxType
          boxTypeQuantity
          boxWeight
          description {
            en
            ita
            __typename
          }
          formatQuantity
          formatType
          unit
          unitPrice
          unitQuantity
          __typename
        }
        isNew
        isSoldOut
        isThisYear
        line
        name {
          en
          ita
          __typename
        }
        price
        productId
        productStatus
        productType
        roleType
        selectedQuantity
        sizeType
        users
        __typename
      }
      role
      startDate
      status
      tracking {
        status
        update
        __typename
      }
      userId
      __typename
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct($product: FinalProductInput!) {
    updateProduct(product: $product) {
      attribute
      certifications
      description {
        en
        ita
        __typename
      }
      discount {
        discountPercentage
        discountPrice
        __typename
      }
      format {
        boxType
        boxTypeQuantity
        boxWeight
        description {
          en
          ita
          __typename
        }
        formatQuantity
        formatType
        unit
        unitPrice
        unitQuantity
        __typename
      }
      isNew
      isSoldOut
      isThisYear
      line
      name {
        en
        ita
        __typename
      }
      price
      productId
      productStatus
      productType
      roleType
      selectedQuantity
      sizeType
      users
      __typename
    }
  }
`;
