/* eslint-disable no-useless-escape */
import {
  Box,
  Button,
  Text,
  HStack,
  Select,
  SimpleGrid,
  Spinner,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { ProductCard } from "../../components/card/product-card/ProductCard";
import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { catalogApi } from "../../services/catalog-api/catalog-api";
import { Icons } from "../../images/svg";
import {
  catalogDefaultData,
  useContextCatalog,
} from "../../contexts/catalog/catalog";
import { cartApi } from "../../services/cart-api/cart-api";
import { Cart } from "../../API";
import { LanguageContext } from "../../contexts/languageContext";
import { useTranslation } from "react-i18next";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";

const { REACT_APP_CDN } = process.env;

const Catalog = () => {
  const { language } = useContext(LanguageContext);
  const { t } = useTranslation();
  const [selectedLine, setSelectedLine] = useState<string | null>(null);
  const [selectedOilType, setSelectedOilType] = useState<string | null>(null);
  const toast = useToast();
  const { user } = useAuthenticator((context) => [context.user]);
  const queryClient = useQueryClient();

  const role = user?.attributes?.["custom:role"] || "standard";
  const sub = user?.username || "";
  const idRole = role === "standard" ? "standard" : `${sub}`;
  const [initialCatalog, setInitialCatalog] = useState(catalogDefaultData);
  const { catalog, setCatalog } = useContextCatalog();

  useEffect(() => {
    async function fetchData() {
      await Auth.currentAuthenticatedUser({ bypassCache: true });
    }
    fetchData();
  }, []);

  const { mutate: mutateUpdateCart, isLoading: isLoadingUpdateCart } =
    useMutation(
      async () => {
        const response = await cartApi.updateCart({
          products: catalog.products,
          role: idRole,
        });
        return response;
      },
      {
        onError: (error) => {
          console.log("[productCard-updateCart]", error);
          toast({
            title: t("catalog.updateCartError.title"),
            description: t("catalog.updateCartError.description"),
            status: "error",
          });
        },
        onSuccess: async (response) => {
          setInitialCatalog((prevCatalog) => ({
            ...prevCatalog,
            products: catalog.products,
          }));
          cart.refetch();
          toast({
            title: t("catalog.updateCartSuccess.title"),
            description: t("catalog.updateCartSuccess.description"),
            status: "success",
          });
        },
      }
    );

  const { mutate: mutateDeleteCart, isLoading: isLoadingDeleteCart } =
    useMutation(
      async () => {
        const response = await cartApi.deleteCart({
          cartId: catalog.cartId,
          userId: sub,
        });
        return response;
      },
      {
        onError: (error) => {
          console.log("[productCard-deleteCart]", error);
          toast({
            title: t("catalog.removeAllCartError.title"),
            description: t("catalog.removeAllCartError.description"),
            status: "error",
          });
        },
        onSuccess: async (response) => {
          queryClient.invalidateQueries([
            "getCatalogByUserId",
            language,
            idRole,
          ]);

          toast({
            title: t("catalog.removeAllCartSuccess.title"),
            description: t("catalog.removeAllCartSuccess.description"),
            status: "success",
          });
        },
      }
    );

  const mergeProducts = (cartProducts: Cart | null) => {
    const mergedProducts = catalog.products.map((product) => {
      const cartProduct = cartProducts?.products.find(
        (p) => p.productId === product.productId
      );
      if (cartProduct) {
        return {
          ...product,
          selectedQuantity: cartProduct.selectedQuantity,
        };
      }
      return product;
    });
    setCatalog((prevCatalog) => ({
      ...prevCatalog,
      products: mergedProducts,
      cartId: cartProducts?.cartId || "",
    }));
    setInitialCatalog((prevCatalog) => ({
      ...prevCatalog,
      products: mergedProducts,
      cartId: cartProducts?.cartId || "",
    }));
  };

  const cart = useQuery(
    ["getCartByUserId", sub, language],
    () =>
      cartApi.getCartByUserId({
        userId: sub,
        lang: language,
      }),
    {
      enabled: false,
      onSuccess: (data) => {
        mergeProducts(data);
      },
    }
  );

  const catalogProducts = useQuery(
    ["getCatalogByUserId", language, idRole],
    () => catalogApi.getCatalogByUserId({ lang: language, roleType: idRole }),
    {
      onSuccess: (data) => {
        setCatalog((prevCatalog) => ({
          products: data,
          cartId: prevCatalog.cartId,
        }));
        cart.refetch();
      },
    }
  );

  // Subscriptions
  useEffect(() => {
    const subscription = catalogApi.onCatalogUpdated().subscribe({
      next: (e: any) => {
        queryClient.invalidateQueries(["getCatalogByUserId", language, idRole]);
      },
      error: (error: any) => {
        console.error("Catalog Subscription error", error);
      },
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [idRole, language, queryClient]);

  if (
    catalogProducts.isLoading ||
    cart.isLoading ||
    isLoadingUpdateCart ||
    isLoadingDeleteCart
  ) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (catalogProducts.isError || cart.isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        pt={{ base: "140px", md: "90px", xl: "90px" }}
      >
        <Icons.Error width={500} height={500} />
      </Box>
    );
  }

  if (
    catalogProducts.isSuccess &&
    cart.isSuccess &&
    catalog.products.length === 0
  ) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50%"
        pt={{ base: "140px", md: "90px", xl: "90px" }}
      >
        <Text fontSize="2xl" fontWeight="bold">
          {t("catalog.emptyItems")}
        </Text>
      </Box>
    );
  }

  const handleLineFilter = (line: string | null) => {
    setSelectedLine((prevSelectedLine) =>
      prevSelectedLine === line ? null : line
    );
  };

  const handleOilTypeFilter = (oilType: string | null) => {
    setSelectedOilType((prevSelectedOilType) =>
      prevSelectedOilType === oilType ? null : oilType
    );
  };

  const filteredProducts = catalog.products.filter((product) => {
    const lineFilterPassed = !selectedLine || product.line === selectedLine;
    const oilTypeFilterPassed =
      !selectedOilType ||
      product.productType.toLocaleLowerCase() === selectedOilType;
    return lineFilterPassed && oilTypeFilterPassed;
  });

  const oilTypeLabel = {
    oil: t("catalog.oil") as string,
    olive: t("catalog.olive") as string,
  };

  return (
    <Box pt={{ base: "140px", md: "90px", xl: "90px" }}>
      <Stack>
        <HStack>
          <Select
            placeholder={t("catalog.line") as string}
            value={selectedLine || undefined}
            onChange={(e) => handleLineFilter(e.target.value || null)}
            maxW="25%"
            color="white"
            fontWeight="bold"
            bgColor="green.600"
            _hover={{ bg: "green.400", color: "white" }}
          >
            {Array.from(new Set(catalog.products.map((item) => item.line))).map(
              (line) => (
                <option key={line} value={line}>
                  {line as string}
                </option>
              )
            )}
          </Select>

          <Select
            placeholder={t("catalog.product") as string}
            value={selectedOilType || undefined}
            onChange={(e) => handleOilTypeFilter(e.target.value || null)}
            maxW="25%"
            color="white"
            fontWeight="bold"
            bgColor="green.600"
            _hover={{ bg: "green.400", color: "white" }}
          >
            {Array.from(
              new Set(
                catalog.products.map((item) =>
                  item.productType.toLocaleLowerCase()
                )
              )
            ).map((oilType) => (
              <option key={oilType} value={oilType}>
                {oilTypeLabel[oilType as "oil" | "olive"]}
              </option>
            ))}
          </Select>
        </HStack>

        <SimpleGrid
          columns={{
            base: 1,
            md: 2,
            lg: 3,
            xl: 4,
          }}
          mt={6}
          mb={32}
          spacing={4}
        >
          {filteredProducts.map((item) => {
            const path =
              item.productType.toLowerCase() === "oil" ? "oil" : "olive";
            const image = `${REACT_APP_CDN}/${path}/${item.productId}.png`;
            if (item.name.includes("Mostrino")) {
              console.log("image", image);
            }

            return (
              <ProductCard
                key={item.productId}
                product={item}
                cardImage={image}
              />
            );
          })}
        </SimpleGrid>

        <HStack
          position="fixed"
          bottom={0}
          left={{ base: 0, md: 60 }}
          right={0}
          p={4}
          zIndex="sticky"
          bgColor="green.200"
        >
          <Button
            flex={1}
            colorScheme="teal"
            variant="outline"
            onClick={() => mutateDeleteCart()}
            isDisabled={!catalog.cartId}
          >
            {t("catalog.removeAllToCart") as string}
          </Button>
          <Button
            colorScheme="teal"
            flex={1}
            mt={0}
            onClick={() => mutateUpdateCart()}
            // disable sum of selectedQuantity of catalog and initialCatalog
            isDisabled={
              catalog.products.reduce(
                (acc, curr) => acc + curr.selectedQuantity,
                0
              ) ===
              initialCatalog.products.reduce(
                (acc, curr) => acc + curr.selectedQuantity,
                0
              )
            }
          >
            {t("catalog.addToCart") as string}
          </Button>
        </HStack>
      </Stack>
    </Box>
  );
};

export default Catalog;
