import {
  IconButton,
  Avatar,
  Box,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Icon,
  useColorModeValue,
  Text,
  Drawer,
  DrawerContent,
  useDisclosure,
  BoxProps,
  FlexProps,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Image,
} from "@chakra-ui/react";
import {
  FiHome,
  FiList,
  // FiSettings,
  FiMenu,
  // FiBell,
  FiChevronDown,
  FiBookOpen,
} from "react-icons/fi";
import { PiTreeDuotone, PiShoppingCartBold } from "react-icons/pi";
import { TbTruckDelivery } from "react-icons/tb";
import { IconType } from "react-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { Images } from "../../images";
import { useQueryClient } from "@tanstack/react-query";
import { FF } from "../../API";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import FlagMenu from "../menu/flagMenu";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";

interface LinkItemProps {
  name: string;
  icon: IconType;
  link: string;
  value: string;
}

interface NavItemProps extends FlexProps {
  icon: IconType;
  children: React.ReactNode;
  isactive: boolean;
}

interface MobileProps extends FlexProps {
  onOpen: () => void;
  name: string;
}

interface SidebarProps extends BoxProps {
  onClose: () => void;
}

const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const data = queryClient.getQueriesData<FF[]>(["getFFByUserId"]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, ffs] = data[0];
  const adoptTree = ffs?.find((ff) => ff.ff === "adoptTree");

  const handleItemClick = (link: LinkItemProps) => {
    navigate(link.link);
    onClose();
  };

  const LinkItems: Array<LinkItemProps> = [
    { name: "sidebar.home", icon: FiHome, link: "/home", value: "/home" },
    {
      name: "sidebar.history",
      icon: FiBookOpen,
      link: "history",
      value: "/history",
    },
    {
      name: "sidebar.adopt",
      icon: PiTreeDuotone,
      link: "adopt",
      value: "/adopt",
    },
    {
      name: "sidebar.catalog",
      icon: FiList,
      link: "catalog",
      value: "/catalog",
    },
    {
      name: "sidebar.cart",
      icon: PiShoppingCartBold,
      link: "cart",
      value: "/cart",
    },
    {
      name: "sidebar.orders",
      icon: TbTruckDelivery,
      link: "orders",
      value: "/orders",
    },
    // {
    //   name: "Impostazioni",
    //   icon: FiSettings,
    //   link: "settings",
    //   value: "/settings",
    // },
  ];

  return (
    <Box
      transition="3s ease"
      // bg={useColorModeValue("white", "gray.900")}
      // borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      zIndex={990}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
        <Image src={Images.Logo} width={100} />

        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <div key={link.value}>
          {adoptTree && link.link.includes("adopt") ? null : (
            <NavItem
              _hover={{ bg: "green.400", color: "white" }}
              key={link.value}
              isactive={location.pathname.includes(link.value)}
              icon={link.icon}
              bg={
                location.pathname.includes(link.value) ? "green.400" : undefined
              }
              onClick={() => handleItemClick(link)}
            >
              {t(link.name)}
            </NavItem>
          )}
        </div>
      ))}
    </Box>
  );
};

const NavItem = ({
  icon,
  children,
  isactive: navactive,
  ...rest
}: NavItemProps) => {
  return (
    <Box style={{ textDecoration: "none" }} _focus={{ boxShadow: "none" }}>
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        color={navactive ? "white" : "gray.600"}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Box>
  );
};

const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  const { signOut } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();
  const { t } = useTranslation();

  let navbarPosition = "fixed" as const;
  let navbarFilter = "none";
  let navbarBackdrop = "blur(20px)";
  let navbarShadow = "none";
  let navbarBorder = "transparent";
  let secondaryMargin = "0px";
  let paddingX = "15px";

  const [, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", changeNavbar);

    return () => {
      window.removeEventListener("scroll", changeNavbar);
    };
  });

  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      // px={{ base: 4, md: 4 }}
      height="20"
      alignItems="center"
      zIndex={900}
      // bg={useColorModeValue("white", "gray.900")}
      // borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent={{ base: "space-between", md: "flex-end" }}
      {...rest}
      position={navbarPosition}
      boxShadow={navbarShadow}
      // bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      backgroundPosition="center"
      backgroundSize="cover"
      borderRadius="16px"
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      // alignItems={{ xl: "center" }}
      display={"flex"}
      minH="75px"
      // justifyContent={{ xl: "center" }}
      lineHeight="25.6px"
      mx="auto"
      mt={secondaryMargin}
      pb="8px"
      right={{ base: "12px", md: "30px", lg: "30px", xl: "30px" }}
      px={{
        sm: paddingX,
        md: "10px",
      }}
      ps={{
        xl: "12px",
      }}
      pt="8px"
      top={{ base: "12px", md: "16px", xl: "18px" }}
      w={{
        base: "calc(100vw - 6%)",
        md: "calc(100vw - 8%)",
        lg: "calc(100vw - 6%)",
        xl: "calc(100vw - 350px)",
        "2xl": "calc(100vw - 365px)",
      }}
    >
      <IconButton
        display={{ base: "flex", md: "none" }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Box display={{ base: "flex", md: "none" }}>
        <Image src={Images.Logo} width={100} />
      </Box>

      <HStack spacing={{ base: "0", md: "6" }}>
        <Flex alignItems={"center"}>
          <FlagMenu />
          <Menu>
            <MenuButton
              py={2}
              ml={4}
              transition="all 0.3s"
              _focus={{ boxShadow: "none" }}
            >
              <HStack>
                <Avatar size={"sm"} name={rest.name} />
                <VStack
                  display={{ base: "none", md: "flex" }}
                  alignItems="flex-start"
                  spacing="1px"
                  ml="2"
                >
                  <Text fontSize="sm">{rest.name}</Text>
                </VStack>
                <Box display={{ base: "none", md: "flex" }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue("white", "gray.900")}
              borderColor={useColorModeValue("gray.200", "gray.700")}
            >
              <MenuItem onClick={() => navigate("profile")}>
                {t("sidebar.settings.profile")}
              </MenuItem>
              <MenuItem onClick={() => navigate("settings")}>
                {t("sidebar.settings.addresses")}
              </MenuItem>
              <MenuDivider />
              <MenuItem
                onClick={() => {
                  signOut();
                  navigate("/");
                }}
              >
                {t("sidebar.settings.signOut")}
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

const Sidebar: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { user } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    async function fetchData() {
      await Auth.currentAuthenticatedUser({ bypassCache: true });
    }
    fetchData();
  }, []);

  const givenName = user?.attributes?.given_name;
  const familyName = user?.attributes?.family_name;
  const name = `${givenName} ${familyName}`;

  return (
    <Box minH="100vh">
      <SidebarContent
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav onOpen={onOpen} name={name} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </Box>
  );
};

export default Sidebar;
