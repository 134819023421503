import { z } from "zod";

export const ShippingSchema = z.object({
  __typename: z.literal("ShippingCost"),
  shippingCostIta: z.number(),
  shippingCostId: z.string(),
  shippingCostOther: z.number(),
  minWeight: z.number(),
  maxWeight: z.number(),
});
