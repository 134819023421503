import * as React from "react";

import { useTranslation } from "react-i18next";

import { useContext } from "react";
import { Icons } from "../../images/svg";
import { LanguageContext } from "../../contexts/languageContext";
import {
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

type FlagOptions = {
  [key: string]: React.ReactNode;
};

const flag: FlagOptions = {
  it: <Icons.ItaFlag />,
  en: <Icons.EnFlag />,
};

export const FlagMenu: React.FC = () => {
  const { i18n } = useTranslation();
  const { setLanguage } = useContext(LanguageContext);
  const flags = Object.keys(flag);
  const [lang, setLang] = React.useState<string>("it");

  React.useEffect(() => {
    // Perform localStorage action
    const item = localStorage.getItem("i18nLng");
    if (item) {
      setLang(item);
      i18n.changeLanguage(item);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = (f?: string) => {
    if (f) {
      localStorage.setItem("i18nLng", f);
      setLang(f);
      setLanguage(f);
      i18n.changeLanguage(f);
    }
  };

  return (
    <HStack>
      {process.env.REACT_APP_ENV === "development" && (
        <Text
          variant="caption"
          fontWeight="bold"
          color="text.primary"
          textAlign="center"
        >
          Dev
        </Text>
      )}

      <Menu>
        <MenuButton py={2} transition="all 0.3s" _focus={{ boxShadow: "none" }}>
          <>{flag[lang ?? "it"]}</>
        </MenuButton>
        <MenuList
          bg={useColorModeValue("white", "gray.900")}
          borderColor={useColorModeValue("gray.200", "gray.700")}
        >
          {flags.map((f) => (
            <MenuItem key={f} onClick={() => handleClose(f)}>
              {flag[f]}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </HStack>
  );
};

export default FlagMenu;
