import { FC } from "react";

export const combineComponents = (...components: FC[]): any => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent: any) => {
      return function combine({ children }: any): JSX.Element {
        return (
          <AccumulatedComponents>
            <CurrentComponent>{children}</CurrentComponent>
          </AccumulatedComponents>
        );
      };
    },
    ({ children }: any) => <>{children}</>
  );
};
