import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "./routes/AppRoutes";
import { setup } from "./utils/setup";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { useEffect } from "react";
import { resources } from "./i18n/config";
import { LanguageContextProvider } from "./contexts/languageContext";
import { Authenticator } from "@aws-amplify/ui-react";

function App() {
  setup();
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false, // default: true
      },
    },
  });

  useEffect(() => {
    const item = localStorage.getItem("i18nLng") ?? "it";

    i18next.use(initReactI18next).init({
      lng: item,
      resources,
      react: {
        transSupportBasicHtmlNodes: true,
      },
      interpolation: {
        escapeValue: false,
      },
    });
  }, []);

  return (
    <Authenticator.Provider>
      <LanguageContextProvider>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <AppRoutes />
          </BrowserRouter>
        </QueryClientProvider>
      </LanguageContextProvider>
    </Authenticator.Provider>
  );
}

export default App;
