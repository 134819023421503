import { Center, Box as ChakraBox, Text } from "@chakra-ui/react";
import { Box } from "../../../API";
import { FC } from "react";
import { AsyncImage } from "../../../components/async-image/AsyncImage";

interface BoxWithoutCatalogProps {
  box: Box;
}

const { REACT_APP_CDN } = process.env;
export const BoxWithoutCatalog: FC<BoxWithoutCatalogProps> = ({ box }) => {
  const image = `${REACT_APP_CDN}/boxes/${box.boxId}.png`;

  return (
    <ChakraBox>
      <Center>
        <AsyncImage
          height={300}
          src={`${image}?${box.boxId}`}
          alt={box.title}
        />
      </Center>
      <Text textAlign="center" fontSize="2xl" fontWeight="bold">
        {box.title}
      </Text>
      <Text mt={12} fontSize="xl">
        {box.description}
      </Text>
    </ChakraBox>
  );
};
