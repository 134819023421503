import React from "react";
import {
  Box,
  IconButton,
  Heading,
  Text,
  Flex,
  Button,
  useMediaQuery,
  Stack,
} from "@chakra-ui/react";

import { BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";

import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../images/svg";
import { HomeCard } from "../../API";
import { useTranslation } from "react-i18next";

const settings = {
  dots: true,
  arrows: false,
  fade: false,
  infinite: true,
  autoplay: false,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

interface CarouselProps {
  card: HomeCard;
}

const sticker = {
  order: <Icons.Shipping width={40} height={40} />,
  newProduct: <Icons.New width={40} height={40} />,
  discount: <Icons.Discount width={40} height={40} />,
  interess: <Icons.Interess width={40} height={40} />,
  info: <Icons.Info width={40} height={40} />,
  mostPurchase: <Icons.Bestseller width={40} height={40} />,
};

type CardType = keyof typeof sticker;

export const CaptionCarousel: React.FC<CarouselProps> = ({ card }) => {
  const { t } = useTranslation();
  const [isSmScreen] = useMediaQuery("(max-width: 30em)");
  const navigate = useNavigate();

  const [slider, setSlider] = React.useState<Slider | null>(null);

  const cardFound = [
    "order",
    "newProduct",
    "discount",
    "interess",
    "info",
    "mostPurchase",
  ].find((cardType) => cardType === card.type);

  const cardType = (cardFound ?? "info") as CardType;

  const cardInfo = card.info ?? [];

  const handleNavigation = (navigateTo: string) => {
    if (navigateTo.includes("https://")) {
      window.open(navigateTo, "_blank");
      return;
    }
    navigate(navigateTo);
  };

  return (
    <Box
      position={"relative"}
      width={"full"}
      overflow={"hidden"}
      w={isSmScreen ? "100%" : "80%"}
      mx={isSmScreen ? 0 : "auto"}
    >
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />

      <Box>
        <Box display="flex" mb={2}>
          {sticker[cardType]}
          <Heading ml={2}>{card.name}</Heading>
        </Box>
        <Slider {...settings} ref={(slider) => setSlider(slider)}>
          {cardInfo.map((cardInfo, index) => (
            <Box key={index}>
              <Flex
                bg={`linear-gradient(to left, ${card.color[0]}, ${card.color[1]})`}
                borderRadius={8}
                overflow="hidden"
                height={isSmScreen ? "200px" : "256px"}
              >
                {/* <Box
                  key={index}
                  height={isSmScreen ? "100px" : "100px"}
                  position="relative"
                  backgroundRepeat="no-repeat"
                  backgroundImage={`url(${card.image ?? defaultImage})`}
                  flex={1}
                /> */}

                <Box
                  justifyContent="space-between"
                  display="flex"
                  flexDirection="column"
                  flex={2}
                  p={4}
                >
                  <Stack spacing={3}>
                    <Heading textAlign="center">{cardInfo.title}</Heading>
                    <Text
                      noOfLines={
                        isSmScreen
                          ? cardInfo.navigateTo
                            ? 2
                            : 3
                          : cardInfo.navigateTo
                          ? 3
                          : 4
                      }
                    >
                      {cardInfo.description}
                    </Text>
                  </Stack>

                  {cardInfo.navigateTo && (
                    <Button
                      w="full"
                      justifySelf="flex-end"
                      onClick={() =>
                        handleNavigation(cardInfo.navigateTo ?? "")
                      }
                    >
                      {t("home.discover")}
                    </Button>
                  )}
                </Box>
              </Flex>
            </Box>
          ))}
        </Slider>
        {cardInfo.length > 1 && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-around"
            mt={5}
          >
            <IconButton
              aria-label="left-arrow"
              variant="ghost"
              transform={"translate(0%, -50%)"}
              zIndex={2}
              onClick={() => slider?.slickPrev()}
            >
              <BiLeftArrowAlt size="40px" />
            </IconButton>

            <IconButton
              aria-label="right-arrow"
              variant="ghost"
              transform={"translate(0%, -50%)"}
              zIndex={2}
              onClick={() => slider?.slickNext()}
            >
              <BiRightArrowAlt size="40px" />
            </IconButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};
