import { Navigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Box, Spinner } from "@chakra-ui/react";
import { Auth } from "aws-amplify";
import { useEffect } from "react";

export const CheckRoutes = () => {
  const { authStatus } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    async function fetchData() {
      await Auth.currentAuthenticatedUser({ bypassCache: true });
    }
    fetchData();
  }, []);

  if (authStatus === "configuring") {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (authStatus !== "authenticated") {
    return <Navigate to={"/signup"} replace={true} />;
  }

  return <Navigate to={"/home"} replace={true} />;
};
