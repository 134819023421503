import React from "react";
import {
  Box,
  Badge,
  Button,
  Stack,
  HStack,
  Input,
  Text,
} from "@chakra-ui/react";
import { AsyncImage } from "../../async-image/AsyncImage";

import { calculatePrice, formatPrice } from "../../../utils/utils";
import { Product } from "../../../API";
import { useContextCatalog } from "../../../contexts/catalog/catalog";
import { OrderModal } from "../order-card/OrderModal";
import OrderProductInfo from "../order-card/OrderProductInfo";
import { useTranslation } from "react-i18next";

export type ProductProps = {
  product: Product;
  cardImage: string;
};

export const ProductCard: React.FC<ProductProps> = ({ product, cardImage }) => {
  const { setCatalog } = useContextCatalog();
  const { t } = useTranslation();

  const hasCertifications =
    product.isNew ||
    (product.certifications && product.certifications.includes("DOP")) ||
    (product.certifications && product.certifications.includes("IGP")) ||
    (product.certifications && product.certifications.includes("BIO"));

  return (
    <Stack
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      bgColor={"green.300"}
      justifyContent={"space-between"}
    >
      <Stack>
        <Box justifyContent="center" display="flex" bgColor={"green.100"}>
          <OrderModal
            children={<OrderProductInfo product={product} />}
            modalTitle={product.name ?? ""}
            component={
              <Box position="relative">
                <AsyncImage
                  height={150}
                  src={`${cardImage}?${product.productId}`}
                  alt={product.name}
                />
                {product.isSoldOut && (
                  <Box
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    bg="rgba(255, 255, 255, 0.7)"
                  >
                    <Text fontWeight="bold" fontSize="lg" color="orange">
                      Sold Out
                    </Text>
                  </Box>
                )}
              </Box>
            }
          />
        </Box>

        <Stack
          flexDirection="row"
          display="flex"
          alignItems="baseline"
          flex={1}
          flexWrap="wrap"
          px={2}
          mb={hasCertifications ? 0 : "16px"}
        >
          {product.isThisYear && (
            <Badge borderRadius="full" px="2" colorScheme="whiteAlpha">
              2024
            </Badge>
          )}
          {product.isNew && (
            <Badge borderRadius="full" px="2" colorScheme="pink">
              New
            </Badge>
          )}
          {product.certifications && product.certifications.includes("DOP") && (
            <Badge borderRadius="full" px="2" colorScheme="blue">
              DOP
            </Badge>
          )}
          {product.certifications && product.certifications.includes("IGP") && (
            <Badge borderRadius="full" px="2" colorScheme="yellow">
              IGP
            </Badge>
          )}
          {product.certifications && product.certifications.includes("BIO") && (
            <Badge borderRadius="full" px="2" colorScheme="green">
              BIO
            </Badge>
          )}
        </Stack>

        <Box
          mt="1"
          fontWeight="semibold"
          as="h4"
          lineHeight="tight"
          display={"flex"}
          flex={1}
          color={"white"}
          px={2}
        >
          {product.name}
        </Box>
        <Box pl={2} color={"white"} fontSize="sm">
          {`(1 ${t(
            "history.orderHistoryCard.details.features.unit"
          )} - ${formatPrice(product.format.unitPrice)} €)`}
        </Box>
        <Box
          mt="1"
          fontWeight="semibold"
          as="h4"
          lineHeight="tight"
          display={"flex"}
          flex={1}
          color={"white"}
          px={2}
        >
          {t("history.orderHistoryCard.details.features.line")} {product.line}
        </Box>
        <Box
          color={"white"}
          px={2}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <Box
            fontWeight="bold"
            pr={2}
            style={{
              textDecoration: product.discount ? "line-through" : "none",
              color: product.discount ? "gray" : "white",
            }}
          >
            {calculatePrice(product, false)} €
          </Box>
          {product.discount && (
            <Box color={"white"} display="flex" flexDirection="row">
              <Box fontWeight="bold" pr={2}>
                {calculatePrice(product, !!product.discount)} €
              </Box>
            </Box>
          )}
          <Box>
            {product.discount && (
              <Badge
                borderRadius="full"
                px="2"
                colorScheme="orange"
                display="flex"
                ml={"auto"}
              >
                {-product.discount.discountPercentage}%
              </Badge>
            )}
          </Box>
        </Box>

        <Box
          color={"white"}
          whiteSpace="pre-line"
          px={2}
          height="48px"
          noOfLines={2}
        >
          {product.format.description}
        </Box>
      </Stack>

      <Box
        fontSize="sm"
        display={"flex"}
        justifyContent={"flex-end"}
        color="white"
        px={2}
        pb={2}
      >
        <HStack maxW="320px" display="flex" flex={1}>
          <Button
            colorScheme="green.600"
            _hover={{
              bg: "green.600",
              color: "white",
            }}
            variant="outline"
            isDisabled={product.isSoldOut || product.selectedQuantity === 0}
            onClick={() => {
              if (product.selectedQuantity > 0) {
                setCatalog((prevCatalog) => ({
                  ...prevCatalog,
                  products: prevCatalog.products.map((p) => {
                    if (p.productId === product.productId) {
                      return {
                        ...p,
                        selectedQuantity: p.selectedQuantity - 1,
                      };
                    }
                    return p;
                  }),
                }));
              }
            }}
          >
            -
          </Button>
          <Input
            textAlign={"center"}
            isDisabled={product.isSoldOut}
            onChange={(e) => {
              const value = parseInt(e.target.value);
              if (value >= 0 && value <= 100) {
                setCatalog((prevCatalog) => ({
                  ...prevCatalog,
                  products: prevCatalog.products.map((p) => {
                    if (p.productId === product.productId) {
                      return {
                        ...p,
                        selectedQuantity: value,
                      };
                    }
                    return p;
                  }),
                }));
              }
            }}
            value={product.selectedQuantity}
          />
          <Button
            colorScheme="teal"
            _hover={{ bg: "green.600", color: "white" }}
            isDisabled={product.isSoldOut || product.selectedQuantity === 100}
            onClick={() => {
              setCatalog((prevCatalog) => ({
                ...prevCatalog,
                products: prevCatalog.products.map((p) => {
                  if (p.productId === product.productId) {
                    return {
                      ...p,
                      selectedQuantity: p.selectedQuantity + 1,
                    };
                  }
                  return p;
                }),
              }));
            }}
          >
            +
          </Button>
        </HStack>
      </Box>
    </Stack>
  );
};
