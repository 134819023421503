import React from "react";

export default interface IIcon {
  width?: number;
  height?: number;
  color?: string;
  strokeWidth?: number;
  "data-testid"?: string;
}

export interface ExtendedIIcon extends IIcon {
  strokeWidth?: number;
}

export const Discount: React.FC<IIcon> = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          fill="#FF6B39"
          d="M350.975,48.831c12.117-2.27,25.116,0.705,35.297,9.249c10.18,8.542,15.364,20.827,15.237,33.154
		c12.161,2.014,23.359,9.253,30.005,20.764c6.644,11.511,7.316,24.83,2.979,36.368c10.739,6.052,18.787,16.684,21.093,29.775
		c2.308,13.086-1.615,25.831-9.637,35.194c8.022,9.36,11.946,22.103,9.637,35.191c-2.306,13.086-10.354,23.722-21.093,29.772
		c4.337,11.541,3.665,24.86-2.979,36.37c-6.644,11.508-17.842,18.748-30.005,20.763c0.129,12.326-5.057,24.611-15.237,33.155
		c-10.181,8.542-23.179,11.515-35.297,9.247c-4.095,11.63-13.169,21.401-25.658,25.947c-12.491,4.542-25.724,2.893-36.335-3.383
		c-7.823,9.524-19.694,15.601-32.983,15.604c-13.29-0.003-25.161-6.079-32.983-15.604c-10.61,6.276-23.842,7.925-36.333,3.383
		c-12.489-4.546-21.565-14.317-25.658-25.947c-12.117,2.268-25.118-0.705-35.297-9.247c-10.183-8.544-15.366-20.829-15.239-33.155
		c-12.161-2.015-23.359-9.254-30.005-20.763c-6.645-11.51-7.314-24.827-2.979-36.368c-10.737-6.052-18.785-16.689-21.093-29.774
		c-2.306-13.089,1.617-25.834,9.637-35.191c-8.021-9.362-11.943-22.108-9.637-35.194c2.308-13.09,10.354-23.725,21.093-29.775
		c-4.335-11.538-3.666-24.86,2.979-36.368c6.645-11.508,17.843-18.75,30.005-20.764c-0.129-12.326,5.056-24.612,15.239-33.154
		c10.179-8.544,23.177-11.517,35.295-9.249c4.095-11.63,13.171-21.398,25.66-25.945c12.489-4.544,25.722-2.893,36.333,3.38
		c7.826-9.523,19.694-15.6,32.983-15.6c13.29,0,25.161,6.077,32.983,15.601c10.61-6.273,23.845-7.925,36.335-3.38
		C337.806,27.434,346.88,37.203,350.975,48.831z"
        />
        <path
          fill="#FFD72E"
          d="M150.403,107.735c58.321-58.319,152.87-58.319,211.194,0c58.319,58.321,58.319,152.87,0,211.194
		c-58.323,58.319-152.873,58.319-211.194,0C92.085,260.606,92.085,166.057,150.403,107.735z"
        />
      </g>
      <g>
        <path
          fill="#000003"
          d="M327.544,156.874c4.165-4.165,4.165-10.919,0-15.085c-4.165-4.165-10.917-4.165-15.086-0.001
		l-128.002,128c-4.165,4.165-4.165,10.919,0,15.085c2.083,2.083,4.813,3.124,7.542,3.124c2.73,0,5.459-1.042,7.542-3.124
		L327.544,156.874z"
        />
        <path
          fill="#000003"
          d="M224,106.663c-23.526,0-42.666,19.14-42.666,42.668s19.139,42.668,42.666,42.668
		c23.527,0,42.668-19.14,42.668-42.668C266.667,125.804,247.527,106.663,224,106.663z M224,170.666
		c-11.762,0-21.332-9.571-21.332-21.334c0-11.764,9.57-21.334,21.332-21.334c11.763,0,21.334,9.57,21.334,21.334
		C245.334,161.095,235.764,170.666,224,170.666z"
        />
        <path
          fill="#000003"
          d="M288,234.666c-23.526,0-42.666,19.139-42.666,42.666c0,23.527,19.14,42.669,42.666,42.669
		c23.527,0,42.668-19.141,42.668-42.668C330.667,253.805,311.527,234.666,288,234.666z M288,298.667
		c-11.762,0-21.332-9.571-21.332-21.334c0-11.762,9.57-21.332,21.332-21.332c11.763,0,21.334,9.57,21.334,21.332
		C309.334,289.095,299.764,298.667,288,298.667z"
        />
        <path
          fill="#000003"
          d="M466.091,176.289c-2.278-12.932-9.145-24.378-19.242-32.421c2.563-12.649,0.467-25.83-6.099-37.204
		c-6.565-11.372-16.932-19.778-29.17-23.883c-1.919-12.765-8.397-24.434-18.454-32.871c-9.927-8.331-22.596-12.782-35.575-12.462
		c-6.166-11.34-16.246-20.092-28.588-24.583c-5.884-2.141-12.018-3.227-18.234-3.227c-6.671,0-13.273,1.26-19.459,3.685
		C281.599,4.775,269.132,0,255.999,0c-13.133,0-25.597,4.775-35.27,13.323c-6.187-2.426-12.788-3.685-19.458-3.685
		c-6.215,0-12.35,1.086-18.236,3.227c-12.341,4.493-22.421,13.244-28.589,24.583c-12.923-0.324-25.65,4.132-35.572,12.46
		c-10.06,8.439-16.54,20.109-18.457,32.875c-12.237,4.105-22.603,12.511-29.17,23.883c-6.564,11.371-8.661,24.551-6.098,37.203
		c-10.095,8.042-16.961,19.488-19.243,32.421c-2.278,12.93,0.259,26.033,6.994,37.044c-6.735,11.008-9.271,24.112-6.993,37.046
		c2.281,12.93,9.147,24.375,19.243,32.418c-2.564,12.652-0.467,25.833,6.097,37.204c6.566,11.37,16.933,19.775,29.17,23.881
		c1.919,12.767,8.397,24.435,18.457,32.874c9.923,8.329,22.644,12.782,35.574,12.459c6.165,11.342,16.245,20.094,28.591,24.589
		c5.885,2.14,12.019,3.225,18.233,3.225c6.671,0,13.273-1.261,19.458-3.686c9.671,8.546,22.135,13.322,35.269,13.324
		c0.001,0,0.003,0,0.004,0c13.134-0.003,25.597-4.778,35.268-13.324c6.187,2.426,12.788,3.686,19.46,3.686
		c6.214,0,12.349-1.085,18.236-3.226c12.34-4.493,22.419-13.244,28.587-24.587c12.932,0.322,25.649-4.13,35.575-12.46
		c10.059-8.441,16.537-20.11,18.453-32.874c12.24-4.106,22.606-12.511,29.17-23.882c6.565-11.373,8.662-24.554,6.098-37.204
		c10.098-8.043,16.962-19.486,19.242-32.418c2.282-12.932-0.256-26.034-6.992-37.045
		C465.834,202.321,468.372,189.219,466.091,176.289z M437.85,220.275c6.266,7.31,8.901,16.931,7.231,26.399
		c-1.668,9.466-7.436,17.604-15.824,22.331c-4.585,2.582-6.6,8.121-4.749,13.045c3.388,9.014,2.574,18.959-2.233,27.284
		c-4.805,8.322-13.009,13.999-22.51,15.572c-5.19,0.86-8.978,5.375-8.923,10.636c0.1,9.627-4.066,18.693-11.428,24.871
		c-5.753,4.827-13.047,7.485-20.543,7.485c-1.985,0-3.983-0.186-5.936-0.551c-5.181-0.971-10.277,1.981-12.023,6.943
		c-3.198,9.083-10.212,16.178-19.242,19.465c-8.98,3.265-19.081,2.298-27.259-2.54c-4.529-2.679-10.334-1.654-13.673,2.411
		c-6.11,7.438-15.126,11.705-24.74,11.708c-9.615-0.003-18.631-4.27-24.74-11.708c-2.09-2.544-5.145-3.897-8.249-3.897
		c-1.855,0-3.729,0.484-5.424,1.487c-8.176,4.837-18.278,5.805-27.253,2.541c-9.035-3.289-16.049-10.384-19.245-19.465
		c-1.747-4.963-6.849-7.907-12.023-6.944c-1.952,0.366-3.949,0.551-5.936,0.551c-7.495,0-14.79-2.658-20.543-7.485
		c-7.363-6.178-11.53-15.244-11.43-24.874c0.055-5.26-3.733-9.774-8.922-10.634c-9.5-1.573-17.706-7.25-22.511-15.572
		c-4.804-8.323-5.618-18.268-2.231-27.284c1.85-4.924-0.165-10.461-4.748-13.043c-8.388-4.729-14.157-12.868-15.826-22.333
		c-1.668-9.469,0.966-19.09,7.231-26.399c3.423-3.994,3.424-9.887,0.001-13.882c-6.265-7.312-8.901-16.935-7.233-26.401
		c1.669-9.469,7.438-17.609,15.825-22.334c4.583-2.581,6.601-8.121,4.75-13.045c-3.388-9.015-2.574-18.96,2.231-27.282
		c4.807-8.325,13.012-14.002,22.511-15.574c5.189-0.86,8.978-5.375,8.923-10.636c-0.101-9.629,4.063-18.693,11.429-24.871
		c5.751-4.827,13.045-7.486,20.54-7.486c1.985,0,3.983,0.186,5.935,0.55c5.177,0.972,10.276-1.979,12.023-6.942
		c3.197-9.081,10.212-16.175,19.245-19.462c8.976-3.267,19.075-2.299,27.257,2.539c4.53,2.679,10.332,1.653,13.67-2.411
		c6.113-7.439,15.132-11.706,24.742-11.706c9.614,0,18.631,4.267,24.74,11.705c3.339,4.064,9.141,5.09,13.671,2.412
		c8.179-4.836,18.283-5.805,27.257-2.54c9.033,3.289,16.047,10.383,19.245,19.465c1.748,4.963,6.855,7.908,12.026,6.942
		c1.952-0.366,3.949-0.551,5.935-0.551c7.493,0,14.786,2.659,20.541,7.488c7.361,6.176,11.527,15.242,11.428,24.873
		c-0.055,5.26,3.733,9.774,8.923,10.634c9.499,1.573,17.705,7.249,22.51,15.573c4.806,8.326,5.62,18.27,2.233,27.282
		c-1.851,4.925,0.164,10.463,4.748,13.045c8.389,4.729,14.158,12.867,15.826,22.335c1.669,9.466-0.966,19.088-7.232,26.399
		C434.426,210.387,434.427,216.281,437.85,220.275z"
        />
        <path
          fill="#000003"
          d="M299.126,81.543c5.6,1.831,11.622-1.221,13.454-6.821c1.833-5.599-1.222-11.623-6.821-13.455
		c-26.663-8.723-55.261-10.282-82.7-4.508C152.322,71.641,100.17,132.447,96.234,204.627c-0.321,5.883,4.189,10.911,10.07,11.232
		c0.198,0.011,0.394,0.016,0.59,0.016c5.625,0,10.332-4.402,10.642-10.086c3.41-62.558,48.608-115.257,109.916-128.154
		C251.232,72.631,276.016,73.982,299.126,81.543z"
        />
        <path
          fill="#000003"
          d="M405.695,210.803c-5.876-0.314-10.91,4.188-11.231,10.07
		c-3.41,62.559-48.609,115.26-109.918,128.157c-23.778,5.004-48.564,3.652-71.671-3.908c-5.596-1.83-11.622,1.222-13.454,6.821
		c-1.833,5.599,1.221,11.622,6.82,13.455c16.118,5.274,32.941,7.929,49.79,7.929c11.022,0,22.059-1.137,32.909-3.421
		c70.738-14.881,122.892-75.689,126.827-147.871C416.087,216.153,411.577,211.123,405.695,210.803z"
        />
        <path
          fill="#000003"
          d="M119.903,239.903c-1.124-5.782-6.726-9.557-12.508-8.434c-5.782,1.124-9.558,6.723-8.434,12.507
		c0.567,2.916,1.246,5.926,2.017,8.948c1.23,4.823,5.568,8.033,10.329,8.033c0.871,0,1.76-0.108,2.644-0.334
		c5.709-1.456,9.155-7.264,7.699-12.972C120.978,245.024,120.391,242.418,119.903,239.903z"
        />
        <path
          fill="#000003"
          d="M392.1,186.77c0.994,5.091,5.457,8.625,10.458,8.625c0.677,0,1.365-0.065,2.055-0.199
		c5.781-1.129,9.554-6.731,8.425-12.513c-0.576-2.955-1.254-5.964-2.015-8.943c-1.456-5.708-7.26-9.157-12.972-7.7
		c-5.709,1.456-9.155,7.264-7.699,12.972C391.013,181.601,391.6,184.211,392.1,186.77z"
        />
        <path
          fill="#000003"
          d="M362.666,490.667H149.333c-5.89,0-10.667,4.775-10.667,10.667c0,5.891,4.777,10.667,10.667,10.667
		h213.333c5.89,0,10.667-4.775,10.667-10.667C373.333,495.442,368.558,490.667,362.666,490.667z"
        />
      </g>
    </svg>
  );
};
