import {
  Button,
  Divider,
  Flex,
  Heading,
  Stack,
  Text,
  useColorModeValue as mode,
  useToast,
} from "@chakra-ui/react";
import { formatPrice } from "../../utils/utils";
import {
  catalogDefaultData,
  useContextCatalog,
} from "../../contexts/catalog/catalog";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { OrderInput, PaymentInput, ShippingCost } from "../../API";
import { CartItem } from "./cart-item";
import { FaArrowRight } from "react-icons/fa";
import { addressDefault } from "../../pages/settings/Settings";
import { useNavigate } from "react-router-dom";
import { AddressInputSchema } from "../../models/address";
import { ProductInputSchema } from "../../models/products";
import { orderApi } from "../../services/order-api/order-api";
import { useTranslation } from "react-i18next";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { addressApi } from "../../services/address-api/address-api";
import { useEffect } from "react";
import { Auth } from "aws-amplify";

type OrderSummaryItemProps = {
  label: string;
  value?: string;
  children?: React.ReactNode;
};

export const OrderSummaryItem = (props: OrderSummaryItemProps) => {
  const { label, value, children } = props;
  return (
    <Flex justify="space-between" fontSize="sm">
      <Text fontWeight="medium" color={mode("gray.600", "gray.400")}>
        {label}
      </Text>
      {value ? <Text fontWeight="medium">{value}</Text> : children}
    </Flex>
  );
};

export const CartOrderSummary = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { catalog, setCatalog } = useContextCatalog();
  const { user } = useAuthenticator((context) => [context.user]);
  const queryClient = useQueryClient();
  const data = queryClient.getQueriesData<ShippingCost[]>(["getShippingCost"]);
  const sub = user?.username || "";
  const role = user?.attributes?.["custom:role"] || "standard";
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [__, shippingCost] = data[0];

  useEffect(() => {
    async function fetchData() {
      await Auth.currentAuthenticatedUser({ bypassCache: true });
    }
    fetchData();
  }, []);

  const addresses = useQuery(["getAddressByUserId", sub], () =>
    addressApi.getAddressByUserId({
      userId: sub,
    })
  );

  const defaultAddressInput =
    addresses.data?.find((address) => address.isDefault) || addressDefault;

  const totalPrice = catalog.products.reduce((acc, product) => {
    if (product.discount && !product.isSoldOut) {
      return acc + product.discount.discountPrice * product.selectedQuantity;
    }
    return acc + product.price * product.selectedQuantity;
  }, 0);

  const totalWeight = catalog.products.reduce((acc, product) => {
    return acc + product.format.boxWeight * product.selectedQuantity;
  }, 0);

  // calculate total shipping cost check if the total weight is between min and max weight of the shipping cost
  const totalShipping = shippingCost
    ? shippingCost.reduce((acc, cost) => {
        if (totalWeight >= cost.minWeight && totalWeight <= cost.maxWeight) {
          return acc + cost.shippingCostIta;
        }
        return acc;
      }, 0)
    : 0;

  const maxWeight = shippingCost
    ? shippingCost.reduce((acc, cost) => {
        return Math.max(acc, cost.maxWeight);
      }, 0)
    : 0;

  const overMaxWeight = maxWeight - totalWeight;

  const totalWithShipping = totalShipping + totalPrice;

  const { mutate: mutateUpdateOrder } = useMutation(
    ["updateOrder"],
    async () => {
      const payment: PaymentInput = {
        creditCard: null,
        method: "CREDIT_CARD",
        paymentId: "",
        status: "NOT_PAID",
        totalPrice: totalWithShipping,
        totalProductsPrice: totalPrice,
        totalShipping: totalShipping,
      };

      const addressInput = AddressInputSchema.parse(defaultAddressInput);

      const productsInput = catalog.products.map((product) => {
        return ProductInputSchema.parse(product);
      });

      const order: OrderInput = {
        orderId: "",
        userId: sub,
        status: "CREATED",
        role: role === "standard" ? "standard" : `${sub}`,
        orderName: "",
        startDate: new Date().toISOString(),
        estimateDate: "",
        endDate: "",
        tracking: [
          {
            update: new Date().toISOString(),
            status: "CREATED",
          },
        ],
        info: "",
        payment,
        address: {
          ...addressInput,
        },
        products: productsInput,
      };
      const response = await orderApi.updateOrder({
        order: order,
      });
      return response;
    },
    {
      onSuccess: (response) => {
        toast({
          title: t("cart.orderCreated.title"),
          description: t("cart.orderCreated.description"),
          status: "success",
        });
        setCatalog(catalogDefaultData);
        navigate("/orders");
      },
      onError: (error) => {
        toast({
          title: t("cart.orderError.title"),
          description: t("cart.orderError.description"),
          status: "error",
        });
      },
    }
  );

  return (
    <Stack
      spacing="8"
      borderWidth="1px"
      borderColor="gray.400"
      rounded="lg"
      padding="8"
      width="full"
      overflowY="scroll" // Set overflowY to "scroll"
      maxHeight="60vh" // Set a fixed height
      css={{
        "&::-webkit-scrollbar": {
          width: "0px",
          // background: "red",
        },
        // "&::-webkit-scrollbar-track": {
        //   background: "yellow",
        // },
        // "&::-webkit-scrollbar-thumb": {
        //   background: "blue",
        //   borderRadius: "6px",
        // },
        // "&::-webkit-scrollbar-thumb:hover": {
        //   background: "purple",
        // },
      }}
    >
      <Heading size="xl">{t("cart.orderSummary")}</Heading>
      <Heading fontSize="md" fontWeight="extrabold">
        {t("cart.shoppingCart")} ({catalog.products.length} {t("cart.items")})
      </Heading>
      <Stack spacing="6">
        {catalog.products.map((item, index) => (
          <Stack key={item.productId} spacing={6}>
            <CartItem product={item} />
            <Divider borderColor="gray.400" />
          </Stack>
        ))}
      </Stack>

      <Stack spacing="6">
        <OrderSummaryItem
          label={t("cart.subTotal")}
          value={formatPrice(totalPrice)}
        />
        {overMaxWeight < 0 ? (
          <OrderSummaryItem label={t("cart.shippingToBeCalculated")} />
        ) : (
          <OrderSummaryItem label={t("cart.shipping")}>
            <Text>{formatPrice(totalShipping)} €</Text>
          </OrderSummaryItem>
        )}
        {/* <OrderSummaryItem label="Coupon Code">
          <Link href="#" textDecor="underline">
            Add coupon code
          </Link>
        </OrderSummaryItem> */}
        <Flex justify="space-between">
          <Text fontSize="lg" fontWeight="semibold">
            {t("cart.total")}
          </Text>
          <Text fontSize="xl" fontWeight="extrabold">
            {formatPrice(totalWithShipping)} €
          </Text>
        </Flex>
      </Stack>
      <Button
        onClick={() => mutateUpdateOrder()}
        colorScheme="green"
        p={4}
        fontSize="md"
        rightIcon={<FaArrowRight />}
        isDisabled={
          catalog.products.reduce(
            (acc, curr) => acc + curr.selectedQuantity,
            0
          ) === 0 ||
          !shippingCost ||
          !defaultAddressInput.addressId
        }
      >
        {t("cart.preOrder")}
      </Button>
    </Stack>
  );
};
