import { Box, Spinner, Stack, Text, useToast } from "@chakra-ui/react";
import { OrderCard } from "../../components/card/order-card/OrderCard";
import { sortOrdersByStartDate } from "../../utils/utils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { orderApi } from "../../services/order-api/order-api";
import { Icons } from "../../images/svg";
import { Order } from "../../API";
import { OrderInputSchema } from "../../models/orders";
import { LanguageContext } from "../../contexts/languageContext";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

const Orders = () => {
  const toast = useToast();
  const { language } = useContext(LanguageContext);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const ordersQuery = useQuery(["getOrderByUserId", language], () =>
    orderApi.getOrderByUserId({ lang: language })
  );

  // Subscriptions
  useEffect(() => {
    const subscription = orderApi.onOrderUpdated().subscribe({
      next: (e: any) => {
        queryClient.invalidateQueries(["getOrderByUserId", language]);
      },
      error: (error: any) => {
        console.error("Order Subscription error", error);
      },
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [language, queryClient]);

  const { mutate: mutateCheckoutStripe, isLoading: isLoadingCheckoutStripe } =
    useMutation(
      async (order: Order) => {
        const orderInput = OrderInputSchema.parse(order);
        const response = await orderApi.checkoutOrderStripe({
          order: orderInput,
        });
        return response;
      },
      {
        onError: (error) => {
          console.log("[productCard-updateCart]", error);
          toast({
            title: t("history.stripeOrderError.title"),
            description: t("history.stripeOrderError.message"),
            status: "error",
          });
        },
        onSuccess: async (response) => {
          console.log("[productCard-updateCart]", response);
          if (response) {
            window.location.href = response;
          }
        },
      }
    );

  if (ordersQuery.isLoading || isLoadingCheckoutStripe) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (ordersQuery.isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        pt={{ base: "140px", md: "90px", xl: "90px" }}
      >
        <Icons.Error width={500} height={500} />
      </Box>
    );
  }

  const orders = sortOrdersByStartDate(ordersQuery.data);

  if (ordersQuery.isSuccess && orders.length === 0) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50%"
        pt={{ base: "140px", md: "90px", xl: "90px" }}
      >
        <Text fontSize="2xl" fontWeight="bold">
          {t("history.noOrders")}
        </Text>
      </Box>
    );
  }

  return (
    <Stack spacing={16} pt={{ base: "140px", md: "90px", xl: "90px" }}>
      {orders.map((order) => (
        <Box key={order.orderId}>
          <Text fontSize="2xl" fontWeight="bold">
            {order.orderName}
          </Text>
          <OrderCard order={order} onPay={() => mutateCheckoutStripe(order)} />
        </Box>
      ))}
    </Stack>
  );
};

export default Orders;
