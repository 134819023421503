import { z } from "zod";
export interface Address {
  street: string;
  number: string;
  city: string;
  region: string;
  zipCode: string;
  country: string;
  recipient: string;
  addressId: string;
  isDefault: boolean;
}

export const AddressSchema = z.object({
  __typename: z.literal("Address"),
  street: z.string(),
  number: z.string(),
  email: z.string(),
  city: z.string(),
  region: z.string(),
  zipCode: z.string(),
  country: z.string(),
  recipient: z.string(),
  formattedAddress: z.string(),
  lat: z.string(),
  lng: z.string(),
  info: z.string(),
  addressId: z.string(),
  userId: z.string(),
  isDefault: z.boolean(),
  phone: z.string(),
});

export const AddressInputSchema = z.object({
  street: z.string(),
  number: z.string(),
  city: z.string(),
  region: z.string(),
  zipCode: z.string(),
  email: z.string(),
  country: z.string(),
  recipient: z.string(),
  formattedAddress: z.string(),
  lat: z.string(),
  lng: z.string(),
  info: z.string(),
  addressId: z.string(),
  userId: z.string(),
  isDefault: z.boolean(),
  phone: z.string(),
});
