import { Box, Spinner } from "@chakra-ui/react";
import React, { ReactNode, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { CheckRoutes } from "./CheckRoutes";
import { AuthRoutes } from "./AuthRoutes";
import { ContextProvider } from "../contexts/context-provider";
import MaintenancePage from "../pages/maintenence/maintenence";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Signup } from "../pages/signup/Signup";
import { Auth } from "aws-amplify";

const LoginGuard: React.FC<{ children?: ReactNode }> = ({ children }) => {
  console.log("LoginGuard");
  const { authStatus } = useAuthenticator((context) => [context.user]);

  useEffect(() => {
    async function fetchData() {
      await Auth.currentAuthenticatedUser({ bypassCache: true });
    }
    fetchData();
  }, []);

  if (authStatus === "configuring") {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (authStatus !== "authenticated") {
    return <Navigate to={"/signup"} replace={true} />;
  }

  return <>{children}</>;
};

export const AppRoutes = () => {
  return (
    <React.Suspense
      fallback={
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Spinner size="xl" />
        </Box>
      }
    >
      <ContextProvider>
        <Routes>
          <Route path="/" element={<CheckRoutes />} />
          <Route path="/maintenance" element={<MaintenancePage />} />
          <Route path="/signup" element={<Signup />} />

          <Route
            path="/*"
            element={
              <LoginGuard>
                <AuthRoutes />
              </LoginGuard>
            }
          />
        </Routes>
      </ContextProvider>
    </React.Suspense>
  );
};
