import merge from "lodash/merge";
import sidebar from "./components/sidebar/en.json";
import adopt from "./pages/adopt/en.json";
import home from "./pages/home/en.json";
import history from "./pages/history/en.json";
import catalog from "./pages/catalog/en.json";
import cart from "./pages/cart/en.json";
import settings from "./pages/settings/en.json";
import app from "./pages/app/en.json";
import box from "./pages/box/en.json";

export const enTranslation = merge(
  sidebar,
  adopt,
  home,
  history,
  catalog,
  cart,
  settings,
  app,
  box
);
