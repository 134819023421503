import {
  Box,
  HStack,
  Icon,
  Link,
  Stack,
  Text,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import { FiGift } from "react-icons/fi";
import { AsyncImage } from "../async-image/AsyncImage";
import { Product } from "../../API";
import { OrderModal } from "../card/order-card/OrderModal";
import OrderProductInfo from "../card/order-card/OrderProductInfo";

export type CartProductMetaProps = {
  isGiftWrapping?: boolean;
  image: string;
  product: Product;
};

export const CartProductMeta = (props: CartProductMetaProps) => {
  const { isGiftWrapping = true, image, product } = props;

  return (
    <Stack direction="row" spacing="5" width="full">
      <OrderModal
        children={<OrderProductInfo product={product} />}
        modalTitle={product.name ?? ""}
        component={
          <Box position="relative">
            <AsyncImage
              height={75}
              src={`${image}?${product.productId}`}
              alt={product.name}
            />
            {product.isSoldOut && (
              <Box
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                display="flex"
                alignItems="center"
                justifyContent="center"
                bg="rgba(255, 255, 255, 0.7)"
              >
                <Text fontWeight="bold" fontSize="lg" color="orange">
                  Sold Out
                </Text>
              </Box>
            )}
          </Box>
        }
      />

      <Box pt="4" w="200px">
        <Stack spacing="0.5">
          <Text fontWeight="medium">{product.name}</Text>
          <Text
            color={mode("gray.600", "gray.400")}
            fontSize="sm"
            noOfLines={2}
          >
            {product.format.description}
          </Text>
        </Stack>
        {isGiftWrapping && (
          <HStack spacing="1" mt="3" color={mode("gray.600", "gray.400")}>
            <Icon as={FiGift} boxSize="4" />
            <Link fontSize="sm" textDecoration="underline">
              Add gift wrapping
            </Link>
          </HStack>
        )}
      </Box>
    </Stack>
  );
};
