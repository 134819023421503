import { Box, Spinner, Text } from "@chakra-ui/react";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import { LanguageContext } from "../../contexts/languageContext";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import { boxApi } from "../../services/boxes-api/box-api";
import { Icons } from "../../images/svg";
import { BoxWithCatalog } from "./components/BoxWithCatalog";
import { BoxWithoutCatalog } from "./components/BoxWithoutCatalog";

/* Nel navigateTo del banner, oltre al bannerId ci deve essere anche uno stato
 * Lo stato puo essere:
 * "newProduct" -> ti porta a navigare sui nuovi prodotti, con bannerId index secondario e indice primario boxId e stato newProduct. Torna array di prodotti
 * "discount" -> ti porta a navigare sugli sconti, con bannerId index secondario e indice primario boxId e stato discount. Torna array di prodotti
 * "mostPurchase" -> ti porta a navigare sugli acquisti piu frequenti, con bannerId index secondario e indice primario boxId e stato mostPurchase. Torna array di prodotti
 * "interess" -> ti porta a navigare sugli interessi, con bannerId index secondario e indice primario boxId e stato interess
 * "info" -> ti porta a navigare sulle info, con bannerId index secondario e indice primario boxId e stato info
 */

export const BoxPage = () => {
  const { bannerId } = useParams();

  const { language } = useContext(LanguageContext);
  const { t } = useTranslation();

  const boxesData = useQuery(
    ["getBoxByBannerId", bannerId, language],
    () => boxApi.getBoxByBannerId({ bannerId: bannerId || "", lang: language }),
    {
      enabled: !!bannerId,
    }
  );

  if (boxesData.isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (boxesData.isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        pt={{ base: "140px", md: "90px", xl: "90px" }}
      >
        <Icons.Error width={500} height={500} />
      </Box>
    );
  }

  if (boxesData.isSuccess && !boxesData.data) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50%"
        pt={{ base: "140px", md: "90px", xl: "90px" }}
      >
        <Text fontSize="2xl" fontWeight="bold">
          {t("box.emptyItems")}
        </Text>
      </Box>
    );
  }

  const boxes = boxesData.data;
  const hasProducts = boxes.products && boxes.products.length > 0;

  return (
    <Box pt={{ base: "140px", md: "90px", xl: "90px" }}>
      {hasProducts ? (
        <BoxWithCatalog box={boxes} />
      ) : (
        <BoxWithoutCatalog box={boxes} />
      )}
    </Box>
  );
};
