import { z } from "zod";
export interface Payment {
  method: "CREDIT_CARD" | "PAYPAL";
  status: "PAID" | "NOT_PAID" | "TO_PAY";
  totalProductsPrice: number;
  totalShipping: number;
  totalPrice: number;
  paymentId: string;
  creditCard?: {
    cardNumber: string;
    cardHolder: string;
    expirationDate: string;
    cvv: string;
  };
}

export const PaymentSchema = z.object({
  __typename: z.literal("Payment"),
  method: z.string(),
  status: z.string(),
  totalProductsPrice: z.number(),
  totalShipping: z.number(),
  totalPrice: z.number(),
  paymentId: z.string(),
  creditCard: z.union([
    z.object({
      __typename: z.literal("CreditCard"),
      cardNumber: z.string(),
      cardHolder: z.string(),
      expirationDate: z.string(),
      cvv: z.string(),
    }),
    z.null(),
  ]),
});
