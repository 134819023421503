import React from "react";
import { Box, Text, Center, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useContextApp } from "../../contexts/app/app";
import { Navigate } from "react-router-dom";
import FlagMenu from "../../components/menu/flagMenu";

const MaintenancePage = () => {
  const { t } = useTranslation();
  const { appConfig } = useContextApp();

  if (!appConfig.maintenance) {
    return <Navigate to={"/"} replace={true} />;
  }

  return (
    <>
      <Box display="flex" flex={1} justifyContent="flex-end" mr={8}>
        <FlagMenu />
      </Box>
      <Center h="90vh">
        <Box textAlign="center">
          <Spinner size="xl" color="blue.500" mb={4} />
          <Text fontSize="xl" fontWeight="bold">
            {t("app.underMaintenance.title")}
          </Text>
          <Text mt={2}>{t("app.underMaintenance.message")}</Text>
        </Box>
      </Center>
    </>
  );
};

export default MaintenancePage;
