import { Bestseller } from "./Bestseller";
import { CreditCard } from "./CreditCard";
import { Discount } from "./Discount";
import { Info } from "./Info";
import { Interess } from "./Interess";
import { New } from "./New";
import { Shipping } from "./Shipping";
import { NotFound } from "./404";
import { Error } from "./Error";
import { ItaFlag } from "./itaFlag";
import { EnFlag } from "./enFlag";

export const Icons = {
  Bestseller,
  Discount,
  Info,
  New,
  Shipping,
  Interess,
  CreditCard,
  NotFound,
  Error,
  ItaFlag,
  EnFlag,
};
