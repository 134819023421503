import React from "react";

export default interface IIcon {
  width?: number;
  height?: number;
  color?: string;
  strokeWidth?: number;
  "data-testid"?: string;
}

export interface ExtendedIIcon extends IIcon {
  strokeWidth?: number;
}

export const ItaFlag: React.FC<IIcon> = ({ width = 24, height = 24 }) => {
  return (
    <svg id="svg5885" viewBox="0 0 356 245" width={width} height={height}>
      <defs id="defs5887">
        <clipPath id="clipPath5856" clipPathUnits="userSpaceOnUse">
          <rect
            id="rect5858"
            height="1030.2"
            width="743.02"
            y="-3.4341"
            x="-501.04"
            fill="#ed1c24"
          />
        </clipPath>
      </defs>
      <g id="layer1" transform="translate(0 -807.36)">
        <g
          id="g5775"
          clipPath="url(#clipPath5856)"
          transform="matrix(.47820 -.0011356 .00056414 .24217 240 808.99)"
        >
          <path
            id="rect5759"
            transform="matrix(2.9089 0 0 5.722 -7556.9 -18260)"
            fill="#ed1c24"
            d="m2597.8 3191.2v174.75h82.5v-174.75h-82.5z"
          />
          <rect
            id="rect5763"
            height="625"
            width="187.5"
            y="187.5"
            x="406.25"
            fill="#fff"
          />
          <rect
            id="rect5765"
            height="1e3"
            width="250"
            y="0"
            x="-499.5"
            fill="#009247"
          />
          <rect
            id="rect5767"
            height="1e3"
            width="250"
            y="0"
            x="-249.5"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
};
