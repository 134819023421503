import { GetFFByUserIdQuery, FF } from "../../API";
import { getFFByUserId } from "../../graphql/queries";
import { FFSchema } from "../../models/ff/ff";
import { getAppError } from "../../utils/error";
import { api, Api } from "../api";
import { z } from "zod";

interface FFApiInterface {
  getFFByUserId: () => Promise<FF[]>;
}

const GetFFByUserIdQuerySchema = z.object({
  data: z.object({
    getFFByUserId: z.array(FFSchema),
  }),
});

export class FFApi implements FFApiInterface {
  // eslint-disable-next-line no-useless-constructor
  constructor(private api: Api) {}

  getFFByUserId = async () => {
    try {
      // Fetch the data using your API
      const queryResult = await this.api.query<GetFFByUserIdQuery>({
        query: getFFByUserId,
      });

      // Validate the query result using Zod
      const validatedData = GetFFByUserIdQuerySchema.parse(queryResult);

      return validatedData.data.getFFByUserId;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };
}

export const ffApi = new FFApi(api);
