import { GetHistoryByUserIdQueryVariables, Order } from "../../API";
import { getHistoryByUserId } from "../../graphql/queries";
import { OrderSchema } from "../../models/orders";

import { getAppError } from "../../utils/error";
import { api, Api } from "../api";
import { z } from "zod";
import { Observable } from "zen-observable-ts";
import { onHistoryOrderUpdated } from "../../graphql/subscriptions";

export type GraphQLSubscriptionResult<T> = {
  [key: string]: T;
};

interface HistoryApiInterface {
  getHistoryByUserId: (i: { lang: string }) => Promise<Order[]>;
  onHistoryOrderUpdated: () => Observable<{
    value: {
      data: GraphQLSubscriptionResult<"onHistoryOrderUpdated">;
    };
  }>;
}

const GetHistoryByUserIdQuerySchema = z.object({
  data: z.object({
    getHistoryByUserId: z.array(OrderSchema),
  }),
});

export class HistoryApi implements HistoryApiInterface {
  // eslint-disable-next-line no-useless-constructor
  constructor(private api: Api) {}

  getHistoryByUserId = async (i: { lang: string }) => {
    try {
      // Fetch the data using your API
      const queryResult =
        await this.api.query<GetHistoryByUserIdQueryVariables>({
          query: getHistoryByUserId,
          variables: i,
        });

      // Validate the query result using Zod
      const validatedData = GetHistoryByUserIdQuerySchema.parse(queryResult);

      return validatedData.data.getHistoryByUserId;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  onHistoryOrderUpdated = () => {
    try {
      const result = this.api.subscription<
        GraphQLSubscriptionResult<"onHistoryOrderUpdated">
      >({
        query: onHistoryOrderUpdated,
      });
      return result;
    } catch (err) {
      const error = getAppError(err);
      console.log("[onHistoryOrderUpdated] Error", error);
      throw new Error("[onHistoryOrderUpdated] Error");
    }
  };
}

export const historyApi = new HistoryApi(api);
