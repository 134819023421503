import { useContext, useEffect, useState } from "react";
import {
  Box,
  ButtonGroup,
  Button,
  Heading,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Switch,
  Spinner,
  Link,
} from "@chakra-ui/react";

import { useToast } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import { authApi } from "../../services/auth-api/auth-api";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { LanguageContext } from "../../contexts/languageContext";
import { Auth } from "aws-amplify";

interface UserMultistepFormProps {
  profile: {
    given_name: string;
    family_name: string;
    email: string;
    phone_number: string;
    "custom:newsletter": boolean;
  };
  setProfile: (profile: {
    given_name: string;
    family_name: string;
    email: string;
    phone_number: string;
    "custom:newsletter": boolean;
  }) => void;
}

const Form1: React.FC<UserMultistepFormProps> = ({ profile, setProfile }) => {
  const { t } = useTranslation();
  const newsletter = profile["custom:newsletter"];

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProfile({ ...profile, given_name: event.target.value });
  };

  const handleChangeSurname = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProfile({ ...profile, family_name: event.target.value });
  };

  const handleChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProfile({ ...profile, phone_number: event.target.value });
  };

  return (
    <>
      <Heading w="100%" textAlign={"center"} fontWeight="normal" mb="2%">
        {t("settings.profile.personalData")}
      </Heading>
      <Flex>
        <FormControl mr="5%">
          <FormLabel htmlFor="first-name" fontWeight={"normal"}>
            {t("settings.profile.name")}
          </FormLabel>
          <Input
            id="first-name"
            placeholder={t("settings.profile.name") + "..."}
            value={profile.given_name}
            onChange={handleChangeName}
          />
        </FormControl>

        <FormControl mr="5%">
          <FormLabel htmlFor="last-name" fontWeight={"normal"}>
            {t("settings.profile.surname")}
          </FormLabel>
          <Input
            id="last-name"
            placeholder={t("settings.profile.surname") + "..."}
            value={profile.family_name}
            onChange={handleChangeSurname}
          />
        </FormControl>
      </Flex>
      <Flex mt="5%">
        <FormControl mr="5%">
          <FormLabel htmlFor="first-name" fontWeight={"normal"}>
            {t("settings.profile.email")}
          </FormLabel>
          <Input
            id="first-name"
            placeholder={t("settings.profile.email") + "..."}
            value={profile.email}
            disabled
          />
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="last-name" fontWeight={"normal"}>
            {t("settings.profile.phone")}
          </FormLabel>
          <Input
            id="last-name"
            placeholder={t("settings.profile.phone") + "..."}
            value={profile.phone_number}
            onChange={handleChangePhone}
          />
        </FormControl>
      </Flex>
      <Flex mt="5%">
        <FormControl mr="5%">
          <FormLabel htmlFor="first-name" fontWeight={"normal"}>
            {t("settings.profile.newsletter")}
          </FormLabel>
          <Switch
            id="email-alerts"
            placeholder="Marketing"
            isChecked={newsletter}
            onChange={(e) =>
              setProfile({ ...profile, "custom:newsletter": e.target.checked })
            }
          />
        </FormControl>
      </Flex>
    </>
  );
};

export const UserMultistep = () => {
  const { t } = useTranslation();
  const { user } = useAuthenticator((context) => [context.user]);
  const { language } = useContext(LanguageContext);

  useEffect(() => {
    async function fetchData() {
      await Auth.currentAuthenticatedUser({ bypassCache: true });
    }
    fetchData();
  }, []);

  const privacyPolicyIta = "https://www.iubenda.com/privacy-policy/28264422";
  const privacyPolicyEng = "https://www.iubenda.com/privacy-policy/28730423";
  const cookiePolicyIta =
    "https://www.iubenda.com/privacy-policy/28264422/cookie-policy";
  const cookiePolicyEng =
    "https://www.iubenda.com/privacy-policy/28730423/cookie-policy";
  const termsConditionsIta =
    "https://www.iubenda.com/termini-e-condizioni/28264422";
  const termsConditionsEng =
    "https://www.iubenda.com/terms-and-conditions/28730423";

  const profile = user?.attributes;
  const [tmpProfile, setTmpProfile] = useState({
    given_name: profile ? profile.given_name : "",
    family_name: profile ? profile.family_name : "",
    phone_number: profile ? profile.phone_number : "",
    email: profile ? profile.email : "",
    "custom:newsletter": profile
      ? profile["custom:newsletter"]
        ? profile["custom:newsletter"].toLowerCase() === "true"
          ? true
          : false
        : false
      : false,
  });
  const toast = useToast();

  const { mutate: mutateUpdateProfile, isLoading: isLoadingUpdateProfile } =
    useMutation(
      async () => {
        const response = await authApi.updateAttributes(
          tmpProfile.given_name,
          tmpProfile.family_name,
          tmpProfile.phone_number,
          tmpProfile["custom:newsletter"]
        );
        return response;
      },
      {
        onError: (error) => {
          console.log("[profile-updateProfile]", error);
          toast({
            title: t("settings.profile.updateProfileError.title"),
            description: t("settings.profile.updateProfileError.message"),
            status: "error",
          });
        },
        onSuccess: async (response) => {
          toast({
            title: t("settings.profile.updateProfileSuccess.title"),
            description: t("settings.profile.updateProfileSuccess.message"),
            status: "success",
          });
        },
      }
    );

  if (isLoadingUpdateProfile) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50%"
        pt={{ base: "140px", md: "90px", xl: "90px" }}
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  return (
    <Box
      borderWidth="1px"
      rounded="lg"
      shadow="1px 1px 3px rgba(0,0,0,0.3)"
      bg="white"
      p={6}
      as="form"
    >
      <Form1 profile={tmpProfile} setProfile={setTmpProfile} />
      <ButtonGroup mt="5%" w="100%">
        <Flex w="100%" justifyContent="flex-end">
          <Button
            w="7rem"
            mr="5%"
            colorScheme="green"
            variant="outline"
            onClick={() =>
              setTmpProfile({
                given_name: profile?.given_name || "",
                family_name: profile?.family_name || "",
                phone_number: profile?.phone_number || "",
                email: profile?.email || "",
                "custom:newsletter": profile?.["custom:newsletter"] === "true",
              })
            }
          >
            {t("settings.profile.cancel")}
          </Button>
          <Button
            w="7rem"
            colorScheme="green"
            variant="solid"
            isDisabled={
              tmpProfile.given_name === profile?.given_name &&
              tmpProfile.family_name === profile?.family_name &&
              tmpProfile?.phone_number === profile?.phone_number &&
              tmpProfile?.["custom:newsletter"] ===
                (profile?.["custom:newsletter"] === "true")
            }
            onClick={() => mutateUpdateProfile()}
          >
            {t("settings.profile.submit")}
          </Button>
        </Flex>
      </ButtonGroup>
      <Box
        alignItems="center"
        display="flex"
        flexDirection={{ base: "column", md: "row" }}
        justifyItems="center"
        justifyContent={{ base: "center", md: "space-between" }}
        flex={1}
        width={{ base: "100%", md: "50%" }}
        mt="5%"
      >
        <Link
          href={language === "ita" ? privacyPolicyIta : privacyPolicyEng}
          isExternal
        >
          Privacy Policy
        </Link>

        <Link
          href={language === "ita" ? cookiePolicyIta : cookiePolicyEng}
          isExternal
        >
          Cookie Policy
        </Link>

        <Link
          href={language === "ita" ? termsConditionsIta : termsConditionsEng}
          isExternal
        >
          Terms and Conditions
        </Link>
      </Box>
    </Box>
  );
};
