interface AppError {
  data: unknown
  errors: {
    locations: {
      column: number
      line: number
    }[]
    message: string
  }[]
}

export const getAppError = (error: unknown): string => {
  const appSyncError = error as AppError
  if (appSyncError.errors && Array.isArray(appSyncError.errors)) {
    return appSyncError.errors[0].message
  }

  if (error instanceof Error) {
    return error.message
  }

  return 'Unknown error'
}

export const parseError = (error: any): string => JSON.stringify(error)
