import React from "react";

export default interface IIcon {
  width?: number;
  height?: number;
  color?: string;
  strokeWidth?: number;
  "data-testid"?: string;
}

export interface ExtendedIIcon extends IIcon {
  strokeWidth?: number;
}

export const Info: React.FC<IIcon> = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <g transform="translate(1 1)">
        <path
          fill="#FD9808"
          d="M331.8,7.533H178.2c-61.44,0-110.933,49.493-110.933,110.933v153.6
		C67.267,333.507,116.76,383,178.2,383h28.16c12.8,0,23.04,10.24,23.04,23.04v84.48c0,11.947,14.507,16.213,21.333,6.827
		l52.907-74.24c15.36-21.333,37.547-37.547,62.293-45.227c44.373-14.507,76.8-56.32,76.8-105.813v-153.6
		C442.733,57.027,393.24,7.533,331.8,7.533"
        />
        <path
          fill="#FFDD09"
          d="M313.88,7.533H170.52c-57.173,0-103.253,49.493-103.253,110.933v153.6
		c0,61.44,46.08,110.933,103.253,110.933h36.693c11.947,0,21.333,10.24,21.333,23.04v84.48c0,11.947,13.653,16.213,20.48,6.827
		l48.64-74.24c14.507-21.333,24.747-37.547,47.787-45.227c41.813-14.507,71.68-56.32,71.68-105.813v-153.6
		C417.133,57.027,371.053,7.533,313.88,7.533"
        />
        <path
          fill="#FFFFFF"
          d="M148.333,370.2c-33.28-18.773-55.467-55.467-55.467-98.133v-153.6
		c0-61.44,46.08-110.933,103.253-110.933H178.2c-61.44,0-110.933,49.493-110.933,110.933v153.6c0,49.493,32.427,91.307,76.8,105.813
		c0,0,0,0,0.853,0C144.92,375.32,146.627,371.907,148.333,370.2"
        />
        <g>
          <path
            fill="#54C9FD"
            d="M280.6,297.667V178.2v-17.067c0-9.387-7.68-17.067-17.067-17.067H229.4
			c-9.387,0-17.067,7.68-17.067,17.067s7.68,17.067,17.067,17.067h17.067v119.467H229.4c-9.387,0-17.067,7.68-17.067,17.067
			c0,9.387,7.68,17.067,17.067,17.067h68.267c9.387,0,17.067-7.68,17.067-17.067c0-9.387-7.68-17.067-17.067-17.067H280.6z"
          />
          <path
            fill="#54C9FD"
            d="M280.6,84.333c0-14.507-11.093-25.6-25.6-25.6c-14.507,0-25.6,11.093-25.6,25.6
			s11.093,25.6,25.6,25.6C269.507,109.933,280.6,98.84,280.6,84.333"
          />
        </g>
        <path
          d="M241.347,511c-1.707,0-4.267,0-5.973-0.853c-8.533-2.56-14.507-10.24-14.507-19.627v-84.48
		c0-7.68-6.827-14.507-14.507-14.507H178.2c-65.707,0-119.467-53.76-119.467-119.467v-153.6C58.733,52.76,112.493-1,178.2-1h153.6
		c65.707,0,119.467,53.76,119.467,119.467v153.6c0,52.053-33.28,97.28-82.773,113.493c-23.893,7.68-44.373,22.187-58.027,42.667
		l-52.053,74.24C254.147,507.587,248.173,511,241.347,511z M178.2,16.067c-56.32,0-102.4,46.08-102.4,102.4v153.6
		c0,56.32,46.08,102.4,102.4,102.4h28.16c17.067,0,31.573,14.507,31.573,31.573v84.48c0,2.56,1.707,2.56,2.56,3.413
		c0.853,0,2.56,0.853,3.413-0.853l52.907-74.24c16.213-23.04,39.253-39.253,66.56-48.64c42.667-13.653,70.827-52.907,70.827-97.28
		V118.467c0-56.32-46.08-102.4-102.4-102.4H178.2z"
        />
        <path
          d="M297.667,340.333H229.4c-14.507,0-25.6-11.093-25.6-25.6c0-14.507,11.093-25.6,25.6-25.6h8.533v-102.4H229.4
		c-14.507,0-25.6-11.093-25.6-25.6c0-14.507,11.093-25.6,25.6-25.6h34.133c14.507,0,25.6,11.093,25.6,25.6v128h8.533
		c14.507,0,25.6,11.093,25.6,25.6C323.267,329.24,312.173,340.333,297.667,340.333z M229.4,306.2c-5.12,0-8.533,3.413-8.533,8.533
		c0,5.12,3.413,8.533,8.533,8.533h68.267c5.12,0,8.533-3.413,8.533-8.533c0-5.12-3.413-8.533-8.533-8.533H280.6
		c-5.12,0-8.533-3.413-8.533-8.533V161.133c0-5.12-4.267-8.533-8.533-8.533H229.4c-5.12,0-8.533,3.413-8.533,8.533
		c0,5.12,3.413,8.533,8.533,8.533h17.067c5.12,0,8.533,3.413,8.533,8.533v119.467c0,5.12-3.413,8.533-8.533,8.533H229.4z"
        />
        <path
          d="M255,118.467c-18.773,0-34.133-15.36-34.133-34.133S236.227,50.2,255,50.2s34.133,15.36,34.133,34.133
		S273.773,118.467,255,118.467z M255,67.267c-9.387,0-17.067,7.68-17.067,17.067S245.613,101.4,255,101.4s17.067-7.68,17.067-17.067
		S264.387,67.267,255,67.267z"
        />
      </g>
    </svg>
  );
};
