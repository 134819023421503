import { Box, GetBoxByBannerIdQuery } from "../../API";
import { getBoxByBannerId } from "../../graphql/queries";

import { getAppError } from "../../utils/error";
import { api, Api } from "../api";

interface BannerApiInterface {
  getBoxByBannerId: (i: { bannerId: string; lang: string }) => Promise<Box>;
}

export class BoxApi implements BannerApiInterface {
  // eslint-disable-next-line no-useless-constructor
  constructor(private api: Api) {}

  getBoxByBannerId = async (i: { bannerId: string; lang: string }) => {
    try {
      const queryResult = await this.api.query<GetBoxByBannerIdQuery>({
        query: getBoxByBannerId,
        variables: i,
      });

      return queryResult?.data?.getBoxByBannerId as Box;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };
}

export const boxApi = new BoxApi(api);
