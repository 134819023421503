import {
  Box,
  Container,
  VStack,
  Grid,
  Divider,
  Flex,
  GridItem,
  chakra,
} from "@chakra-ui/react";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Image,
} from "@react-pdf/renderer";
import { Images } from "../../images";
import { useState } from "react";
import { AdoptMultiStepForm } from "../../components/form/adopt-multi-step";
import { useTranslation } from "react-i18next";

interface FeatureProps {
  heading: string;
  text: string;
}

const Feature = ({ heading, text }: FeatureProps) => {
  return (
    <GridItem>
      <chakra.h3 fontSize="xl" fontWeight="600">
        {heading}
      </chakra.h3>
      <chakra.p>{text}</chakra.p>
    </GridItem>
  );
};

export interface MyDocumentProps {
  nation: string;
  name: string;
  surname: string;
  region: string;
  cda: string;
}

const Adopt = () => {
  const { t } = useTranslation();
  const [myDocument, setMyDocument] = useState<MyDocumentProps>({
    nation: "",
    name: "",
    surname: "",
    region: "",
    cda: "",
  });
  return (
    <Box as={Container} maxW="7xl" mt={14} p={4}>
      <GridItem colSpan={1}>
        <VStack alignItems="flex-start" spacing="20px">
          <chakra.h2 fontSize="3xl" fontWeight="700">
            {t("adopt.title")}
          </chakra.h2>
        </VStack>
      </GridItem>
      <GridItem
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={12}
      >
        <AdoptMultiStepForm
          myDocument={myDocument}
          setMyDocument={setMyDocument}
        />
        <Flex
          h={"410px"}
          display={{ base: "none", sm: "none", md: "none", lg: "flex" }}
        >
          <PDFViewer showToolbar={false}>
            <MyDocument myDocument={myDocument} />
          </PDFViewer>
        </Flex>
      </GridItem>

      <Divider mt={12} mb={12} />
      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(4, 1fr)",
        }}
        gap={{ base: "8", sm: "12", md: "16" }}
      >
        <Feature
          heading={t("adopt.adoptionCerticate.title")}
          text={t("adopt.adoptionCerticate.description")}
        />
        <Feature
          heading={t("adopt.treeCultivation.title")}
          text={t("adopt.treeCultivation.description")}
        />
        <Feature
          heading={t("adopt.oilProduction.title")}
          text={t("adopt.oilProduction.description")}
        />
        <Feature
          heading={t("adopt.costOfAdoption.title")}
          text={t("adopt.costOfAdoption.description")}
        />
      </Grid>
    </Box>
  );
};

export default Adopt;

const styles = StyleSheet.create({
  image: {
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
    opacity: 0.1,
  },
  topRightText: {
    position: "absolute",
    top: "32px",
    right: "56px",
    marginHorizontal: "auto",
    textAlign: "center",
    justifyContent: "center",
  },
  centerText: {
    position: "absolute",
    top: "35%",
    right: "16%",
    left: "16%",
    marginHorizontal: "auto",
    textAlign: "center",
    justifyContent: "center",
    border: "2px solid green",
    borderRadius: "8px",
    padding: "8px",
  },
  bottomText: {
    position: "absolute",
    top: "48%",
    right: "16%",
    left: "16%",
    marginHorizontal: "auto",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "8px",
  },
  overBottomText: {
    position: "absolute",
    top: "55%",
    right: "16%",
    left: "16%",
    marginHorizontal: "auto",
    textAlign: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "8px",
  },
  decorateText: {
    fontSize: 20,
    fontFamily: "Helvetica-Oblique",
    lineHeight: 1.5,
  },
});

interface MyDocumentPDFProps {
  myDocument: MyDocumentProps;
}

const MyDocument: React.FC<MyDocumentPDFProps> = (props) => {
  return (
    <Document>
      <Page size="A4">
        <View style={styles.image}>
          <Image src={Images.Logo} />
        </View>
        <View style={styles.topRightText}>
          <Text>Numero: X </Text>
          <Text
            style={{
              marginTop: "16px",
            }}
          >
            Nazione: {props.myDocument.nation}
          </Text>
        </View>
        <View style={styles.centerText}>
          <Text style={styles.decorateText}>
            Certificato di adozione di un Albero di olive
          </Text>
          <Text style={styles.decorateText}>
            Certificate of the adoption of an olive tree
          </Text>
        </View>
        <View style={styles.bottomText}>
          <Text
            style={{
              width: "300px",
            }}
          >
            Nome: {props.myDocument.name}
          </Text>
          <Text
            style={{
              width: "300px",
            }}
          >
            Cognome: {props.myDocument.surname}
          </Text>
        </View>
        <View style={styles.overBottomText}>
          <Text
            style={{
              width: "300px",
            }}
          >
            Comune: {props.myDocument.region}
          </Text>
          <Text
            style={{
              width: "300px",
            }}
          >
            C.da: {props.myDocument.cda}
          </Text>
          <Text
            style={{
              width: "300px",
            }}
          >
            Foglio:X
          </Text>
          <Text
            style={{
              width: "300px",
            }}
          >
            Particella: X
          </Text>
        </View>
      </Page>
    </Document>
  );
};
