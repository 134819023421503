import {
  GetOrdersByUserIdQueryVariables,
  LambdaCheckoutOrderStripeMutationVariables,
  LambdaUpdateOrderMutationVariables,
  Order,
  OrderInput,
} from "../../API";
import {
  lambdaCheckoutOrderStripe,
  lambdaUpdateOrder,
} from "../../graphql/mutations";
import { getOrdersByUserId } from "../../graphql/queries";
import { OrderSchema } from "../../models/orders";

import { getAppError } from "../../utils/error";
import { api, Api } from "../api";
import { z } from "zod";
import { Observable } from "zen-observable-ts";
import { onOrderUpdated } from "../../graphql/subscriptions";

export type GraphQLSubscriptionResult<T> = {
  [key: string]: T;
};

interface OrderApiInterface {
  getOrderByUserId: (i: { lang: string }) => Promise<Order[]>;
  updateOrder: (i: { order: OrderInput }) => Promise<Order>;
  checkoutOrderStripe: (i: { order: OrderInput }) => Promise<String>;
  onOrderUpdated: () => Observable<{
    value: {
      data: GraphQLSubscriptionResult<"onOrderUpdated">;
    };
  }>;
}

const GetOrderByUserIdQuerySchema = z.object({
  data: z.object({
    getOrdersByUserId: z.array(OrderSchema),
  }),
});

const UpdateOrderMutationSchema = z.object({
  data: z.object({
    lambdaUpdateOrder: OrderSchema,
  }),
});

const CheckoutOrderStripe = z.object({
  data: z.object({
    lambdaCheckoutOrderStripe: z.string(),
  }),
});

export class OrderApi implements OrderApiInterface {
  // eslint-disable-next-line no-useless-constructor
  constructor(private api: Api) {}

  getOrderByUserId = async (i: { lang: string }) => {
    try {
      // Fetch the data using your API
      const queryResult = await this.api.query<GetOrdersByUserIdQueryVariables>(
        {
          query: getOrdersByUserId,
          variables: i,
        }
      );

      // Validate the query result using Zod
      const validatedData = GetOrderByUserIdQuerySchema.parse(queryResult);

      return validatedData.data.getOrdersByUserId;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  updateOrder = async (i: { order: OrderInput }) => {
    try {
      // Fetch the data using your API
      const mutationResult =
        await this.api.query<LambdaUpdateOrderMutationVariables>({
          query: lambdaUpdateOrder,
          variables: i,
        });

      // Validate the mutation result using Zod
      const validatedData = UpdateOrderMutationSchema.parse(mutationResult);

      return validatedData.data.lambdaUpdateOrder;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  checkoutOrderStripe = async (i: { order: OrderInput }) => {
    try {
      // Fetch the data using your API
      const mutationResult =
        await this.api.query<LambdaCheckoutOrderStripeMutationVariables>({
          query: lambdaCheckoutOrderStripe,
          variables: i,
        });

      // Validate the mutation result using Zod
      const validatedData = CheckoutOrderStripe.parse(mutationResult);

      return validatedData.data.lambdaCheckoutOrderStripe;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  onOrderUpdated = () => {
    try {
      const result = this.api.subscription<
        GraphQLSubscriptionResult<"onOrderUpdated">
      >({
        query: onOrderUpdated,
      });
      return result;
    } catch (err) {
      const error = getAppError(err);
      console.log("[onOrderUpdated] Error", error);
      throw new Error("[onOrderUpdated] Error");
    }
  };
}

export const orderApi = new OrderApi(api);
