import { Box, Spinner } from "@chakra-ui/react";
import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../pages/home/Home";
import { CheckRoutes } from "./CheckRoutes";
import Sidebar from "../components/drawer/Sidebar";
import Catalog from "../pages/catalog/Catalog";
import History from "../pages/history/History";
import Adopt from "../pages/adopt/Adopt";
import Cart from "../pages/cart/Cart";
import Orders from "../pages/orders/Orders";
import Settings from "../pages/settings/Settings";
import { AuthContextProvider } from "../contexts/auth-context-provider";
import Profile from "../pages/profile/Profile";
import { useQuery } from "@tanstack/react-query";
import { ffApi } from "../services/ff-api/ff-api";
import { CheckoutSuccess } from "../pages/checkout/CheckoutSuccess";
import FixedPlugin from "../components/fixedPlugin/FixedPlugin";
import { BoxPage } from "../pages/boxes/BoxPage";

export const AuthRoutes = () => {
  const ffs = useQuery(["getFFByUserId"], () => ffApi.getFFByUserId());

  if (ffs.isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (ffs.isError) {
    console.log("ffs error");
  }

  const adoptTree = ffs.data?.find((ff) => ff.ff === "adoptTree");

  return (
    <React.Suspense fallback={<Spinner />}>
      <Sidebar>
        <AuthContextProvider>
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/boxes/:bannerId" element={<BoxPage />} />
            <Route path="/history" element={<History />} />
            {!adoptTree && <Route path="/adopt" element={<Adopt />} />}
            <Route path="/catalog" element={<Catalog />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="/checkout-success" element={<CheckoutSuccess />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/settings" element={<Settings pt />} />
            <Route path="/*" element={<CheckRoutes />} />
          </Routes>
        </AuthContextProvider>
        <FixedPlugin />
      </Sidebar>
    </React.Suspense>
  );
};
