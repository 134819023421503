import { Box, Spinner, Stack, Text } from "@chakra-ui/react";
import { CaptionCarousel } from "../../components/carousel/Carousel";
import {
  joinHomeCards,
  sortCardsByPriorityDescending,
} from "../../utils/utils";
import { homeApi } from "../../services/home-api/home-api";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Icons } from "../../images/svg";
import { LanguageContext } from "../../contexts/languageContext";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { language } = useContext(LanguageContext);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const homeCards = useQuery(["getHomeCardsByUserId", language], () =>
    homeApi.getHomeCardsByUserId({ lang: language })
  );

  // Subscriptions
  useEffect(() => {
    const subscription = homeApi.onHomeCardUpdated().subscribe({
      next: (e: any) => {
        queryClient.invalidateQueries(["getHomeCardsByUserId", language]);
      },
      error: (error: any) => {
        console.error("HomeCard Subscription error", error);
      },
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [language, queryClient]);

  if (homeCards.isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (homeCards.isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        pt={{ base: "140px", md: "90px", xl: "90px" }}
      >
        <Icons.Error width={500} height={500} />
      </Box>
    );
  }

  const cards = homeCards.data;

  const sortedCardsDescending = joinHomeCards(
    sortCardsByPriorityDescending(cards)
  );

  const info = sortedCardsDescending.find(
    (card) => card.info && card.info.length > 0
  );

  if ((homeCards.isSuccess && homeCards.data.length === 0) || !info) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50%"
        pt={{ base: "140px", md: "90px", xl: "90px" }}
      >
        <Text fontSize="2xl" fontWeight="bold">
          {t("home.emptyItems")}
        </Text>
      </Box>
    );
  }

  return (
    <Box pt={{ base: "140px", md: "90px", xl: "90px" }}>
      <Stack spacing={100}>
        {sortedCardsDescending &&
          sortedCardsDescending.length > 0 &&
          sortedCardsDescending.map((card, index) => (
            <Stack key={index}>
              <CaptionCarousel card={card} />
            </Stack>
          ))}
      </Stack>
    </Box>
  );
};

export default Home;
