import {
  Card,
  CardHeader,
  Heading,
  CardBody,
  Stack,
  StackDivider,
  Box,
  Text,
  Divider,
  SimpleGrid,
  Icon,
  Switch,
} from "@chakra-ui/react";
import { FcInTransit } from "react-icons/fc";
import { Address, AddressInput } from "../../../API";
import { AddressInputSchema } from "../../../models/address";
import { useTranslation } from "react-i18next";

interface AddressCardProps {
  addresses: Address[];
  chageDefaultAddress: (address: AddressInput) => void;
}

export const AddressCard: React.FC<AddressCardProps> = ({
  addresses,
  chageDefaultAddress,
}) => {
  const { t } = useTranslation();
  // sort address by default
  addresses.sort((a, b) => {
    if (a.isDefault) {
      return -1;
    }
    if (b.isDefault) {
      return 1;
    }
    return 0;
  });

  return (
    <Stack>
      {addresses.map((address) => (
        <Card key={address.addressId} bg="green.100">
          <CardHeader bg="gray.200" borderRadius="md">
            <SimpleGrid columns={1}>
              <Box display="flex" alignItems="center">
                <Icon as={FcInTransit} w={10} h={10} />
                <Heading size="sm" ml="1%">
                  {t("settings.defaultDeliveryAddress")}
                </Heading>
                <Switch
                  ml="5%"
                  id="email-alerts"
                  placeholder={t("address.defaultDeliveryAddress") as string}
                  isChecked={address.isDefault}
                  onChange={() => {
                    if (addresses.length > 1 && address.isDefault === false) {
                      const addressInput = AddressInputSchema.parse(address);
                      chageDefaultAddress(addressInput);
                    }
                  }}
                />
              </Box>
            </SimpleGrid>
          </CardHeader>
          <Divider color="gray.400" />

          <CardBody>
            <Stack
              divider={<StackDivider borderColor="gray.400" />}
              spacing="4"
            >
              <Stack>
                <Text color={"gray.600"}>{address.recipient}</Text>
                <Text color={"gray.600"}>
                  {address.street}, {address.number}
                </Text>
                <Text color={"gray.600"}>
                  {address.zipCode}, {address.city}
                </Text>
                <Text color={"gray.600"}>
                  {address.region}, {address.country}
                </Text>
                <Text color={"gray.600"}>{address.phone}</Text>
                <Text color={"gray.600"}>{address.info}</Text>
              </Stack>
            </Stack>
          </CardBody>
        </Card>
      ))}
    </Stack>
  );
};
