import { Box, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

export const CheckoutSuccess = () => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <Text fontSize="3xl" fontWeight="bold">
        {t("history.payment.message")}
      </Text>
    </Box>
  );
};
