import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ShoppingCart } from "../../components/shopping-cart/shopping-cart";
import { cartApi } from "../../services/cart-api/cart-api";
import { Box, Spinner, useToast, Text } from "@chakra-ui/react";
import { Icons } from "../../images/svg";
import {
  catalogDefaultData,
  useContextCatalog,
} from "../../contexts/catalog/catalog";
import { useContext, useEffect, useState } from "react";
import { shippingApi } from "../../services/shipping-api/shipping-api";
import { addressApi } from "../../services/address-api/address-api";
import { useTranslation } from "react-i18next";
import { LanguageContext } from "../../contexts/languageContext";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";

const Cart = () => {
  const { t } = useTranslation();
  const { language } = useContext(LanguageContext);
  const queryClient = useQueryClient();
  const [isLoadingUpdateOrder, setIsLoadingUpdateOrder] = useState(false);
  queryClient.getMutationCache().subscribe(({ mutation }) => {
    if (
      mutation?.state.status === "loading" &&
      mutation?.options.mutationKey?.[0] === "updateOrder"
    ) {
      setIsLoadingUpdateOrder(true);
    }
    if (
      mutation?.state.status === "success" &&
      mutation?.options.mutationKey?.[0] === "updateOrder"
    ) {
      setIsLoadingUpdateOrder(false);
    }
    if (
      mutation?.state.status === "error" &&
      mutation?.options.mutationKey?.[0] === "updateOrder"
    ) {
      setIsLoadingUpdateOrder(false);
    }
  });

  const { user } = useAuthenticator((context) => [context.user]);

  const sub = user?.username || "";
  const role = user?.attributes?.["custom:role"] || "standard";
  const idRole = role === "standard" ? "standard" : `${sub}`;
  const { catalog, setCatalog } = useContextCatalog();
  const toast = useToast();
  const [initialCatalog, setInitialCatalog] = useState(catalogDefaultData);

  useEffect(() => {
    async function fetchData() {
      await Auth.currentAuthenticatedUser({ bypassCache: true });
    }
    fetchData();
  }, []);

  const addresses = useQuery(["getAddressByUserId", sub], () =>
    addressApi.getAddressByUserId({
      userId: sub,
    })
  );

  const cart = useQuery(
    ["getCartByUserId", sub, language],
    () =>
      cartApi.getCartByUserId({
        userId: sub,
        lang: language,
      }),
    {
      onSuccess: (response) => {
        setCatalog((prevCatalog) => ({
          ...prevCatalog,
          products: response ? response.products : [],
        }));
        setInitialCatalog((prevCatalog) => ({
          ...prevCatalog,
          products: response ? response.products : [],
        }));
      },
    }
  );

  const shippingCost = useQuery(["getShippingCost"], () =>
    shippingApi.getShippingCost()
  );

  const { mutate: mutateUpdateCart, isLoading: isLoadingUpdateCart } =
    useMutation(
      async () => {
        const response = await cartApi.updateCart({
          products: catalog.products,
          role: idRole,
        });
        return response;
      },
      {
        onError: (error) => {
          console.log("[productCard-updateCart]", error);
          toast({
            title: t("cart.updateCartError.title"),
            description: t("cart.updateCartError.description"),
            status: "error",
          });
        },
        onSuccess: async (response) => {
          setInitialCatalog((prevCatalog) => ({
            ...prevCatalog,
            products: catalog.products,
          }));
          toast({
            title: t("cart.updateCartSuccess.title"),
            description: t("cart.updateCartSuccess.description"),
            status: "success",
          });
        },
      }
    );

  const { mutate: mutateDeleteCart, isLoading: isLoadingDeleteCart } =
    useMutation(
      async () => {
        const response = await cartApi.deleteCart({
          cartId: cart.data?.cartId || "",
          userId: sub,
        });
        return response;
      },
      {
        onError: (error) => {
          console.log("[productCard-deleteCart]", error);
          toast({
            title: t("cart.removeAllCartError.title"),
            description: t("cart.removeAllCartError.description"),
            status: "error",
          });
        },
        onSuccess: async (response) => {
          setInitialCatalog(catalogDefaultData);
          setCatalog(catalogDefaultData);
          cart.refetch();
          toast({
            title: t("cart.removeAllCartSuccess.title"),
            description: t("cart.removeAllCartSuccess.description"),
            status: "success",
          });
        },
      }
    );

  if (
    cart.isLoading ||
    isLoadingUpdateCart ||
    isLoadingDeleteCart ||
    shippingCost.isLoading ||
    isLoadingUpdateOrder ||
    addresses.isLoading
  ) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (cart.isError || addresses.isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        pt={{ base: "140px", md: "90px", xl: "90px" }}
      >
        <Icons.Error width={500} height={500} />
      </Box>
    );
  }

  if (cart.isSuccess && !cart.data) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50%"
        pt={{ base: "140px", md: "90px", xl: "90px" }}
      >
        <Text fontSize="2xl" fontWeight="bold">
          {t("cart.emptyItems")}
        </Text>
      </Box>
    );
  }

  return (
    <Box pt={{ base: "140px", md: "90px", xl: "90px" }}>
      <ShoppingCart
        removeAllToCart={mutateDeleteCart}
        addAllToCart={mutateUpdateCart}
        disableAddAllToCart={
          catalog.products.reduce(
            (acc, curr) => acc + curr.selectedQuantity,
            0
          ) ===
          initialCatalog.products.reduce(
            (acc, curr) => acc + curr.selectedQuantity,
            0
          )
        }
      />
    </Box>
  );
};

export default Cart;
