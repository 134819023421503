import { ReactElement } from "react";
import {
  Box,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  Flex,
  Divider,
} from "@chakra-ui/react";
import { FcInTransit } from "react-icons/fc";
import { Icons } from "../../../images/svg";
import { Order } from "../../../API";
import { calculatePrice, formatPrice } from "../../../utils/utils";
import { useTranslation } from "react-i18next";

interface FeatureProps {
  title: string;
  text?: string;
  icon: ReactElement;
  children?: ReactElement;
}

const Feature = ({ title, text, icon, children }: FeatureProps) => {
  return (
    <Stack>
      <Flex
        w={16}
        h={16}
        align={"center"}
        justify={"center"}
        color={"white"}
        rounded={"full"}
        bg={"gray.100"}
        mb={1}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{title}</Text>
      {text && <Text color={"gray.600"}>{text}</Text>}
      {children}
    </Stack>
  );
};

interface OrderInfoProps {
  order: Order;
}

export const OrderInfo = ({ order }: OrderInfoProps) => {
  const { t } = useTranslation();

  const totalProducts = order.products.reduce(
    (acc, curr) => acc + (curr.selectedQuantity ?? acc),
    0
  );

  const totalPrice = order.products.reduce((acc, product) => {
    if (product.discount && !product.isSoldOut) {
      return acc + product.discount.discountPrice * product.selectedQuantity;
    }
    return acc + product.price * product.selectedQuantity;
  }, 0);

  return (
    <Box p={4} borderWidth={1} borderRadius="md">
      <SimpleGrid
        columns={{ base: 1, md: 3 }}
        spacing={10}
        display="flex"
        justifyContent="space-between"
      >
        <Feature
          icon={<Icon as={FcInTransit} w={10} h={10} />}
          title={t("history.orderHistoryCard.details.deliveryAddress")}
        >
          <Stack>
            <Text color={"gray.600"}>{order.address.recipient}</Text>
            <Text color={"gray.600"}>
              {order.address.street}, {order.address.number}
            </Text>
            <Text color={"gray.600"}>
              {order.address.zipCode}, {order.address.city}
            </Text>
          </Stack>
        </Feature>
        <Feature
          icon={<Icons.CreditCard width={40} height={40} />}
          title={t("history.orderHistoryCard.details.orderSummary")}
        >
          <Stack>
            {order.products.map((product) => {
              return (
                <Stack key={product.productId}>
                  <Text fontSize="xs" color={"gray.600"} flexDirection="row">
                    {product.name} - {product.selectedQuantity} x{" "}
                    {formatPrice(product.price)} € =
                    <Box display="flex">
                      <Text
                        fontWeight="bold"
                        flexDirection="row"
                        style={{
                          textDecoration: product.discount
                            ? "line-through"
                            : "none",
                          color: product.discount ? "gray" : "black",
                        }}
                      >
                        {calculatePrice(product, false)} €
                      </Text>
                      {product.discount && (
                        <Box color={"black"} pl={2}>
                          <Box fontWeight="bold" pr={2}>
                            {calculatePrice(product, !!product.discount)} €
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Text>
                  <Divider borderColor="gray.500" />
                </Stack>
              );
            })}
            <Divider borderColor="gray.500" />
            <Text fontSize="xs" color={"gray.600"} fontWeight="semibold">
              {t("history.orderHistoryCard.details.productsTotal")}{" "}
              {totalProducts} - {formatPrice(totalPrice)} €
            </Text>

            <Text fontSize="xs" color={"gray.600"} fontWeight="semibold">
              {t("history.orderHistoryCard.details.deliveryTotal")}{" "}
              {formatPrice(order.payment.totalShipping)} €
            </Text>

            <Text color={"gray.600"} fontWeight="bold">
              {t("history.orderHistoryCard.details.total")}{" "}
              {formatPrice(totalPrice + order.payment.totalShipping)} €
            </Text>
          </Stack>
        </Feature>
      </SimpleGrid>
    </Box>
  );
};
