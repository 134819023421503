import { Box, Center, Spinner, Text } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, useLocation } from "react-router-dom";
import FlagMenu from "../../components/menu/flagMenu";
import { v4 as uuidv4 } from "uuid";

export interface App {
  maintenance: boolean;
}

export const appDefaultData: App = {
  maintenance: false,
};

type AppContextProps = {
  appConfig: App;
};

const defaultState = {
  appConfig: appDefaultData,
};

const AppContext = createContext<AppContextProps>(defaultState);

export const useContextApp = () => useContext(AppContext);

const { REACT_APP_CDN } = process.env;

export const AppProvider = ({ children }: any) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [appConfig, setAppConfing] = useState(appDefaultData);
  const id = uuidv4();

  const appJson = useQuery(
    ["appJson"],
    () => fetch(`${REACT_APP_CDN}/app.json?${id}`).then((res) => res.json()),
    {
      onSuccess: (data) => {
        setAppConfing(data);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  if (appJson.isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (appJson.isError) {
    return (
      <>
        <Box display="flex" flex={1} justifyContent="flex-end" mr={8}>
          <FlagMenu />
        </Box>
        <Center h="90vh">
          <Box textAlign="center">
            <Spinner size="xl" color="blue.500" mb={4} />
            <Text fontSize="xl" fontWeight="bold">
              {t("app.underMaintenance.title")}
            </Text>
            <Text mt={2}>{t("app.underMaintenance.message")}</Text>
          </Box>
        </Center>
      </>
    );
  }

  if (appConfig.maintenance && location.pathname !== "/maintenance") {
    return <Navigate to={"/maintenance"} replace={true} />;
  }

  return (
    <AppContext.Provider value={{ appConfig }}>{children}</AppContext.Provider>
  );
};
