import React from "react";

const defaultState = {
  language: "en",
  setLanguage: () => null,
};

type LanguageContextProps = {
  language: string;
  setLanguage: (language: string) => void;
};

export const LanguageContext =
  React.createContext<LanguageContextProps>(defaultState);

export const LanguageContextProvider = ({ children }: any) => {
  const [language, setLanguage] = React.useState("it");

  React.useEffect(() => {
    // Perform localStorage action
    const item = localStorage.getItem("i18nLng");
    if (item) {
      setLanguage(item);
    }
  }, []);

  const value = {
    language: language === "it" ? "ita" : language,
    setLanguage,
  };

  return (
    <LanguageContext.Provider value={value}>
      {children}
    </LanguageContext.Provider>
  );
};
