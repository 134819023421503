import { API, GraphQLResult, GraphQLSubscription } from "@aws-amplify/api";
import { Observable } from "zen-observable-ts";

import * as Types from "./api.types";

/**
 * Manages all requests to the API.
 */
export class Api {
  query<T>(options: Types.GraphQLOptions) {
    return API.graphql(options) as unknown as Promise<GraphQLResult<T>>;
  }

  mutation<T>(options: Types.GraphQLOptions) {
    return API.graphql(options) as unknown as Promise<GraphQLResult<T>>;
  }

  subscription<T>(options: Types.GraphQLOptions) {
    return API.graphql<GraphQLSubscription<T>>(
      options
    ) as unknown as Observable<{
      value: { data: T };
    }>;
  }
}

export const api = new Api();
