import {
  Box,
  ButtonGroup,
  Button,
  Heading,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";

import { useToast } from "@chakra-ui/react";
import { MyDocumentProps } from "../../pages/adopt/Adopt";
import { useTranslation } from "react-i18next";

interface AdoptMultiStepFormProps {
  myDocument: MyDocumentProps;
  setMyDocument: (myDocument: MyDocumentProps) => void;
}

const Form1: React.FC<AdoptMultiStepFormProps> = ({
  myDocument,
  setMyDocument,
}) => {
  const { t } = useTranslation();
  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMyDocument({ ...myDocument, name: event.target.value });
  };

  const handleChangeSurname = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMyDocument({ ...myDocument, surname: event.target.value });
  };

  const handleChangeRegion = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMyDocument({ ...myDocument, region: event.target.value });
  };

  const handleChangeNation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMyDocument({ ...myDocument, nation: event.target.value });
  };

  const handleChangeCda = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMyDocument({ ...myDocument, cda: event.target.value });
  };

  return (
    <>
      <Heading w="100%" textAlign={"center"} fontWeight="normal" mb="2%">
        {t("adopt.setData.title")}
      </Heading>
      <Flex>
        <FormControl mr="5%">
          <FormLabel htmlFor="first-name" fontWeight={"normal"}>
            {t("adopt.setData.name")}
          </FormLabel>
          <Input
            id="first-name"
            placeholder={t("adopt.setData.name") as string}
            value={myDocument.name}
            onChange={handleChangeName}
          />
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="last-name" fontWeight={"normal"}>
            {t("adopt.setData.surname")}
          </FormLabel>
          <Input
            id="last-name"
            placeholder={t("adopt.setData.surname") as string}
            value={myDocument.surname}
            onChange={handleChangeSurname}
          />
        </FormControl>
      </Flex>
      <Flex mt="5%">
        <FormControl mr="5%">
          <FormLabel htmlFor="first-name" fontWeight={"normal"}>
            {t("adopt.setData.municipality")}
          </FormLabel>
          <Input
            id="first-name"
            placeholder={t("adopt.setData.municipality") as string}
            value={myDocument.region}
            onChange={handleChangeRegion}
          />
        </FormControl>

        <FormControl>
          <FormLabel htmlFor="last-name" fontWeight={"normal"}>
            {t("adopt.setData.nationality")}
          </FormLabel>
          <Input
            id="last-name"
            placeholder={t("adopt.setData.nationality") as string}
            value={myDocument.nation}
            onChange={handleChangeNation}
          />
        </FormControl>
      </Flex>

      <Flex mt="5%">
        <FormControl mr="5%">
          <FormLabel htmlFor="first-name" fontWeight={"normal"}>
            {t("adopt.setData.district")}
          </FormLabel>
          <Select
            placeholder={t("adopt.setData.district") as string}
            value={myDocument.cda}
            onChange={handleChangeCda as any}
          >
            <option>Pietralunga</option>
            <option>Margi-Faro Cavallaccio</option>
            <option>Schettino</option>
            <option>Gallinella</option>
            <option>Zotto</option>
          </Select>
        </FormControl>
      </Flex>
    </>
  );
};

export const AdoptMultiStepForm: React.FC<AdoptMultiStepFormProps> = ({
  myDocument,
  setMyDocument,
}) => {
  const toast = useToast();
  const { t } = useTranslation();

  return (
    <>
      <Box
        borderWidth="1px"
        rounded="lg"
        shadow="1px 1px 3px rgba(0,0,0,0.3)"
        bg="white"
        maxWidth={800}
        p={6}
        as="form"
      >
        <Form1 myDocument={myDocument} setMyDocument={setMyDocument} />
        <ButtonGroup mt="5%" w="100%">
          <Flex w="100%" justifyContent="flex-end">
            <Button
              w="7rem"
              colorScheme="green"
              variant="solid"
              onClick={() => {
                toast({
                  title: t("adopt.onSuccess.title"),
                  description: t("adopt.onSuccess.description"),
                  status: "success",
                  duration: 3000,
                  isClosable: true,
                  size: "sm",
                });
              }}
            >
              {t("adopt.setData.submit")}
            </Button>
          </Flex>
        </ButtonGroup>
      </Box>
    </>
  );
};
