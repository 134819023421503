import { Box as ChakraBox, SimpleGrid, Text } from "@chakra-ui/react";
import { Box } from "../../../API";
import { FC } from "react";
import { ProductCard } from "../../../components/card/product-card/ProductCard";

interface BoxWithCatalogProps {
  box: Box;
}

const { REACT_APP_CDN } = process.env;
export const BoxWithCatalog: FC<BoxWithCatalogProps> = ({ box }) => {
  return (
    <ChakraBox>
      <Text fontSize="2xl" fontWeight="bold">
        {box.title}
      </Text>
      <Text fontSize="xl">{box.description}</Text>
      <SimpleGrid
        columns={{
          base: 1,
          md: 2,
          lg: 3,
          xl: 4,
        }}
        mt={6}
        mb={32}
        spacing={4}
      >
        {box.products &&
          box.products.map((item) => {
            const path =
              item.productType.toLowerCase() === "oil" ? "oil" : "olive";
            const image = `${REACT_APP_CDN}/${path}/${item.productId}.png`;

            return (
              <ProductCard
                key={item.productId}
                product={item}
                cardImage={image}
              />
            );
          })}
      </SimpleGrid>
    </ChakraBox>
  );
};
