import { Navigate } from "react-router-dom";
import { Authenticator } from "./components/Authenticator";

export const Signup = () => {
  return (
    <Authenticator>
      <Navigate to="/home" replace={true} />
    </Authenticator>
  );
};
