import {
  Card,
  CardHeader,
  Heading,
  CardBody,
  Stack,
  StackDivider,
  Box,
  Text,
  Divider,
  SimpleGrid,
  Button,
} from "@chakra-ui/react";
import { formatPrice } from "../../../utils/utils";
import { OrderModal } from "./OrderModal";
import { OrderInfo } from "./OrderInfo";
import OrderProductInfo from "./OrderProductInfo";
import { Order } from "../../../API";
import { OrderHistoryModal } from "./OrderHistoryModal";
import { useTranslation } from "react-i18next";
import { formattedDate } from "../../../utils/date";

interface OrderCardProps {
  order: Order;
  onPay: () => void;
}

export const OrderCard: React.FC<OrderCardProps> = ({ order, onPay }) => {
  const { t } = useTranslation();
  const statusLabel = {
    CREATED: t("history.orderHistoryCard.status.created"),
    ARRIVED: t("history.orderHistoryCard.status.arrived"),
    STARTED: t("history.orderHistoryCard.status.started"),
    READY: t("history.orderHistoryCard.status.ready"),
    DELIVERING: t("history.orderHistoryCard.status.delivering"),
    DELIVERED: t("history.orderHistoryCard.status.delivered"),
    CANCELED: t("history.orderHistoryCard.status.canceled"),
    REJECTED: t("history.orderHistoryCard.status.rejected"),
    COMPLETED: t("history.orderHistoryCard.status.completed"),
  };
  return (
    <Card bg="green.100">
      <CardHeader bg="gray.200" borderRadius="md">
        <SimpleGrid columns={4} spacing={10} minChildWidth="120px">
          <Box>
            <Heading size="sm">{t("history.orderHistoryCard.start")}</Heading>
            <Text>{formattedDate(order.startDate)}</Text>
          </Box>
          <Box>
            <Heading size="sm">{t("history.orderHistoryCard.total")}</Heading>
            <Text>{`${formatPrice(order.payment.totalPrice)} €`}</Text>
          </Box>
          <Box>
            <Heading size="sm">{t("history.orderHistoryCard.sendTo")}</Heading>
            <Text>{order.address.recipient}</Text>
          </Box>
          <Box>
            <Heading size="sm">
              {t("history.orderHistoryCard.details.title")}
            </Heading>

            <OrderHistoryModal
              size="2xl"
              children={<OrderInfo order={order} />}
              modalTitle={t("history.orderHistoryCard.details.details")}
            />
          </Box>
        </SimpleGrid>
      </CardHeader>
      <Divider color="gray.400" />

      <CardBody>
        <Stack divider={<StackDivider borderColor="gray.400" />} spacing="4">
          <Box alignItems="center">
            <Box mb={3}>
              <Heading size="xs">
                {t("history.orderName")} {order.orderName}
              </Heading>
              <Heading mt={2} size="xs">
                {t("history.orderHistoryCard.status.title")}
                {": "}
                {
                  statusLabel[
                    order.tracking[order.tracking.length - 1]
                      .status as keyof typeof statusLabel
                  ]
                }
              </Heading>
              <Text pt="2" fontSize="sm">
                {formattedDate(
                  order.tracking[order.tracking.length - 1].update
                )}
              </Text>
              <Text pt="2" fontSize="sm">
                {order.info}
              </Text>
            </Box>
            {order.payment.status === "TO_PAY" &&
              order.status !== "CANCELED" &&
              order.status !== "REJECTED" && (
                <Button
                  ml="auto"
                  size="sm"
                  colorScheme="green"
                  onClick={() => onPay()}
                >
                  {t("history.orderHistoryCard.checkout")}
                </Button>
              )}
            {order.payment.status === "PAID" && (
              <Text
                ml="auto"
                size="sm"
                colorScheme="green"
                fontSize="sm"
                fontWeight="bold"
              >
                {t("history.orderHistoryCard.paid")}
              </Text>
            )}
          </Box>
          <Box>
            <Heading size="xs">
              {t("history.orderHistoryCard.products.title")}
            </Heading>

            {order.products.map((product) => (
              <Box key={product.productId} display="flex" alignItems="center">
                <Box w="50%">
                  <OrderModal
                    children={<OrderProductInfo product={product} />}
                    modalTitle={product.name ?? ""}
                    component={
                      <Text fontSize="sm" textDecoration="underline">
                        {product.name ?? ""}
                      </Text>
                    }
                  />
                </Box>
                <Text pt="2" fontSize="sm" ml={8}>
                  x {product.selectedQuantity}
                </Text>
              </Box>
            ))}
          </Box>
        </Stack>
      </CardBody>
    </Card>
  );
};
