import { z } from "zod";

export const DiscountInputSchema = z.object({
  discountPercentage: z.number(),
  discountPrice: z.number(),
});

export const FormatInputSchema = z.object({
  unit: z.string(), // l, ml, kg, g, other
  unitPrice: z.number(), // 0.5, 1, 2, 3, 4, 5, 10, 20, 50, 100, 200, 500, 1000
  unitQuantity: z.number(), // 0.5, 1, 2, 3, 4, 5, 10, 20, 50, 100, 200, 500, 1000
  formatType: z.string(), // fusti
  formatQuantity: z.number(), // 2
  boxType: z.string(), // pedana
  boxTypeQuantity: z.number(), // 1
  boxWeight: z.number(), // 1
  description: z.string(), // string
});

export const ProductInputSchema = z.object({
  isThisYear: z.boolean(), // oil or olive from this campaign
  line: z.string(), //Natale, Simone, Cuscunà, etc
  price: z.number(), // price per unit
  certifications: z.array(z.string()), //bio, dop, etc
  attribute: z.array(z.string()), //fruttato, delicato, etc
  productId: z.string(), // unique id
  description: z.string(), // description of the product
  productType: z.string(), //oil, olive, etc
  sizeType: z.union([z.string(), z.null()]), // only for olive (00, 01, 02, 03, 04, 05)
  name: z.string(), // Natatle, Simone, Cuscunà, etc
  isNew: z.boolean(), // is new product
  isSoldOut: z.boolean(), // is sold out
  users: z.union([z.array(z.string()), z.null()]),
  discount: z.union([DiscountInputSchema, z.null()]), // discount if present
  format: FormatInputSchema, // format of the product
  selectedQuantity: z.number(), // quantity selected by the user
});

export const DiscountSchema = z.object({
  __typename: z.literal("Discount"),
  discountPercentage: z.number(),
  discountPrice: z.number(),
});

export const ArrayProductInputSchema = z.array(ProductInputSchema);

// unit = kg
// unitQuantity = 130
// formatType = fusti
// formatQuantity = 2
// boxType = pedana
// boxTypeQuantity = 1
// description = "string"

export const FormatSchema = z.object({
  __typename: z.literal("Format"),
  unit: z.string(), // l, ml, kg, g, other
  unitPrice: z.number(), // 0.5, 1, 2, 3, 4, 5, 10, 20, 50, 100, 200, 500, 1000
  unitQuantity: z.number(), // 0.5, 1, 2, 3, 4, 5, 10, 20, 50, 100, 200, 500, 1000
  formatType: z.string(), // fusti
  formatQuantity: z.number(), // 2
  boxType: z.string(), // pedana
  boxTypeQuantity: z.number(), // 1
  boxWeight: z.number(), // 1
  description: z.string(), // string
});

export const ProductSchema = z.object({
  __typename: z.literal("Product"),
  isThisYear: z.boolean(), // oil or olive from this campaign
  line: z.string(), //Natale, Simone, Cuscunà, etc
  price: z.number(), // price per unit
  certifications: z.array(z.string()), //bio, dop, etc
  attribute: z.array(z.string()), //fruttato, delicato, etc
  productId: z.string(), // unique id
  description: z.string(), // description of the product
  productType: z.string(), //oil, olive, etc
  sizeType: z.union([z.string(), z.null()]), // only for olive (00, 01, 02, 03, 04, 05)
  name: z.string(), // Natatle, Simone, Cuscunà, etc
  isNew: z.boolean(), // is new product
  isSoldOut: z.boolean(), // is sold out
  users: z.union([z.array(z.string()), z.null()]),
  discount: z.union([DiscountSchema, z.null()]), // discount if present
  format: FormatSchema, // format of the product
  selectedQuantity: z.number(), // quantity selected by the user
});
