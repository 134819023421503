/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCatalogUpdated = /* GraphQL */ `
  subscription OnCatalogUpdated {
    onCatalogUpdated {
      attribute
      certifications
      description {
        en
        ita
        __typename
      }
      discount {
        discountPercentage
        discountPrice
        __typename
      }
      format {
        boxType
        boxTypeQuantity
        boxWeight
        description {
          en
          ita
          __typename
        }
        formatQuantity
        formatType
        unit
        unitPrice
        unitQuantity
        __typename
      }
      isNew
      isSoldOut
      isThisYear
      line
      name {
        en
        ita
        __typename
      }
      price
      productId
      productStatus
      productType
      roleType
      selectedQuantity
      sizeType
      users
      __typename
    }
  }
`;
export const onHistoryOrderUpdated = /* GraphQL */ `
  subscription OnHistoryOrderUpdated {
    onHistoryOrderUpdated {
      address {
        addressId
        city
        country
        email
        formattedAddress
        info
        isDefault
        lat
        lng
        number
        phone
        recipient
        region
        street
        userId
        zipCode
        __typename
      }
      endDate
      estimateDate
      info {
        en
        ita
        __typename
      }
      orderId
      orderName
      payment {
        creditCard {
          cardHolder
          cardNumber
          cvv
          expirationDate
          __typename
        }
        method
        paymentId
        status
        totalPrice
        totalProductsPrice
        totalShipping
        __typename
      }
      products {
        attribute
        certifications
        description {
          en
          ita
          __typename
        }
        discount {
          discountPercentage
          discountPrice
          __typename
        }
        format {
          boxType
          boxTypeQuantity
          boxWeight
          description {
            en
            ita
            __typename
          }
          formatQuantity
          formatType
          unit
          unitPrice
          unitQuantity
          __typename
        }
        isNew
        isSoldOut
        isThisYear
        line
        name {
          en
          ita
          __typename
        }
        price
        productId
        productStatus
        productType
        roleType
        selectedQuantity
        sizeType
        users
        __typename
      }
      role
      startDate
      status
      tracking {
        status
        update
        __typename
      }
      userId
      __typename
    }
  }
`;
export const onHomeCardUpdated = /* GraphQL */ `
  subscription OnHomeCardUpdated {
    onHomeCardUpdated {
      color
      homeCardId
      info {
        description {
          en
          ita
          __typename
        }
        navigateTo
        priority
        status
        title {
          en
          ita
          __typename
        }
        __typename
      }
      name {
        en
        ita
        __typename
      }
      priority
      status
      type
      users
      __typename
    }
  }
`;
export const onOrderUpdated = /* GraphQL */ `
  subscription OnOrderUpdated {
    onOrderUpdated {
      address {
        addressId
        city
        country
        email
        formattedAddress
        info
        isDefault
        lat
        lng
        number
        phone
        recipient
        region
        street
        userId
        zipCode
        __typename
      }
      endDate
      estimateDate
      info {
        en
        ita
        __typename
      }
      orderId
      orderName
      payment {
        creditCard {
          cardHolder
          cardNumber
          cvv
          expirationDate
          __typename
        }
        method
        paymentId
        status
        totalPrice
        totalProductsPrice
        totalShipping
        __typename
      }
      products {
        attribute
        certifications
        description {
          en
          ita
          __typename
        }
        discount {
          discountPercentage
          discountPrice
          __typename
        }
        format {
          boxType
          boxTypeQuantity
          boxWeight
          description {
            en
            ita
            __typename
          }
          formatQuantity
          formatType
          unit
          unitPrice
          unitQuantity
          __typename
        }
        isNew
        isSoldOut
        isThisYear
        line
        name {
          en
          ita
          __typename
        }
        price
        productId
        productStatus
        productType
        roleType
        selectedQuantity
        sizeType
        users
        __typename
      }
      role
      startDate
      status
      tracking {
        status
        update
        __typename
      }
      userId
      __typename
    }
  }
`;
