import { GetShippingCostQuery, ShippingCost } from "../../API";
import { getShippingCost } from "../../graphql/queries";
import { ShippingSchema } from "../../models/shipping";

import { getAppError } from "../../utils/error";
import { api, Api } from "../api";
import { z } from "zod";

interface ShippingApiInterface {
  getShippingCost: () => Promise<ShippingCost[]>;
}

const GetShippingCostSchema = z.object({
  data: z.object({
    getShippingCost: z.array(ShippingSchema),
  }),
});

export class ShippingApi implements ShippingApiInterface {
  // eslint-disable-next-line no-useless-constructor
  constructor(private api: Api) {}

  getShippingCost = async () => {
    try {
      const queryResult = await this.api.query<GetShippingCostQuery>({
        query: getShippingCost,
      });

      // Validate the query result using Zod
      const validatedData = GetShippingCostSchema.parse(queryResult);

      return validatedData.data.getShippingCost;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };
}

export const shippingApi = new ShippingApi(api);
