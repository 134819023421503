import React from "react";

export default interface IIcon {
  width?: number;
  height?: number;
  color?: string;
  strokeWidth?: number;
  "data-testid"?: string;
}

export interface ExtendedIIcon extends IIcon {
  strokeWidth?: number;
}

export const EnFlag: React.FC<IIcon> = ({ width = 24, height = 24 }) => {
  return (
    <svg viewBox="0 0 1000 500" width={width} height={height}>
      <g id="g578" transform="scale(16.667)">
        <rect
          id="rect124"
          strokeWidth="1pt"
          fill="#000066"
          height="30"
          width="60"
          y="0"
          x="0"
        />
        <g id="g584">
          <path
            id="path146"
            strokeWidth="1pt"
            fill="#ffffff"
            d="m0 0v3.3541l53.292 26.646h6.708v-3.354l-53.292-26.646h-6.708zm60 0v3.354l-53.292 26.646h-6.708v-3.354l53.292-26.646h6.708z"
          />
          <path
            id="path136"
            strokeWidth="1pt"
            fill="#ffffff"
            d="m25 0v30h10v-30h-10zm-25 10v10h60v-10h-60z"
          />
          <path
            id="path141"
            strokeWidth="1pt"
            fill="#cc0000"
            d="m0 12v6h60v-6h-60zm27-12v30h6v-30h-6z"
          />
          <path
            id="path150"
            strokeWidth="1pt"
            fill="#cc0000"
            d="m0 30l20-10h4.472l-20 10h-4.472zm0-30l20 10h-4.472l-15.528-7.7639v-2.2361zm35.528 10l20-10h4.472l-20 10h-4.472zm24.472 20l-20-10h4.472l15.528 7.764v2.236z"
          />
        </g>
      </g>
    </svg>
  );
};
