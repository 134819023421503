import ReactDOM from "react-dom/client";
import { extendTheme, ChakraProvider, CSSReset } from "@chakra-ui/react";
import App from "./App";

const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: "green.200",
      },
    },
  },
  fonts: {
    heading: `'Open Sans', sans-serif`,
    body: `'Raleway', sans-serif`,
  },
  breakpoints: {
    base: "0em", // 0px, ~mobile
    sm: "30em", // 480px, ~phone
    md: "48em", // 768px, ~tablet
    lg: "62em", // 992px, ~desktop
    xl: "80em", // 1280px, ~large desktop
    "2xl": "96em", // 1536px, ~large desktop
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ChakraProvider theme={theme}>
    <CSSReset />
    <App />
  </ChakraProvider>
);
