import { useTheme } from "@chakra-ui/react";
import React from "react";
import { ProgressBar } from "react-loader-spinner";
import { Image as ChakraImage } from "@chakra-ui/react";
import { Images } from "../../images";

export const AsyncImage = (props: any) => {
  const [loadedSrc, setLoadedSrc] = React.useState(null);
  const [error, setError] = React.useState(false);
  const theme = useTheme();

  React.useEffect(() => {
    setLoadedSrc(null);
    setError(false);

    if (props.src) {
      const handleLoad = () => {
        setLoadedSrc(props.src);
      };
      const handleError = () => {
        setError(true);
      };
      const image = new Image();
      image.addEventListener("load", handleLoad);
      image.addEventListener("error", handleError);
      image.src = props.src;
      return () => {
        image.removeEventListener("load", handleLoad);
        image.removeEventListener("error", handleError);
      };
    }
  }, [props.src]);

  if (error) {
    return <ChakraImage {...props} src={Images.SquaredLogo} />;
  }

  if (loadedSrc === props.src) {
    return <ChakraImage {...props} />;
  }

  return (
    <ProgressBar
      height="80"
      width="80"
      ariaLabel="progress-bar-loading"
      wrapperStyle={{}}
      wrapperClass="progress-bar-wrapper"
      borderColor={theme.colors.green[500]}
      barColor={theme.colors.green[300]}
    />
  );
};
