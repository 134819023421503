import { format, parseISO } from "date-fns";
import { it } from "date-fns/locale";

export const formattedDate = (date: string) => {
  const parseDate = parseISO(date);
  const formattedDate = format(parseDate, "yyyy/MM/dd HH:mm", { locale: it });
  return formattedDate;
};

export const dateWithHoursISOString = (date: string) => {
  const dateWithHours = new Date(date);
  dateWithHours.setHours(18);
  return dateWithHours.toISOString();
};
