import React from "react";

export default interface IIcon {
  width?: number;
  height?: number;
  color?: string;
  strokeWidth?: number;
  "data-testid"?: string;
}

export interface ExtendedIIcon extends IIcon {
  strokeWidth?: number;
}

export const Error: React.FC<IIcon> = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 500 500"
      enableBackground="new 0 0 500 500"
    >
      <g id="BACKGROUND">
        <g>
          <rect fill="#D75A5A" width="500" height="500" />
          <g>
            <line
              fill="none"
              stroke="#FFAFAF"
              strokeMiterlimit="10"
              x1="100.938"
              y1="176.567"
              x2="197.378"
              y2="176.567"
            />
            <line
              fill="none"
              stroke="#FFAFAF"
              strokeMiterlimit="10"
              x1="348.408"
              y1="162.885"
              x2="449.074"
              y2="162.885"
            />
            <line
              fill="none"
              stroke="#FFAFAF"
              strokeMiterlimit="10"
              x1="280.477"
              y1="168.5"
              x2="301.481"
              y2="168.5"
            />
            <g>
              <path
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                d="M145.086,119v4.546c0,1.558-1.263,2.821-2.821,2.821l0,0
					c-1.558,0-2.821-1.263-2.821-2.821V119c-2.429,1.092-4.114,3.545-4.072,6.391c0.054,3.608,3.003,6.638,6.609,6.782
					c3.936,0.158,7.177-2.987,7.177-6.888C149.158,122.485,147.485,120.079,145.086,119z"
              />
              <path
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                d="M141.982,132.173c-0.873-0.035-1.706-0.242-2.47-0.582v29.814
					c0,1.521,1.233,2.753,2.753,2.753l0,0c1.521,0,2.753-1.233,2.753-2.753v-29.807
					C144.093,132.002,143.066,132.217,141.982,132.173z"
              />
            </g>
            <g>
              <path
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                d="M193.69,151.671h-17.759c-1.521,0-2.753,1.233-2.753,2.753l0,0
					c0,1.521,1.233,2.753,2.753,2.753h17.759V151.671z"
              />
              <line
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                x1="193.69"
                y1="154.425"
                x2="210.611"
                y2="154.425"
              />
              <polygon
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                points="210.611,156.097 210.611,152.753 218.315,153.293 
					218.315,155.556 				"
              />
            </g>
            <g>
              <g>
                <path
                  fill="none"
                  stroke="#FFAFAF"
                  strokeMiterlimit="10"
                  d="M244.657,132.998l0.063,23.212
						c0.004,1.523,0.611,2.983,1.688,4.06l0.153,0.153c1.013,1.013,2.656,1.013,3.67,0h0c1.013-1.013,2.656-1.013,3.67,0l0,0
						c1.013,1.013,2.656,1.013,3.67,0l0,0c1.013-1.013,2.657-1.013,3.67,0h0c1.013,1.013,2.656,1.013,3.67,0l0,0
						c1.013-1.013,2.656-1.013,3.67,0l0,0c1.013,1.013,2.656,1.013,3.67,0h0c1.081-1.081,1.688-2.547,1.688-4.076v-23.389
						c0-8.086-6.555-14.641-14.641-14.641l0,0C251.196,118.317,244.635,124.896,244.657,132.998z"
                />
              </g>
              <g>
                <line
                  fill="none"
                  stroke="#FFAFAF"
                  strokeMiterlimit="10"
                  x1="255.356"
                  y1="136.253"
                  x2="251.29"
                  y2="132.188"
                />
                <line
                  fill="none"
                  stroke="#FFAFAF"
                  strokeMiterlimit="10"
                  x1="251.29"
                  y1="136.253"
                  x2="255.356"
                  y2="132.188"
                />
              </g>
              <g>
                <line
                  fill="none"
                  stroke="#FFAFAF"
                  strokeMiterlimit="10"
                  x1="267.356"
                  y1="136.253"
                  x2="263.291"
                  y2="132.188"
                />
                <line
                  fill="none"
                  stroke="#FFAFAF"
                  strokeMiterlimit="10"
                  x1="263.291"
                  y1="136.253"
                  x2="267.356"
                  y2="132.188"
                />
              </g>
            </g>
            <g>
              <line
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                x1="331.107"
                y1="120.779"
                x2="324.893"
                y2="114.564"
              />
              <line
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                x1="324.893"
                y1="120.779"
                x2="331.107"
                y2="114.564"
              />
            </g>
            <g>
              <line
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                x1="349.448"
                y1="120.779"
                x2="343.234"
                y2="114.564"
              />
              <line
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                x1="343.234"
                y1="120.779"
                x2="349.448"
                y2="114.564"
              />
            </g>
            <g>
              <rect
                x="310.064"
                y="103.567"
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                width="54.212"
                height="39.86"
              />
              <rect
                x="313.528"
                y="107.293"
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                width="47.284"
                height="32.407"
              />
              <rect
                x="329.333"
                y="143.426"
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                width="15.674"
                height="7.813"
              />
              <path
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                d="M349.945,154.934h-25.549v0c0-2.04,1.654-3.694,3.694-3.694
					h18.16C348.291,151.24,349.945,152.893,349.945,154.934L349.945,154.934z"
              />
            </g>
            <line
              fill="none"
              stroke="#FFAFAF"
              strokeMiterlimit="10"
              x1="331.107"
              y1="126.629"
              x2="349.448"
              y2="126.629"
            />
            <path
              fill="none"
              stroke="#FFAFAF"
              strokeMiterlimit="10"
              d="M346.341,126.629h-6.063v5.884c0,1.674,1.357,3.032,3.032,3.032
				l0,0c1.674,0,3.032-1.357,3.032-3.032V126.629z"
            />
            <g>
              <g>
                <path
                  fill="none"
                  stroke="#FFAFAF"
                  strokeMiterlimit="10"
                  d="M459.465,150h-62.52c-2.831,0-5.125-2.295-5.125-5.125l0,0
						c0-2.831,2.294-5.125,5.125-5.125h62.52c2.83,0,5.125,2.294,5.125,5.125l0,0C464.59,147.706,462.295,150,459.465,150z"
                />
                <circle
                  fill="none"
                  stroke="#FFAFAF"
                  strokeMiterlimit="10"
                  cx="428.205"
                  cy="144.875"
                  r="2.875"
                />
                <circle
                  fill="none"
                  stroke="#FFAFAF"
                  strokeMiterlimit="10"
                  cx="440.205"
                  cy="144.875"
                  r="2.875"
                />
                <circle
                  fill="none"
                  stroke="#FFAFAF"
                  strokeMiterlimit="10"
                  cx="452.205"
                  cy="144.875"
                  r="2.875"
                />
                <circle
                  fill="none"
                  stroke="#FFAFAF"
                  strokeMiterlimit="10"
                  cx="416.205"
                  cy="144.875"
                  r="2.875"
                />
                <circle
                  fill="none"
                  stroke="#FFAFAF"
                  strokeMiterlimit="10"
                  cx="404.205"
                  cy="144.875"
                  r="2.875"
                />
              </g>
              <polygon
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                points="451.66,139.75 404.75,139.75 401.75,122.667 
					454.66,122.667 				"
              />
              <line
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                x1="402.766"
                y1="128.453"
                x2="431.833"
                y2="128.453"
              />
              <line
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                x1="441"
                y1="128.453"
                x2="447.333"
                y2="128.453"
              />
              <line
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                x1="414.748"
                y1="134.167"
                x2="443.08"
                y2="134.167"
              />
              <polygon
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                points="424.457,114.834 403.59,114.834 405.59,105.334 
					424.457,105.334 				"
              />
              <line
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                x1="413.33"
                y1="105.334"
                x2="407.786"
                y2="111.834"
              />
              <line
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                x1="414.024"
                y1="110.084"
                x2="409.75"
                y2="114.834"
              />
              <polygon
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                points="431.833,112.459 431.833,101 401.419,101 
					396.419,122.667 459.333,122.667 456.977,112.459 				"
              />
              <rect
                x="438.667"
                y="95.5"
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                width="4.413"
                height="16.958"
              />
              <line
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                x1="447.333"
                y1="101"
                x2="447.333"
                y2="112.459"
              />

              <rect
                x="384.129"
                y="96.47"
                fill="none"
                stroke="#FFAFAF"
                strokeLinejoin="bevel"
                strokeMiterlimit="10"
                width="8.553"
                height="8.553"
              />

              <rect
                x="376.957"
                y="89.298"
                fill="none"
                stroke="#FFAFAF"
                strokeLinejoin="bevel"
                strokeMiterlimit="10"
                width="7.341"
                height="7.341"
              />

              <rect
                x="370.651"
                y="82.992"
                fill="none"
                stroke="#FFAFAF"
                strokeLinejoin="bevel"
                strokeMiterlimit="10"
                width="6.349"
                height="6.349"
              />

              <rect
                x="365.267"
                y="77.608"
                fill="none"
                stroke="#FFAFAF"
                strokeLinejoin="bevel"
                strokeMiterlimit="10"
                width="5.399"
                height="5.399"
              />

              <rect
                x="360.664"
                y="73.005"
                fill="none"
                stroke="#FFAFAF"
                strokeLinejoin="bevel"
                strokeMiterlimit="10"
                width="4.707"
                height="4.707"
              />

              <rect
                x="356.793"
                y="69.134"
                fill="none"
                stroke="#FFAFAF"
                strokeLinejoin="bevel"
                strokeMiterlimit="10"
                width="3.962"
                height="3.962"
              />
              <polygon
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                points="400.874,103.361 358.241,66.803 354.463,70.581 
					396.993,120.181"
              />
              <polygon
                fill="none"
                stroke="#FFAFAF"
                strokeLinejoin="bevel"
                strokeMiterlimit="10"
                points="400.457,105.167 
					392.826,105.167 392.826,114.711 398.255,114.711"
              />

              <line
                fill="none"
                stroke="#FFAFAF"
                strokeLinejoin="bevel"
                strokeMiterlimit="10"
                x1="354.463"
                y1="70.581"
                x2="354.463"
                y2="82.167"
              />
              <path
                fill="none"
                stroke="#FFAFAF"
                strokeLinejoin="bevel"
                strokeMiterlimit="10"
                d="M356.168,83.873
					c0,0.942-0.764,1.706-1.706,1.706c-0.942,0-1.706-0.764-1.706-1.706s0.764-1.706,1.706-1.706
					C355.405,82.167,356.168,82.93,356.168,83.873z"
              />
              <path
                fill="none"
                stroke="#FFAFAF"
                strokeLinejoin="bevel"
                strokeMiterlimit="10"
                d="M351.546,91.554
					c0,1.611,1.306,2.917,2.917,2.917s2.917-1.306,2.917-2.917c0-1.611-1.306-2.917-2.917-2.917v-3.5"
              />
            </g>
            <g>
              <circle
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                cx="86.338"
                cy="139.918"
                r="6.5"
              />
              <path
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                d="M68.564,103.052l-1.591,1.591l12.198,12.198l0,0
					c2.26-2.26,1.828-6.035-0.884-7.726L68.564,103.052z"
              />
              <path
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                d="M49.45,122.166l1.591-1.591l12.198,12.198l0,0
					c-2.26,2.26-6.035,1.828-7.726-0.884L49.45,122.166z"
              />
              <path
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                d="M57.84,113.777l2.357-2.357l10.502,7.391
					c1.993,1.402,2.24,4.262,0.517,5.985l0,0c-1.723,1.723-4.583,1.476-5.985-0.517L57.84,113.777z"
              />
              <path
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                d="M92.309,153.101l6.801-0.411l0.411-6.801
					c0.315-5.216-1.619-10.316-5.314-14.011l-18.572-18.572l-5.27,5.27l0.334,0.235c1.993,1.402,2.24,4.262,0.517,5.985
					c-1.723,1.723-4.583,1.476-5.985-0.517l-0.235-0.334l-5.27,5.27l18.572,18.572C81.992,151.482,87.093,153.417,92.309,153.101z"
              />
              <line
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                x1="49.092"
                y1="92.046"
                x2="51.037"
                y2="93.99"
              />
              <line
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                x1="55.279"
                y1="98.233"
                x2="61.113"
                y2="104.066"
              />
              <line
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                x1="31.523"
                y1="84.866"
                x2="53.002"
                y2="106.344"
              />
              <line
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                x1="27.104"
                y1="90.346"
                x2="29.932"
                y2="93.174"
              />
              <line
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                x1="33.998"
                y1="97.24"
                x2="41.599"
                y2="104.842"
              />
            </g>
            <g>
              <path
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                d="M214.963,96.47h-49.65c-2.502,0-4.53-2.028-4.53-4.53l0,0
					c0-2.502,2.028-4.53,4.53-4.53h49.65c2.502,0,4.53,2.028,4.53,4.53l0,0C219.493,94.442,217.465,96.47,214.963,96.47z"
              />
              <path
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                d="M208.004,87.453c0-0.014,0.002-0.028,0.002-0.042
					c0-9.868-7.999-17.868-17.867-17.868c-9.868,0-17.868,8-17.868,17.868c0,0.014,0.002,0.028,0.002,0.042H208.004z"
              />
              <circle
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                cx="190.139"
                cy="91.941"
                r="2.875"
              />
              <circle
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                cx="179.138"
                cy="91.941"
                r="2.875"
              />
              <circle
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                cx="168.138"
                cy="91.941"
                r="2.875"
              />
              <circle
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                cx="212.139"
                cy="91.941"
                r="2.875"
              />
              <circle
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                cx="201.139"
                cy="91.941"
                r="2.875"
              />
              <polyline
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                points="175.138,133.418 179.138,96.47 201.139,96.47 
					206.139,133.418 				"
              />
              <line
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                x1="184.75"
                y1="105.167"
                x2="183.434"
                y2="139.75"
              />
              <line
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                x1="193.69"
                y1="113.306"
                x2="194.75"
                y2="129.25"
              />
            </g>
            <path
              fill="none"
              stroke="#FFAFAF"
              strokeMiterlimit="10"
              d="M301.481,103.052l-8.426-8.832
				c3.022-3.394,4.808-7.554,4.808-12.054c0-11.417-11.459-20.672-25.595-20.672c-14.135,0-25.595,9.255-25.595,20.672
				c0,11.417,11.459,20.672,25.595,20.672c4.947,0,9.563-1.136,13.477-3.099L301.481,103.052z"
            />
            <path
              fill="none"
              stroke="#FFAFAF"
              strokeMiterlimit="10"
              d="M158.015,69.317h-22.881c0-6.802-5.515-12.317-12.317-12.317
				c-1.817,0-3.537,0.404-5.09,1.11c-2.863-5.134-8.34-8.61-14.636-8.61c-9.253,0-16.754,7.501-16.754,16.754
				c0,0.543,0.031,1.078,0.081,1.608c-3.427,1.68-5.793,5.192-5.793,9.266c0,1.744,0.436,3.384,1.2,4.825H51.037"
            />

            <ellipse
              transform="matrix(0.7071 -0.7071 0.7071 0.7071 -22.9405 110.6318)"
              fill="none"
              stroke="#FFAFAF"
              strokeMiterlimit="10"
              cx="122.074"
              cy="83.008"
              rx="13.06"
              ry="13.06"
            />
            <line
              fill="none"
              stroke="#FFAFAF"
              strokeMiterlimit="10"
              x1="124.5"
              y1="84.866"
              x2="149.929"
              y2="84.866"
            />
            <line
              fill="none"
              stroke="#FFAFAF"
              strokeMiterlimit="10"
              x1="104.526"
              y1="97.24"
              x2="77.5"
              y2="97.24"
            />
            <g>
              <line
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                x1="326.78"
                y1="72.412"
                x2="321.403"
                y2="72.412"
              />
              <line
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                x1="324.092"
                y1="75.101"
                x2="324.092"
                y2="69.724"
              />
            </g>
            <g>
              <line
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                x1="334.457"
                y1="85.752"
                x2="330.654"
                y2="81.95"
              />
              <line
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                x1="330.654"
                y1="85.752"
                x2="334.457"
                y2="81.95"
              />
            </g>
            <path
              fill="none"
              stroke="#FFAFAF"
              strokeMiterlimit="10"
              d="M304.688,64.436c-0.656,0-1.188-0.532-1.188-1.188
				s0.532-1.188,1.188-1.188c0.656,0,1.188,0.532,1.188,1.188S305.343,64.436,304.688,64.436z"
            />
            <circle
              fill="none"
              stroke="#FFAFAF"
              strokeMiterlimit="10"
              cx="317.166"
              cy="78.305"
              r="1.642"
            />
            <g>
              <line
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                x1="122.835"
                y1="122.002"
                x2="117.458"
                y2="122.002"
              />
              <line
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                x1="120.146"
                y1="124.691"
                x2="120.146"
                y2="119.313"
              />
            </g>
            <g>
              <line
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                x1="130.511"
                y1="135.342"
                x2="126.709"
                y2="131.54"
              />
              <line
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                x1="126.709"
                y1="135.342"
                x2="130.511"
                y2="131.54"
              />
            </g>
            <circle
              fill="none"
              stroke="#FFAFAF"
              strokeMiterlimit="10"
              cx="100.742"
              cy="112.839"
              r="1.188"
            />
            <circle
              fill="none"
              stroke="#FFAFAF"
              strokeMiterlimit="10"
              cx="113.22"
              cy="127.895"
              r="1.642"
            />
            <g>
              <line
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                x1="225.618"
                y1="60.56"
                x2="225.618"
                y2="65.938"
              />
              <line
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                x1="228.307"
                y1="63.249"
                x2="222.929"
                y2="63.249"
              />
            </g>
            <g>
              <line
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                x1="249.653"
                y1="52.884"
                x2="245.851"
                y2="56.686"
              />
              <line
                fill="none"
                stroke="#FFAFAF"
                strokeMiterlimit="10"
                x1="249.653"
                y1="56.686"
                x2="245.851"
                y2="52.884"
              />
            </g>
            <circle
              fill="none"
              stroke="#FFAFAF"
              strokeMiterlimit="10"
              cx="220.681"
              cy="71.817"
              r="1.188"
            />
            <circle
              fill="none"
              stroke="#FFAFAF"
              strokeMiterlimit="10"
              cx="234.795"
              cy="82.274"
              r="1.642"
            />
          </g>
        </g>
      </g>
      <g id="OBJECTS">
        <g>
          <path
            fill="#C73E3E"
            d="M483.443,310.668H22.844c-1.32,0-2.39-1.07-2.39-2.39v-0.44c0-1.32,1.07-2.39,2.39-2.39h460.599
			c1.32,0,2.39,1.07,2.39,2.39v0.44C485.833,309.597,484.763,310.668,483.443,310.668z"
          />

          <text
            transform="matrix(1 0 0 1 38.4937 305.3205)"
            fill="#FFFFFF"
            fontFamily="SourceSansPro-Black"
            fontSize="168.8235px"
          >
            OOPS
          </text>
        </g>
        <rect
          x="69.294"
          y="382.661"
          fill="none"
          width="361.036"
          height="55.544"
        />
        <g>
          <path
            fill="#C73E3E"
            d="M173.134,317.534c0,1.41-8.507,2.553-19,2.553c-10.493,0-19-1.143-19-2.553
			c0-1.41,8.507-2.553,19-2.553C164.628,314.981,173.134,316.124,173.134,317.534z"
          />
          <path
            fill="#C8462D"
            d="M154.6,229.418c0,3.553-2.88,6.434-6.433,6.434s-6.433-2.88-6.433-6.434s2.88-6.433,6.433-6.433
			S154.6,225.865,154.6,229.418z"
          />
          <path
            fill="#C8462D"
            d="M164.449,228.35c0,3.553-2.88,6.433-6.434,6.433s-6.433-2.88-6.433-6.433
			c0-3.553,2.88-6.433,6.433-6.433S164.449,224.797,164.449,228.35z"
          />
          <circle fill="#C8462D" cx="151.582" cy="222.157" r="5.255" />
          <path
            fill="#FA968C"
            d="M163.508,287.516c-0.462,3.299-4.724,28.996-5.784,28.59c-0.919-0.352-1.654-26.268-1.192-29.567
			c0.462-3.299,2.398-5.754,4.324-5.484C162.783,281.324,163.97,284.217,163.508,287.516z"
          />
          <path
            fill="#FA968C"
            d="M164.689,263.616c0,5.325,0.13,23.845-3.716,23.845c-3.846,0-10.213-22.38-10.213-27.705
			c0-5.325,5.897-13.5,9.744-13.5C164.35,246.257,164.689,258.292,164.689,263.616z"
          />
          <path
            fill="#FA968C"
            d="M156.975,310.199c0,0-0.103,3.087-0.583,4.779c-0.48,1.692-0.118,2.404,1.629,2.517
			c1.747,0.113,6.141,0.779,6.654,0.272c0.514-0.506-1.536-1.319-2.951-1.929c-1.674-0.722-2.669-2.889-2.609-4.321L156.975,310.199
			z"
          />
          <path
            fill="#FAB9AF"
            d="M165.036,230.84c1.237,3.093,4.948,11.768,3.142,12.49c-1.806,0.722-8.445-6.782-9.682-9.874
			c-1.237-3.093-0.776-6.185,1.03-6.908C161.332,225.826,163.799,227.747,165.036,230.84z"
          />
          <path
            fill="#FA968C"
            d="M165.321,236.802l-2.081-4.704l0.163-1.907c0.209-2.03-1.384-3.795-3.424-3.795h-12.144
			c-1.845,0-3.143,1.815-2.546,3.561l5.738,16.777l1.785,5.748l8.164,1.296l1.332-12.943h0.121
			C164.519,240.836,165.992,238.782,165.321,236.802z"
          />
          <path
            fill="#FFFFFF"
            d="M165.321,236.802l-2.081-4.704l0.163-1.907c0.209-2.03-1.384-3.795-3.424-3.795h-1.968
			c0.173,1.717-0.841,3.578-3.064,3.578c-0.011,0-0.019-0.003-0.03-0.003c-0.012,0-0.021,0.003-0.032,0.003
			c-2.226,0-3.239-1.861-3.064-3.578h-3.985c-1.845,0-3.143,1.815-2.546,3.561l5.738,16.777l1.785,5.748l8.164,1.296l1.332-12.943
			h0.121C164.519,240.836,165.992,238.782,165.321,236.802z"
          />
          <path
            fill="#FAB9AF"
            d="M154.942,287.319c-0.462,3.299-4.724,28.996-5.784,28.59c-0.919-0.352-1.654-26.268-1.192-29.567
			c0.462-3.299,2.398-5.754,4.324-5.484C154.217,281.127,155.404,284.02,154.942,287.319z"
          />
          <path
            fill="#FAB9AF"
            d="M156.123,263.419c0,5.324,0.13,23.845-3.716,23.845c-3.846,0-10.213-22.38-10.213-27.705
			c0-5.325,5.897-13.5,9.744-13.5C155.783,246.06,156.123,258.095,156.123,263.419z"
          />
          <path
            fill="#373C41"
            d="M163.329,283.91c1.427-5.648,1.36-16.434,1.36-20.294c0-5.324,0.258-14.205-2.939-17.359
			c-1.689,0-8.391-0.197-9.813-0.197c-3.846,0-9.744,8.176-9.744,13.5c0,3.904,3.422,16.966,6.764,23.664
			C148.486,284.101,163.562,284.773,163.329,283.91z"
          />
          <path
            fill="#FAB9AF"
            d="M148.409,310.001c0,0-0.104,4.219-0.585,5.911c-0.48,1.692-4.131,7.104-2.053,7.104
			c0,0,4.495-3.784,5.454-5.062c1.206-1.608-0.875-3.126-0.676-6.636L148.409,310.001z"
          />
          <path
            fill="#AA321E"
            d="M161.725,315.838c-0.092-0.04-0.174-0.099-0.262-0.147c-0.575,0.324-1.258,0.531-2.078,0.531
			c-1.316,0-2.308-0.497-2.993-1.241c-0.479,1.69-0.116,2.401,1.63,2.514c1.747,0.113,6.141,0.779,6.654,0.272
			C165.189,317.261,163.14,316.448,161.725,315.838z"
          />
          <path
            fill="#AA321E"
            d="M150.793,313.82c-0.039,0.359-0.129,0.707-0.247,1.044c-0.194,1.932-1.518,3.722-3.992,3.766
			c-1.052,2.075-2.142,4.387-0.783,4.387c0,0,4.495-3.784,5.454-5.062C152.097,316.793,151.253,315.674,150.793,313.82z"
          />
          <path
            fill="#FA968C"
            d="M149.682,233.294c-0.731,3.25-4.285,12.466-6.182,12.039c-1.898-0.427-1.421-10.335-0.69-13.584
			c0.731-3.25,2.862-5.538,4.759-5.111C149.467,227.064,150.413,230.045,149.682,233.294z"
          />
          <path
            fill="#C8462D"
            d="M161.128,210.732c-0.929-0.223-1.8,0.016-2.439,0.533c-1.01,0.817-2.214,1.361-3.513,1.361h-0.687
			c-1.006,0-1.92,0.585-2.341,1.498l-1.434,3.107h8.913v-1.103c0.257,0.078,0.524,0.132,0.807,0.132c1.77,0,3.158-1.64,2.726-3.484
			C162.927,211.78,162.123,210.97,161.128,210.732z"
          />
          <polygon
            fill="#FA968C"
            points="157.196,227.412 151.779,226.839 154.6,221.303 157.196,221.834 		"
          />
          <circle fill="#C8462D" cx="160.324" cy="216.352" r="2.805" />
          <path
            fill="#C8462D"
            d="M161.672,220.488c0,1.549-1.256,2.805-2.805,2.805c-1.549,0-2.805-1.256-2.805-2.805
			c0-1.549,1.256-2.805,2.805-2.805C160.416,217.682,161.672,218.938,161.672,220.488z"
          />
          <g>
            <path
              fill="#C8462D"
              d="M160.485,216.74c0,4.624-3.858,5.66-6.444,5.66c-2.586,0-2.399-3.824-2.399-5.81
				c0-1.987,2.077-3.044,4.663-3.044C158.891,213.546,160.485,214.753,160.485,216.74z"
            />
            <path
              fill="#FAB9AF"
              d="M160.349,218.189c0,2.742-0.909,7.115-2.991,7.115c-2.082,0-4.329-3.132-4.329-5.874
				c0-2.742,1.212-4.328,3.294-4.328C158.406,215.101,160.349,215.447,160.349,218.189z"
            />
            <path
              fill="#FF9669"
              d="M153.101,218.355h5.273c0.556,0,1.525-0.378,1.95-0.673c0-1.964-0.969-2.944-4.001-2.581
				C154.544,215.315,153.386,216.279,153.101,218.355z"
            />
            <path
              fill="#C8462D"
              d="M158.375,217.844h-6.069v-1.21c0-1.444,1.171-2.615,2.615-2.615h6.085v1.194
				C161.006,216.666,159.828,217.844,158.375,217.844z"
            />
            <path
              fill="#C8462D"
              d="M154.041,219.91L154.041,219.91v-3.018h1.318v1.701C155.358,219.32,154.768,219.91,154.041,219.91
				z"
            />
            <path
              fill="#FAB9AF"
              d="M154.544,219.326c0,0.907-0.286,1.643-1.025,1.643s-1.05-1.05-1.05-1.957
				c0-0.907,0.311-1.329,1.05-1.329S154.544,218.418,154.544,219.326z"
            />
          </g>
          <path
            fill="#FA968C"
            d="M168.357,238.689c1.65-1.419,12.512-9.792,13.296-8.881c0.783,0.911-8.81,10.761-10.46,12.18
			c-1.65,1.418-3.623,1.83-4.406,0.919C166.004,241.996,166.707,240.108,168.357,238.689z"
          />
          <path
            fill="#FA968C"
            d="M182.799,229.608c0,0-2.117-0.091-2.359,0.546c-0.242,0.637-0.288,1.109,0.626,1.496
			c0.914,0.387,2.352,0.482,3.227,0.366c0.874-0.116,2.257-0.928,2.347-1.112c0.33-0.672-1.231-0.297-1.996-0.152
			c-0.765,0.145-1.643,0.233-1.609-0.155s1.52-0.555,1.706-0.987C184.928,229.18,182.799,229.608,182.799,229.608z"
          />
          <path
            fill="#FAB9AF"
            d="M132.431,227.625c0,0,1.825,1.077,1.681,1.743c-0.144,0.666-0.363,1.087-1.34,0.913
			c-0.977-0.173-2.236-0.877-2.906-1.45c-0.67-0.573-1.387-2.007-1.363-2.211c0.089-0.744,1.194,0.422,1.757,0.96
			c0.562,0.538,1.251,1.09,1.434,0.746c0.183-0.344-0.972-1.294-0.893-1.757C130.879,226.107,132.431,227.625,132.431,227.625z"
          />
          <path
            fill="#FAB9AF"
            d="M140.109,243.023c-1.186-1.824-8.041-13.703-7.034-14.358c1.007-0.655,9.494,10.162,10.68,11.986
			c1.186,1.824,1.332,3.834,0.325,4.489C143.073,245.795,141.295,244.848,140.109,243.023z"
          />
        </g>
        <g>
          <circle fill="#FFA50A" cx="357.546" cy="182.945" r="5.507" />
          <path
            fill="#FAC855"
            d="M349.05,196.334c-0.926-2.46,0.794-7.803,0.794-7.803l-4.133-6.362
			c-0.458-0.895-0.354-1.975,0.267-2.766l0.251-0.32c0.825-1.051,1.365-2.35,1.278-3.682c-0.057-0.866,0.288-1.761,1.079-2.388
			c0.808-0.64,1.923-0.773,2.859-0.34c1.721,0.797,2.156,2.904,1.062,4.298c-0.174,0.222-0.382,0.399-0.602,0.553l0.868,0.681
			l-3.853,4.907c1.524-0.94,3.492-1.068,5.114-0.238c2.223,1.137,3.183,3.463,2.794,5.632c1.281,0.316,2.497,1.025,3.45,2.184
			c1.719,2.091,1.91,5.138,0.48,7.436c-2.062,3.311-6.467,4.022-9.449,1.681C350.147,198.893,349.533,197.616,349.05,196.334z"
          />
          <path
            fill="#39AE96"
            d="M365.497,175.072c-1.563,0.302-9.217,1.384-13.363,1.495c0.277-0.669,0.423-1.394,0.432-2.117
			c4.009-1.532,10.638-3.39,12.101-3.673c2.149-0.415,4.077,0.21,4.306,1.396C369.202,173.359,367.646,174.657,365.497,175.072z"
          />
          <path
            fill="#39AE96"
            d="M360.1,185.421c1.001-2.931,6.589-14.752,8.3-14.167c1.712,0.585-1.1,13.353-2.102,16.285
			c-1.001,2.931-3.201,4.834-4.912,4.249C359.674,191.203,359.099,188.352,360.1,185.421z"
          />
          <path
            fill="#FAB9AF"
            d="M376.481,197.552c2.307-3.076,10.225-13.854,13.32-11.533c3.095,2.321-0.52,17.829-3.784,22.167
			c-4.396,5.844-10.594,4.241-13.688,1.92C369.234,207.785,374.175,200.628,376.481,197.552z"
          />
          <path
            fill="#55C7A6"
            d="M350.757,190.904l10.878-5.553c1.828-0.933,5.07,2.524,5.07,2.524l4.473,4.355
			c1.507,1.467,1.127,3.98-0.746,4.936l-0.108,0.055l4.725,12.203l-7.906,2.572l-4.227-4.333l-12.811-12.405
			C348.772,193.967,349.104,191.747,350.757,190.904z"
          />
          <path
            fill="#39AE96"
            d="M356.524,191.838c2.922,1.639,14.401,9.894,13.444,11.6c-0.957,1.706-13.987-3.783-16.909-5.422
			c-2.922-1.639-4.515-4.35-3.558-6.056C350.458,190.254,353.602,190.199,356.524,191.838z"
          />
          <path
            fill="#FA968C"
            d="M396.649,193.7c3.348-0.111,27.674-1.875,27.363-0.559c-0.241,1.022-3.613,1.145-6.03,1.966
			c-6.493,2.206-18.658,5.591-21.098,5.673c-3.348,0.111-6.115-1.383-6.18-3.339C390.639,195.486,393.301,193.811,396.649,193.7z"
          />
          <path
            fill="#FAB9AF"
            d="M368.344,199.025c1.07-1.909,8.449-13.563,9.503-12.972c1.054,0.591-4.617,13.203-5.687,15.112
			s-2.792,2.977-3.846,2.386C367.26,202.96,367.274,200.934,368.344,199.025z"
          />
          <path
            fill="#39AE96"
            d="M368.344,199.025c0.707-1.26,4.162-6.765,6.732-10.193c0.441,0.7,1.669,1.771,2.366,2.195
			c-1.497,4.011-4.612,8.942-5.283,10.138c-1.07,1.909-2.792,2.977-3.846,2.386C367.26,202.96,367.273,200.934,368.344,199.025z"
          />
          <path
            fill="#FA968C"
            d="M376.967,201.864c3.861-2.327,17.221-10.535,19.219-7.222c1.997,3.313-9.328,17.627-14.784,20.907
			c-7.348,4.417-12.85,0.822-14.848-2.491C364.557,209.744,373.107,204.191,376.967,201.864z"
          />
          <path
            fill="#0073AA"
            d="M383.642,188.951c-2.927,2.891-5.893,6.909-7.161,8.6c-1.324,1.766-3.51,4.875-4.552,7.643
			c-3.462,2.557-6.684,5.691-5.375,7.862c1.997,3.313,7.499,6.908,14.848,2.491c3.364-2.022,8.957-8.239,12.285-13.486
			C388.798,199.273,385.215,194.524,383.642,188.951z"
          />
          <path
            fill="#FAB9AF"
            d="M393.304,188.594c1.804,2.498,15.409,22.417,14.432,22.881
			c-0.847,0.403-17.967-16.208-19.771-18.706c-1.804-2.498-2.071-5.458-0.597-6.611C388.842,185.006,391.5,186.096,393.304,188.594z
			"
          />
          <path
            fill="#FAB9AF"
            d="M404.72,206.141c0,0,2.655,2.809,4.025,3.646c1.37,0.836,7.22,2.112,5.776,3.487
			c0,0-5.441,0.394-6.89,0.156c-1.823-0.299-1.305-2.712-3.592-4.974L404.72,206.141z"
          />
          <path
            fill="#C8462D"
            d="M411.582,210.906c1.913,0.697,3.837,1.514,2.939,2.369c0,0-5.441,0.394-6.89,0.156
			c-1.208-0.198-1.391-1.327-2.041-2.723C407.209,211.903,409.395,212.256,411.582,210.906z"
          />
          <path
            fill="#FA968C"
            d="M418.988,192.726c0,0,3.734-0.998,5.122-1.805c1.387-0.807,5.294-5.345,5.816-3.42
			c0,0-2.243,4.973-3.141,6.134c-1.13,1.461-3.006-0.143-6.084,0.792L418.988,192.726z"
          />
          <path
            fill="#C8462D"
            d="M426.444,188.958c1.523-1.351,3.157-2.654,3.482-1.457c0,0-2.243,4.973-3.141,6.134
			c-0.749,0.968-1.829,0.592-3.366,0.499C425.239,193.279,426.591,191.524,426.444,188.958z"
          />
          <g>
            <path
              fill="#C8E6EB"
              d="M380.313,179.733l0.197-0.039c0.051-0.01,0.085-0.06,0.074-0.112l-0.221-1.101
				c-0.01-0.051-0.06-0.085-0.112-0.074l-0.197,0.04c-0.051,0.01-0.085,0.06-0.074,0.112l0.221,1.101
				C380.212,179.71,380.262,179.743,380.313,179.733z"
            />
            <path
              fill="#C8E6EB"
              d="M377.895,186.913c-0.25,0.197-0.619,0.057-0.676-0.256l-1.214-6.637
				c-0.028-0.153,0.03-0.308,0.152-0.405l3.146-2.516c0.247-0.197,0.615-0.063,0.677,0.247l1.324,6.592
				c0.031,0.156-0.028,0.316-0.153,0.415L377.895,186.913z"
            />
            <path
              fill="#FFFFFF"
              d="M376.685,179.931c-0.028-0.153,0.03-0.308,0.151-0.405l2.857-2.285
				c0.049-0.039,0.114-0.05,0.173-0.028c0.059,0.021,0.102,0.072,0.114,0.133l0,0l1.324,6.592c0.031,0.156-0.028,0.316-0.153,0.415
				l-3.112,2.447c-0.067-0.059-0.123-0.132-0.141-0.232L376.685,179.931z"
            />
            <path
              fill="#FAB9AF"
              d="M376.614,184.572c0.102,0.548-0.016,1.117-0.313,1.589c-0.339,0.539-0.683,1.242-0.493,1.654
				c0.339,0.736,0.701,1.167,1.79,0.714c1.089-0.453,2.367-1.593,2.997-2.43c0.63-0.837,1.657-3.342,1.576-3.572
				c-0.295-0.84-1.841,1.453-2.355,2.222c-0.514,0.769-1.441,1.764-1.741,1.412c-0.3-0.352-0.556-2.936-1.114-2.916
				C376.476,183.262,376.502,183.969,376.614,184.572z"
            />
            <path
              fill="#C8E6EB"
              d="M378.225,180.899c0.118,0.523,0.503,0.882,0.858,0.802c0.355-0.081,0.548-0.57,0.429-1.093
				c-0.118-0.523-0.503-0.882-0.858-0.802C378.299,179.886,378.107,180.375,378.225,180.899z"
            />
          </g>
          <path
            fill="#FA968C"
            d="M358.646,186.877c0,0,3.164,2.66,2.624,3.644c-0.684,1.249-5.687-0.316-5.687-0.316l-2.268-5.785
			l2.12-1.589L358.646,186.877z"
          />
          <path
            fill="#FAC855"
            d="M354.244,174.907c1.219,0.957,1.431,2.72,0.474,3.939c-0.957,1.219-2.72,1.431-3.939,0.474
			c-1.219-0.957-1.431-2.72-0.474-3.939C351.262,174.162,353.025,173.95,354.244,174.907z"
          />
          <g>
            <path
              fill="#FAC855"
              d="M352.916,177.227c3.637,2.855,2.07,6.53,0.473,8.564c-1.597,2.034-4.489-0.474-6.052-1.701
				c-1.563-1.227-1.111-3.513,0.486-5.547C349.42,176.509,351.353,176,352.916,177.227z"
            />
            <path
              fill="#FAB9AF"
              d="M353.972,178.228c2.157,1.693,5.035,5.109,3.749,6.746c-1.286,1.638-5.137,1.47-7.294-0.223
				c-2.157-1.693-2.655-3.626-1.37-5.264C350.344,177.85,351.815,176.535,353.972,178.228z"
            />
            <path
              fill="#FF9669"
              d="M349.627,184.032l3.256-4.148c0.343-0.437,0.644-1.433,0.675-1.949
				c-1.545-1.213-2.914-1.056-4.501,1.553C348.127,181.02,348.17,182.526,349.627,184.032z"
            />
            <path
              fill="#FAC855"
              d="M352.481,179.568l-3.747,4.773l-0.952-0.747c-1.136-0.892-1.334-2.535-0.442-3.671l3.758-4.786
				l0.939,0.737C353.18,176.771,353.379,178.425,352.481,179.568z"
            />
            <path
              fill="#FAC855"
              d="M351.43,184.253L351.43,184.253l-2.374-1.864l0.814-1.036l1.338,1.05
				C351.78,182.852,351.879,183.681,351.43,184.253z"
            />
            <path
              fill="#FAB9AF"
              d="M351.281,183.497c0.714,0.56,1.116,1.239,0.66,1.82c-0.456,0.581-1.474,0.177-2.188-0.383
				c-0.714-0.56-0.853-1.065-0.397-1.646C349.812,182.707,350.567,182.936,351.281,183.497z"
            />
          </g>
          <path
            fill="#FAC855"
            d="M356.276,192.752c0,3.895-3.158,7.053-7.053,7.053c-3.896,0-7.053-3.158-7.053-7.053
			c0-3.895,3.158-7.053,7.053-7.053C353.118,185.699,356.276,188.857,356.276,192.752z"
          />
          <path
            fill="#FAC855"
            d="M349.223,185.79c0,1.902-1.542,3.445-3.445,3.445c-1.902,0-3.445-1.542-3.445-3.445
			s1.542-3.445,3.445-3.445C347.681,182.345,349.223,183.887,349.223,185.79z"
          />
        </g>
        <g>
          <path
            fill="#FAB9AF"
            d="M243.129,219.858c2.637-0.957,15.574-6.29,15.05-7.735c-0.524-1.444-14.311,1.547-16.948,2.505
			c-2.637,0.957-4.35,2.904-3.825,4.348C237.929,220.421,240.492,220.816,243.129,219.858z"
          />
          <path
            fill="#FAB9AF"
            d="M258.411,224.738c-3.372-1.86-19.801-8.358-21.027-6.135c-1.226,2.223,13.215,12.326,16.587,14.186
			c3.372,1.86,7.1,1.565,8.326-0.658C263.523,229.908,261.783,226.598,258.411,224.738z"
          />
          <path
            fill="#51946F"
            d="M246.999,228.126c2.938,2.14,5.719,3.971,6.972,4.662c3.372,1.86,7.099,1.565,8.325-0.658
			c1.226-2.223-0.514-5.533-3.886-7.392c-1.298-0.716-4.526-2.116-8.079-3.442C247.244,223.43,246.999,228.126,246.999,228.126z"
          />
          <ellipse
            fill="#C73E3E"
            cx="266.62"
            cy="322.795"
            rx="19.037"
            ry="1.293"
          />
          <path
            fill="#FAB9AF"
            d="M256.811,290.875c0.429,3.065,4.39,26.946,5.375,26.569c0.854-0.327,1.537-24.411,1.107-27.477
			c-0.429-3.066-2.229-5.347-4.019-5.097C257.484,285.122,256.382,287.81,256.811,290.875z"
          />
          <path
            fill="#695A6E"
            d="M257.462,265.121c-0.608,4.429-1.937,29.517,1.177,29.945c3.114,0.428,9.863-27.219,10.471-31.648
			s-1.423-8.366-4.537-8.794C261.46,254.197,258.07,260.693,257.462,265.121z"
          />
          <path
            fill="#FAB9AF"
            d="M262.843,312.552l-0.021,5.819l1.01,3.306c0.069,0.228-0.101,0.458-0.339,0.458h-2.304v-0.736
			l-1.118,0.736c0,0-5.96,0.329-6.164,0c-0.767-1.241,2.7-1.718,5.237-2.7c2.536-0.982,0.786-10.613,0.786-10.613L262.843,312.552z"
          />
          <path
            fill="#FAB9AF"
            d="M269.632,290.873c0.429,3.065,4.39,26.946,5.375,26.569c0.854-0.327,1.537-24.411,1.107-27.477
			c-0.429-3.065-2.229-5.347-4.019-5.097C270.306,285.12,269.203,287.808,269.632,290.873z"
          />
          <path
            fill="#695A6E"
            d="M260.25,314.25h2.917c0.413-7.462,0.409-22.264,0.127-24.283c-0.429-3.066-2.229-5.347-4.019-5.097
			c-1.79,0.251-2.893,2.939-2.464,6.005C257.086,292.843,258.785,307.051,260.25,314.25z"
          />
          <path
            fill="#695A6E"
            d="M267.203,262.405c-0.101,4.469,1.429,32.819,4.571,32.891c3.142,0.071,6.707-24.89,6.809-29.359
			c0.101-4.469-2.364-11.423-5.506-11.495S267.305,257.936,267.203,262.405z"
          />
          <path
            fill="#FAB9AF"
            d="M275.665,312.55l-0.021,5.819l1.01,3.306c0.07,0.228-0.101,0.458-0.339,0.458h-2.303v-0.736
			l-1.118,0.736c0,0-5.96,0.329-6.164,0c-0.767-1.241,2.7-1.718,5.237-2.7c2.536-0.982,0.786-10.613,0.786-10.613L275.665,312.55z"
          />
          <path
            fill="#415050"
            d="M262.963,318.833c-0.762,0.448-1.694,0.714-2.796,0.714c-0.377,0-0.729-0.037-1.067-0.095
			c-2.532,0.971-5.955,1.45-5.193,2.684c0.203,0.329,6.164,0,6.164,0l1.118-0.736v0.736h2.304c0.238,0,0.408-0.23,0.339-0.458
			L262.963,318.833z"
          />
          <path
            fill="#415050"
            d="M275.667,318.448c-0.836,0.628-1.919,1.015-3.251,1.015c-0.162,0-0.316-0.012-0.471-0.023
			c-2.535,0.977-5.982,1.455-5.217,2.693c0.203,0.329,6.164,0,6.164,0l1.118-0.736v0.736h2.303c0.238,0,0.408-0.23,0.339-0.458
			L275.667,318.448z"
          />
          <path
            fill="#FA968C"
            d="M274.126,225.536l-16.634-1.182c-3.315-0.236-5.891,2.84-5.078,6.062l7.186,28.473
			c0.858,3.399,3.961,5.745,7.465,5.645l0,0c3.654-0.105,6.696-2.836,7.194-6.457l3.804-27.689
			C278.399,227.937,276.594,225.712,274.126,225.536z"
          />
          <path
            fill="#51946F"
            d="M274.126,225.536l-5.093-0.362c0.372,1.91-0.609,4.12-3.113,4.284
			c-2.909,0.191-4.141-2.613-3.479-4.753l-4.949-0.352c-3.315-0.236-5.891,2.84-5.078,6.062l7.186,28.473
			c0.858,3.399,3.961,5.745,7.465,5.645c3.654-0.105,9.858-1.428,10.355-5.049l0.642-29.096
			C278.399,227.937,276.594,225.712,274.126,225.536z"
          />
          <path
            fill="#69280F"
            d="M258.196,215.466c1.28,5.896,6.574,7.209,9.872,6.493c3.298-0.716,1.915-6.598,1.365-9.132
			c-0.55-2.534-3.492-3.306-6.79-2.591C259.346,210.952,257.646,212.932,258.196,215.466z"
          />
          <polygon
            fill="#FA968C"
            points="263.163,225.174 269.033,225.174 268.325,214.709 264.767,217.677 		"
          />
          <path
            fill="#FAB9AF"
            d="M278.374,238.75c4.908,5.171,11.055,7.316,12.302,6.122c1.304-1.248-1.762-6.866-6.391-11.96
			c-4.795-5.277-9.496-8.557-11.383-6.767C271.017,227.935,273.466,233.579,278.374,238.75z"
          />
          <path
            fill="#51946F"
            d="M284.286,232.911c-4.795-5.277-9.496-8.557-11.383-6.767c-1.789,1.698,0.327,6.862,4.737,11.802
			C277.64,237.947,283.423,235.736,284.286,232.911z"
          />
          <path
            fill="#FAB9AF"
            d="M281.383,243.876c-2.775,0.729-15.097,4.89-14.684,6.461c0.413,1.572,13.534-0.477,16.269-1.346
			c5.022-1.597,8.39-3.573,7.978-5.145C290.532,242.274,284.158,243.147,281.383,243.876z"
          />
          <path
            fill="#FAB9AF"
            d="M259.456,218.865c0.921,4.244,2.194,6.154,4.375,5.681c2.655-0.576,5.377-4.217,4.617-7.714
			c-0.759-3.497-2.744-5.184-5.399-4.608C260.394,212.801,258.697,215.368,259.456,218.865z"
          />
          <path
            fill="#FF9669"
            d="M268.06,215.482l-6.725,1.46c-0.709,0.154-1.413,0.074-2.036-0.184
			c0.177-2.395,1.665-4.08,3.751-4.533C265.335,211.729,267.122,212.913,268.06,215.482z"
          />
          <path
            fill="#69280F"
            d="M261.193,216.29l7.74-1.68l-0.335-1.543c-0.4-1.842-2.217-3.01-4.058-2.611l-7.76,1.684l0.33,1.522
			C257.511,215.516,259.34,216.692,261.193,216.29z"
          />
          <path
            fill="#69280F"
            d="M267.292,217.725L267.292,217.725l-0.835-3.85l-1.68,0.365l0.471,2.169
			C265.448,217.337,266.364,217.926,267.292,217.725z"
          />
          <path
            fill="#FAB9AF"
            d="M266.489,217.119c0.251,1.157,0.819,2.016,1.762,1.812c0.942-0.204,1.202-1.927,0.951-3.085
			c-0.251-1.157-0.918-1.311-1.86-1.106C266.398,214.944,266.238,215.962,266.489,217.119z"
          />
          <path
            fill="#A5C8CD"
            d="M264.717,250.768h-8.586c-0.576,0-1.062-0.432-1.129-1.004l-1.415-12.038
			c-0.079-0.676,0.449-1.27,1.129-1.27h8.586c0.577,0,1.062,0.431,1.129,1.004l1.415,12.038
			C265.926,250.174,265.398,250.768,264.717,250.768z"
          />
          <path
            fill="#C8E6EB"
            d="M264.217,251.241h-8.586c-0.577,0-1.062-0.432-1.129-1.004l-1.415-12.038
			c-0.079-0.676,0.449-1.27,1.129-1.27h8.586c0.577,0,1.062,0.432,1.129,1.004l1.415,12.038
			C265.426,250.647,264.898,251.241,264.217,251.241z"
          />
          <path
            fill="#FAB9AF"
            d="M266.69,247.121c0,0,2.108,1.744,1.788,2.564c-0.319,0.82-0.684,1.312-1.894,0.892
			c-1.211-0.42-2.671-1.573-3.409-2.439c-0.738-0.867-1.36-2.841-1.287-3.096c0.265-0.93,1.436,0.78,2.044,1.581
			c0.607,0.8,1.373,1.644,1.676,1.243c0.302-0.401-0.976-1.847-0.782-2.421C265.02,244.87,266.69,247.121,266.69,247.121z"
          />
          <path
            fill="#695A6E"
            d="M273.042,314.25h2.875c0.413-7.462,0.481-22.266,0.198-24.285
			c-0.429-3.065-2.228-5.347-4.019-5.097c-1.79,0.251-2.893,2.939-2.464,6.005C269.908,292.841,271.577,307.05,273.042,314.25z"
          />
        </g>
      </g>
    </svg>
  );
};
