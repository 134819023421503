import { Box, Spinner } from "@chakra-ui/react";
import { UserMultistep } from "../../components/form/user-multi-step";
import { useQuery } from "@tanstack/react-query";
import { authApi } from "../../services/auth-api/auth-api";
import { Icons } from "../../images/svg";

const Profile = () => {
  const currentUserData = useQuery(["currentUser"], () =>
    authApi.currentUser()
  );

  if (currentUserData.isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (currentUserData.isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        pt={{ base: "140px", md: "90px", xl: "90px" }}
      >
        <Icons.Error width={500} height={500} />
      </Box>
    );
  }

  return (
    <Box pt={{ base: "140px", md: "90px", xl: "90px" }}>
      <UserMultistep />
    </Box>
  );
};

export default Profile;
