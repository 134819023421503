import { Box, Spinner, Stack, Text } from "@chakra-ui/react";
import { sortOrdersByStartDate } from "../../utils/utils";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { historyApi } from "../../services/history-api/history-api";
import { Icons } from "../../images/svg";
import { OrderHistoryCard } from "../../components/card/order-card/OrderHistoryCard";
import { useTranslation } from "react-i18next";
import { useContext, useEffect } from "react";
import { LanguageContext } from "../../contexts/languageContext";

const History = () => {
  const { language } = useContext(LanguageContext);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const history = useQuery(["getHistoryByUserId", language], () =>
    historyApi.getHistoryByUserId({ lang: language })
  );

  // Subscriptions
  useEffect(() => {
    const subscription = historyApi.onHistoryOrderUpdated().subscribe({
      next: (e: any) => {
        queryClient.invalidateQueries(["getHistoryByUserId", language]);
      },
      error: (error: any) => {
        console.error("History Order Subscription error", error);
      },
    });
    return () => {
      subscription.unsubscribe();
    };
  }, [language, queryClient]);

  if (history.isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (history.isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        pt={{ base: "140px", md: "90px", xl: "90px" }}
      >
        <Icons.Error width={500} height={500} />
      </Box>
    );
  }

  if (history.isSuccess && history.data.length === 0) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="50%"
        pt={{ base: "140px", md: "90px", xl: "90px" }}
      >
        <Text fontSize="2xl" fontWeight="bold">
          {t("history.emptyOrders")}
        </Text>
      </Box>
    );
  }

  const orders = sortOrdersByStartDate(history.data);

  return (
    <Box pt={{ base: "140px", md: "90px", xl: "90px" }}>
      <Stack>
        {orders.map((order) => (
          <OrderHistoryCard key={order.orderId} order={order} />
        ))}
      </Stack>
    </Box>
  );
};

export default History;
