import { z } from "zod";

const Info = z.object({
  __typename: z.literal("Info"),
  description: z.string(),
  navigateTo: z.union([z.string(), z.null()]),
  priority: z.number(),
  title: z.string(),
  status: z.union([z.literal("DRAFT"), z.literal("LIVE")]),
});

export const HomeCardSchema = z.object({
  __typename: z.literal("HomeCard"),
  color: z.array(z.string()),
  homeCardId: z.string(),
  info: z.union([z.array(Info), z.null()]),
  name: z.string(),
  status: z.union([z.literal("DRAFT"), z.literal("LIVE")]),
  priority: z.number(),
  type: z.union([
    z.literal("order"),
    z.literal("newProduct"),
    z.literal("discount"),
    z.literal("interess"),
    z.literal("mostPurchase"),
    z.literal("info"),
  ]),
  users: z.union([z.array(z.string()), z.null()]),
});
