import React from "react";

export default interface IIcon {
  width?: number;
  height?: number;
  color?: string;
  strokeWidth?: number;
  "data-testid"?: string;
}

export interface ExtendedIIcon extends IIcon {
  strokeWidth?: number;
}

export const Bestseller: React.FC<IIcon> = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <g>
        <path
          fill="#FF9600"
          d="M161.025,48.83c-12.119-2.27-25.117,0.707-35.297,9.249c-10.179,8.542-15.364,20.827-15.236,33.156
		c-12.163,2.014-23.359,9.253-30.007,20.764c-6.643,11.508-7.315,24.826-2.98,36.365c-10.739,6.052-18.784,16.687-21.091,29.774
		c-2.308,13.088,1.615,25.834,9.637,35.194c-8.022,9.359-11.946,22.103-9.637,35.191c2.307,13.088,10.352,23.722,21.091,29.774
		c-4.335,11.539-3.663,24.857,2.98,36.367c6.644,11.508,17.843,18.75,30.005,20.764c-0.128,12.327,5.059,24.609,15.239,33.153
		c10.18,8.542,23.178,11.517,35.297,9.249c4.096,11.627,13.169,21.398,25.658,25.944c12.489,4.544,25.72,2.893,36.331-3.38
		c7.825,9.522,19.696,15.601,32.986,15.603c13.291-0.002,25.16-6.081,32.983-15.603c10.612,6.274,23.845,7.925,36.334,3.38
		c12.49-4.546,21.563-14.316,25.659-25.944c12.116,2.268,25.115-0.708,35.296-9.249c10.179-8.544,15.367-20.827,15.237-33.153
		c12.162-2.014,23.36-9.256,30.005-20.764c6.643-11.511,7.316-24.826,2.979-36.367c10.739-6.052,18.785-16.686,21.094-29.774
		s-1.617-25.834-9.637-35.191c8.021-9.36,11.946-22.105,9.637-35.194c-2.308-13.088-10.352-23.722-21.094-29.774
		c4.337-11.538,3.664-24.857-2.979-36.365c-6.644-11.511-17.842-18.75-30.005-20.764c0.129-12.329-5.058-24.614-15.237-33.156
		c-10.181-8.542-23.179-11.517-35.294-9.249c-4.096-11.628-13.171-21.398-25.661-25.942c-12.488-4.547-25.721-2.895-36.334,3.378
		c-7.824-9.523-19.693-15.6-32.984-15.6c-13.29,0-25.161,6.077-32.986,15.601c-10.61-6.274-23.845-7.925-36.331-3.378
		C174.194,27.433,165.121,37.203,161.025,48.83z"
        />
        <circle fill="#FFD72E" cx="256.002" cy="213.336" r="149.335" />
        <polygon
          fill="#FF6B3E"
          points="255.999,142.32 234.843,191.709 181.334,196.566 221.767,231.952 209.852,284.344 
		255.999,256.82 302.147,284.344 290.232,231.95 330.668,196.566 277.154,191.709 	"
        />
      </g>
      <g>
        <path
          fill="#000003"
          d="M459.098,213.332c6.735-11.011,9.273-24.113,6.992-37.046c-2.279-12.932-9.146-24.377-19.242-32.42
		c2.564-12.65,0.468-25.83-6.098-37.2c-6.564-11.372-16.929-19.778-29.169-23.884c-1.917-12.766-8.396-24.435-18.455-32.875
		c-9.928-8.328-22.596-12.777-35.574-12.461c-6.169-11.342-16.25-20.092-28.588-24.582c-5.886-2.143-12.021-3.229-18.238-3.229
		c-6.669,0-13.271,1.261-19.456,3.685C281.598,4.776,269.134,0,255.999,0c-13.134,0-25.598,4.776-35.273,13.322
		c-6.186-2.425-12.786-3.685-19.455-3.685c-6.217,0-12.353,1.086-18.235,3.228c-12.339,4.49-22.421,13.24-28.588,24.582
		c-12.965-0.331-25.65,4.132-35.576,12.462c-10.059,8.44-16.536,20.109-18.454,32.874c-12.238,4.106-22.605,12.512-29.173,23.885
		c-6.563,11.372-8.66,24.551-6.098,37.199c-10.095,8.043-16.96,19.487-19.24,32.42c-2.282,12.932,0.256,26.035,6.992,37.046
		c-6.735,11.009-9.273,24.112-6.993,37.043c2.281,12.932,9.146,24.377,19.241,32.421c-2.562,12.649-0.466,25.829,6.099,37.204
		c6.565,11.372,16.932,19.778,29.169,23.883c1.919,12.762,8.397,24.429,18.456,32.872c9.928,8.329,22.624,12.776,35.576,12.461
		c6.168,11.341,16.248,20.092,28.587,24.584c5.885,2.141,12.019,3.227,18.235,3.227c6.669,0,13.271-1.261,19.457-3.685
		c9.674,8.546,22.139,13.323,35.27,13.325c0.001,0,0.003,0,0.004,0c13.132-0.002,25.595-4.778,35.269-13.325
		c6.188,2.426,12.789,3.685,19.458,3.685c6.217,0,12.35-1.086,18.236-3.227c12.339-4.492,22.418-13.243,28.588-24.584
		c12.948,0.325,25.648-4.132,35.576-12.462c10.059-8.441,16.536-20.109,18.455-32.871c12.238-4.105,22.604-12.511,29.171-23.884
		c6.562-11.372,8.658-24.552,6.096-37.202c10.096-8.044,16.962-19.488,19.244-32.42C468.371,237.444,465.834,224.34,459.098,213.332
		z M437.85,220.274c6.265,7.309,8.9,16.93,7.231,26.397c-1.668,9.467-7.438,17.607-15.827,22.334
		c-4.581,2.582-6.597,8.121-4.748,13.044c3.388,9.013,2.573,18.957-2.232,27.281c-4.806,8.325-13.01,14.002-22.509,15.575
		c-5.189,0.86-8.979,5.374-8.923,10.635c0.1,9.626-4.067,18.691-11.429,24.871c-5.755,4.828-13.05,7.487-20.546,7.487
		c-1.985,0-3.981-0.185-5.931-0.55c-5.171-0.971-10.275,1.979-12.024,6.94c-3.2,9.083-10.215,16.176-19.246,19.465
		c-8.981,3.267-19.075,2.297-27.257-2.539c-4.531-2.679-10.332-1.653-13.671,2.411c-6.111,7.438-15.128,11.706-24.741,11.708
		c-9.613-0.002-18.632-4.27-24.745-11.709c-2.09-2.543-5.144-3.894-8.248-3.894c-1.855,0-3.728,0.484-5.423,1.486
		c-8.179,4.836-18.279,5.804-27.253,2.54c-9.032-3.289-16.046-10.382-19.246-19.465c-1.748-4.96-6.854-7.904-12.021-6.941
		c-1.952,0.366-3.949,0.55-5.935,0.55c-7.494,0-14.791-2.659-20.544-7.486c-7.363-6.18-11.531-15.246-11.431-24.872
		c0.055-5.261-3.733-9.775-8.923-10.635c-9.499-1.572-17.704-7.249-22.509-15.572c-4.805-8.328-5.62-18.271-2.234-27.285
		c1.85-4.924-0.165-10.462-4.749-13.044c-8.387-4.726-14.155-12.867-15.824-22.334c-1.668-9.466,0.966-19.087,7.232-26.396
		c3.424-3.995,3.424-9.889,0-13.884c-6.266-7.31-8.901-16.932-7.232-26.4c1.669-9.467,7.438-17.607,15.824-22.334
		c4.584-2.582,6.6-8.121,4.749-13.044c-3.387-9.012-2.573-18.956,2.233-27.279c4.808-8.325,13.012-14.002,22.511-15.576
		c5.189-0.86,8.978-5.374,8.923-10.635c-0.1-9.63,4.066-18.696,11.427-24.873c5.755-4.828,13.05-7.487,20.543-7.487
		c1.985,0,3.982,0.186,5.935,0.551c5.179,0.967,10.277-1.978,12.024-6.941c3.2-9.083,10.213-16.176,19.247-19.463
		c8.973-3.269,19.071-2.3,27.251,2.538c4.529,2.678,10.331,1.652,13.671-2.411c6.114-7.438,15.133-11.705,24.747-11.705
		s18.631,4.267,24.74,11.705c3.338,4.065,9.139,5.089,13.671,2.412c8.179-4.836,18.273-5.808,27.257-2.537
		c9.034,3.285,16.048,10.379,19.248,19.462c1.748,4.962,6.852,7.912,12.024,6.941c1.95-0.365,3.946-0.55,5.932-0.55
		c7.493,0,14.789,2.658,20.544,7.486c7.362,6.178,11.53,15.244,11.429,24.873c-0.057,5.261,3.733,9.775,8.923,10.635
		c9.498,1.573,17.705,7.249,22.509,15.575c4.806,8.323,5.618,18.267,2.234,27.279c-1.852,4.925,0.164,10.463,4.748,13.045
		c8.387,4.726,14.156,12.867,15.825,22.333c1.668,9.467-0.966,19.089-7.233,26.401C434.425,210.386,434.427,216.281,437.85,220.274z
		"
        />
        <path
          fill="#000003"
          d="M299.126,81.543c5.596,1.831,11.622-1.222,13.454-6.821c1.831-5.599-1.222-11.623-6.821-13.455
		c-26.663-8.722-55.26-10.282-82.7-4.509C152.321,71.64,100.168,132.445,96.233,204.627c-0.321,5.883,4.189,10.911,10.071,11.232
		c0.198,0.011,0.394,0.016,0.59,0.016c5.625,0,10.332-4.402,10.642-10.087c3.41-62.557,48.609-115.257,109.916-128.154
		C251.235,72.632,276.017,73.983,299.126,81.543z"
        />
        <path
          fill="#000003"
          d="M405.693,210.803c-5.889-0.317-10.911,4.188-11.231,10.07
		c-3.41,62.556-48.609,115.256-109.916,128.153c-23.786,5.004-48.569,3.653-71.672-3.907c-5.596-1.831-11.621,1.221-13.455,6.819
		c-1.833,5.599,1.221,11.624,6.819,13.455c16.114,5.274,32.938,7.93,49.786,7.93c11.024,0,22.06-1.137,32.913-3.421
		c70.74-14.881,122.891-75.687,126.826-147.868C416.085,216.153,411.575,211.124,405.693,210.803z"
        />
        <path
          fill="#000003"
          d="M119.898,239.891c-1.132-5.78-6.729-9.555-12.515-8.421c-5.781,1.131-9.552,6.734-8.422,12.515
		c0.578,2.955,1.255,5.961,2.014,8.937c1.23,4.825,5.568,8.035,10.33,8.035c0.871,0,1.759-0.108,2.642-0.333
		c5.709-1.455,9.156-7.262,7.702-12.971C120.988,245.063,120.4,242.452,119.898,239.891z"
        />
        <path
          fill="#000003"
          d="M392.1,186.771c0.994,5.091,5.456,8.624,10.457,8.624c0.679,0,1.366-0.065,2.059-0.201
		c5.781-1.13,9.552-6.733,8.422-12.514c-0.581-2.972-1.261-5.981-2.017-8.945c-1.458-5.707-7.263-9.151-12.977-7.693
		c-5.707,1.459-9.152,7.268-7.692,12.976C391.007,181.589,391.595,184.197,392.1,186.771z"
        />
        <path
          fill="#000003"
          d="M362.666,490.666H149.329c-5.89,0-10.667,4.776-10.667,10.667c0,5.891,4.777,10.667,10.667,10.667
		h213.337c5.889,0,10.667-4.775,10.667-10.667C373.333,495.442,368.556,490.666,362.666,490.666z"
        />
        <path
          fill="#000003"
          d="M199.451,281.978c-0.947,4.162,0.679,8.486,4.131,10.995c1.862,1.354,4.064,2.037,6.271,2.037
		c1.885,0,3.775-0.499,5.464-1.506l40.682-24.266l40.683,24.266c3.665,2.186,8.28,1.978,11.733-0.531
		c3.452-2.509,5.079-6.833,4.131-10.995l-10.503-46.194l35.646-31.192c3.213-2.811,4.44-7.265,3.121-11.324
		c-1.318-4.059-4.929-6.941-9.179-7.327l-47.177-4.284l-18.65-43.541c-1.679-3.923-5.537-6.466-9.805-6.466
		c-4.268,0-8.125,2.544-9.805,6.466l-18.651,43.541l-47.174,4.284c-4.25,0.386-7.861,3.268-9.181,7.327
		c-1.318,4.059-0.092,8.512,3.12,11.323l35.647,31.197L199.451,281.978z M207.095,204.937l28.712-2.607
		c3.903-0.354,7.297-2.82,8.841-6.422l11.352-26.498l11.352,26.498c1.543,3.603,4.938,6.068,8.84,6.422l28.713,2.607l-21.694,18.983
		c-2.95,2.58-4.245,6.572-3.377,10.393l6.392,28.114l-24.759-14.768c-1.683-1.004-3.576-1.506-5.466-1.506
		c-1.89,0-3.781,0.502-5.465,1.506l-24.759,14.767l6.393-28.108c0.868-3.822-0.427-7.811-3.376-10.393L207.095,204.937z"
        />
      </g>
    </svg>
  );
};
