import {
  useDisclosure,
  Button,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Modal,
} from "@chakra-ui/react";
import React from "react";

interface OrderModalProps {
  children: React.ReactNode;
  modalTitle: string;
  component?: React.ReactNode;
  size?: string;
}

export const OrderModal: React.FC<OrderModalProps> = ({
  children,
  modalTitle,
  component,
  size = "xl",
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Button colorScheme="green" variant="link" onClick={onOpen}>
      {component}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        motionPreset="slideInBottom"
        size={size}
        scrollBehavior="inside"
      >
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(90deg)"
        />
        <ModalContent>
          <ModalHeader>{modalTitle}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>{children}</ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Button>
  );
};
