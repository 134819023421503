/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAddressByUserId = /* GraphQL */ `
  query GetAddressByUserId($userId: String!) {
    getAddressByUserId(userId: $userId) {
      addressId
      city
      country
      email
      formattedAddress
      info
      isDefault
      lat
      lng
      number
      phone
      recipient
      region
      street
      userId
      zipCode
      __typename
    }
  }
`;
export const getAllFinalOrdersBack = /* GraphQL */ `
  query GetAllFinalOrdersBack(
    $limit: Int
    $nextToken: String
    $status: String!
  ) {
    getAllFinalOrdersBack(
      limit: $limit
      nextToken: $nextToken
      status: $status
    ) {
      items {
        address {
          addressId
          city
          country
          email
          formattedAddress
          info
          isDefault
          lat
          lng
          number
          phone
          recipient
          region
          street
          userId
          zipCode
          __typename
        }
        endDate
        estimateDate
        info {
          en
          ita
          __typename
        }
        orderId
        orderName
        payment {
          creditCard {
            cardHolder
            cardNumber
            cvv
            expirationDate
            __typename
          }
          method
          paymentId
          status
          totalPrice
          totalProductsPrice
          totalShipping
          __typename
        }
        products {
          attribute
          certifications
          description {
            en
            ita
            __typename
          }
          discount {
            discountPercentage
            discountPrice
            __typename
          }
          format {
            boxType
            boxTypeQuantity
            boxWeight
            description {
              en
              ita
              __typename
            }
            formatQuantity
            formatType
            unit
            unitPrice
            unitQuantity
            __typename
          }
          isNew
          isSoldOut
          isThisYear
          line
          name {
            en
            ita
            __typename
          }
          price
          productId
          productStatus
          productType
          roleType
          selectedQuantity
          sizeType
          users
          __typename
        }
        role
        startDate
        status
        tracking {
          status
          update
          __typename
        }
        userId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getBoxByBannerId = /* GraphQL */ `
  query GetBoxByBannerId($bannerId: String!, $lang: String!) {
    getBoxByBannerId(bannerId: $bannerId, lang: $lang) {
      bannerId
      boxId
      boxStatus
      description
      products {
        attribute
        certifications
        description
        discount {
          discountPercentage
          discountPrice
          __typename
        }
        format {
          boxType
          boxTypeQuantity
          boxWeight
          description
          formatQuantity
          formatType
          unit
          unitPrice
          unitQuantity
          __typename
        }
        isNew
        isSoldOut
        isThisYear
        line
        name
        price
        productId
        productType
        selectedQuantity
        sizeType
        users
        __typename
      }
      status
      title
      __typename
    }
  }
`;
export const getBoxByBoxId = /* GraphQL */ `
  query GetBoxByBoxId($boxId: String!) {
    getBoxByBoxId(boxId: $boxId) {
      bannerId
      boxId
      boxStatus
      description {
        en
        ita
        __typename
      }
      products {
        attribute
        certifications
        description {
          en
          ita
          __typename
        }
        discount {
          discountPercentage
          discountPrice
          __typename
        }
        format {
          boxType
          boxTypeQuantity
          boxWeight
          description {
            en
            ita
            __typename
          }
          formatQuantity
          formatType
          unit
          unitPrice
          unitQuantity
          __typename
        }
        isNew
        isSoldOut
        isThisYear
        line
        name {
          en
          ita
          __typename
        }
        price
        productId
        productStatus
        productType
        roleType
        selectedQuantity
        sizeType
        users
        __typename
      }
      status
      title {
        en
        ita
        __typename
      }
      __typename
    }
  }
`;
export const getBoxes = /* GraphQL */ `
  query GetBoxes {
    getBoxes {
      bannerId
      boxId
      boxStatus
      description {
        en
        ita
        __typename
      }
      products {
        attribute
        certifications
        description {
          en
          ita
          __typename
        }
        discount {
          discountPercentage
          discountPrice
          __typename
        }
        format {
          boxType
          boxTypeQuantity
          boxWeight
          description {
            en
            ita
            __typename
          }
          formatQuantity
          formatType
          unit
          unitPrice
          unitQuantity
          __typename
        }
        isNew
        isSoldOut
        isThisYear
        line
        name {
          en
          ita
          __typename
        }
        price
        productId
        productStatus
        productType
        roleType
        selectedQuantity
        sizeType
        users
        __typename
      }
      status
      title {
        en
        ita
        __typename
      }
      __typename
    }
  }
`;
export const getCardByCardId = /* GraphQL */ `
  query GetCardByCardId($homeCardId: String!) {
    getCardByCardId(homeCardId: $homeCardId) {
      color
      homeCardId
      info {
        description {
          en
          ita
          __typename
        }
        navigateTo
        priority
        status
        title {
          en
          ita
          __typename
        }
        __typename
      }
      name {
        en
        ita
        __typename
      }
      priority
      status
      type
      users
      __typename
    }
  }
`;
export const getCartByUserId = /* GraphQL */ `
  query GetCartByUserId($lang: String!, $userId: String!) {
    getCartByUserId(lang: $lang, userId: $userId) {
      cartId
      products {
        attribute
        certifications
        description
        discount {
          discountPercentage
          discountPrice
          __typename
        }
        format {
          boxType
          boxTypeQuantity
          boxWeight
          description
          formatQuantity
          formatType
          unit
          unitPrice
          unitQuantity
          __typename
        }
        isNew
        isSoldOut
        isThisYear
        line
        name
        price
        productId
        productType
        selectedQuantity
        sizeType
        users
        __typename
      }
      userId
      __typename
    }
  }
`;
export const getCatalogByUser = /* GraphQL */ `
  query GetCatalogByUser($roleType: String!) {
    getCatalogByUser(roleType: $roleType) {
      attribute
      certifications
      description {
        en
        ita
        __typename
      }
      discount {
        discountPercentage
        discountPrice
        __typename
      }
      format {
        boxType
        boxTypeQuantity
        boxWeight
        description {
          en
          ita
          __typename
        }
        formatQuantity
        formatType
        unit
        unitPrice
        unitQuantity
        __typename
      }
      isNew
      isSoldOut
      isThisYear
      line
      name {
        en
        ita
        __typename
      }
      price
      productId
      productStatus
      productType
      roleType
      selectedQuantity
      sizeType
      users
      __typename
    }
  }
`;
export const getCatalogByUserId = /* GraphQL */ `
  query GetCatalogByUserId($lang: String!, $roleType: String!) {
    getCatalogByUserId(lang: $lang, roleType: $roleType) {
      attribute
      certifications
      description
      discount {
        discountPercentage
        discountPrice
        __typename
      }
      format {
        boxType
        boxTypeQuantity
        boxWeight
        description
        formatQuantity
        formatType
        unit
        unitPrice
        unitQuantity
        __typename
      }
      isNew
      isSoldOut
      isThisYear
      line
      name
      price
      productId
      productType
      selectedQuantity
      sizeType
      users
      __typename
    }
  }
`;
export const getCatalogByUserPaginated = /* GraphQL */ `
  query GetCatalogByUserPaginated(
    $limit: Int
    $nextToken: String
    $roleType: String!
  ) {
    getCatalogByUserPaginated(
      limit: $limit
      nextToken: $nextToken
      roleType: $roleType
    ) {
      items {
        attribute
        certifications
        description {
          en
          ita
          __typename
        }
        discount {
          discountPercentage
          discountPrice
          __typename
        }
        format {
          boxType
          boxTypeQuantity
          boxWeight
          description {
            en
            ita
            __typename
          }
          formatQuantity
          formatType
          unit
          unitPrice
          unitQuantity
          __typename
        }
        isNew
        isSoldOut
        isThisYear
        line
        name {
          en
          ita
          __typename
        }
        price
        productId
        productStatus
        productType
        roleType
        selectedQuantity
        sizeType
        users
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getFFByFFId = /* GraphQL */ `
  query GetFFByFFId($ffId: String!) {
    getFFByFFId(ffId: $ffId) {
      ff
      ffId
      users
      __typename
    }
  }
`;
export const getFFByUserId = /* GraphQL */ `
  query GetFFByUserId {
    getFFByUserId {
      ff
      ffId
      users
      __typename
    }
  }
`;
export const getFFs = /* GraphQL */ `
  query GetFFs {
    getFFs {
      ff
      ffId
      users
      __typename
    }
  }
`;
export const getFinalCartByUserId = /* GraphQL */ `
  query GetFinalCartByUserId($userId: String!) {
    getFinalCartByUserId(userId: $userId) {
      cartId
      products {
        attribute
        certifications
        description {
          en
          ita
          __typename
        }
        discount {
          discountPercentage
          discountPrice
          __typename
        }
        format {
          boxType
          boxTypeQuantity
          boxWeight
          description {
            en
            ita
            __typename
          }
          formatQuantity
          formatType
          unit
          unitPrice
          unitQuantity
          __typename
        }
        isNew
        isSoldOut
        isThisYear
        line
        name {
          en
          ita
          __typename
        }
        price
        productId
        productStatus
        productType
        roleType
        selectedQuantity
        sizeType
        users
        __typename
      }
      userId
      __typename
    }
  }
`;
export const getHistoryByUserId = /* GraphQL */ `
  query GetHistoryByUserId($lang: String!) {
    getHistoryByUserId(lang: $lang) {
      address {
        addressId
        city
        country
        email
        formattedAddress
        info
        isDefault
        lat
        lng
        number
        phone
        recipient
        region
        street
        userId
        zipCode
        __typename
      }
      endDate
      estimateDate
      info
      orderId
      orderName
      payment {
        creditCard {
          cardHolder
          cardNumber
          cvv
          expirationDate
          __typename
        }
        method
        paymentId
        status
        totalPrice
        totalProductsPrice
        totalShipping
        __typename
      }
      products {
        attribute
        certifications
        description
        discount {
          discountPercentage
          discountPrice
          __typename
        }
        format {
          boxType
          boxTypeQuantity
          boxWeight
          description
          formatQuantity
          formatType
          unit
          unitPrice
          unitQuantity
          __typename
        }
        isNew
        isSoldOut
        isThisYear
        line
        name
        price
        productId
        productType
        selectedQuantity
        sizeType
        users
        __typename
      }
      role
      startDate
      status
      tracking {
        status
        update
        __typename
      }
      userId
      __typename
    }
  }
`;
export const getHomeCards = /* GraphQL */ `
  query GetHomeCards {
    getHomeCards {
      color
      homeCardId
      info {
        description {
          en
          ita
          __typename
        }
        navigateTo
        priority
        status
        title {
          en
          ita
          __typename
        }
        __typename
      }
      name {
        en
        ita
        __typename
      }
      priority
      status
      type
      users
      __typename
    }
  }
`;
export const getHomeCardsByUserId = /* GraphQL */ `
  query GetHomeCardsByUserId($lang: String!) {
    getHomeCardsByUserId(lang: $lang) {
      color
      homeCardId
      info {
        description
        navigateTo
        priority
        status
        title
        __typename
      }
      name
      priority
      status
      type
      users
      __typename
    }
  }
`;
export const getOrderByOrderId = /* GraphQL */ `
  query GetOrderByOrderId($orderId: String!) {
    getOrderByOrderId(orderId: $orderId) {
      address {
        addressId
        city
        country
        email
        formattedAddress
        info
        isDefault
        lat
        lng
        number
        phone
        recipient
        region
        street
        userId
        zipCode
        __typename
      }
      endDate
      estimateDate
      info {
        en
        ita
        __typename
      }
      orderId
      orderName
      payment {
        creditCard {
          cardHolder
          cardNumber
          cvv
          expirationDate
          __typename
        }
        method
        paymentId
        status
        totalPrice
        totalProductsPrice
        totalShipping
        __typename
      }
      products {
        attribute
        certifications
        description {
          en
          ita
          __typename
        }
        discount {
          discountPercentage
          discountPrice
          __typename
        }
        format {
          boxType
          boxTypeQuantity
          boxWeight
          description {
            en
            ita
            __typename
          }
          formatQuantity
          formatType
          unit
          unitPrice
          unitQuantity
          __typename
        }
        isNew
        isSoldOut
        isThisYear
        line
        name {
          en
          ita
          __typename
        }
        price
        productId
        productStatus
        productType
        roleType
        selectedQuantity
        sizeType
        users
        __typename
      }
      role
      startDate
      status
      tracking {
        status
        update
        __typename
      }
      userId
      __typename
    }
  }
`;
export const getOrdersByUser = /* GraphQL */ `
  query GetOrdersByUser($userId: String!) {
    getOrdersByUser(userId: $userId) {
      address {
        addressId
        city
        country
        email
        formattedAddress
        info
        isDefault
        lat
        lng
        number
        phone
        recipient
        region
        street
        userId
        zipCode
        __typename
      }
      endDate
      estimateDate
      info
      orderId
      orderName
      payment {
        creditCard {
          cardHolder
          cardNumber
          cvv
          expirationDate
          __typename
        }
        method
        paymentId
        status
        totalPrice
        totalProductsPrice
        totalShipping
        __typename
      }
      products {
        attribute
        certifications
        description
        discount {
          discountPercentage
          discountPrice
          __typename
        }
        format {
          boxType
          boxTypeQuantity
          boxWeight
          description
          formatQuantity
          formatType
          unit
          unitPrice
          unitQuantity
          __typename
        }
        isNew
        isSoldOut
        isThisYear
        line
        name
        price
        productId
        productType
        selectedQuantity
        sizeType
        users
        __typename
      }
      role
      startDate
      status
      tracking {
        status
        update
        __typename
      }
      userId
      __typename
    }
  }
`;
export const getOrdersByUserId = /* GraphQL */ `
  query GetOrdersByUserId($lang: String!) {
    getOrdersByUserId(lang: $lang) {
      address {
        addressId
        city
        country
        email
        formattedAddress
        info
        isDefault
        lat
        lng
        number
        phone
        recipient
        region
        street
        userId
        zipCode
        __typename
      }
      endDate
      estimateDate
      info
      orderId
      orderName
      payment {
        creditCard {
          cardHolder
          cardNumber
          cvv
          expirationDate
          __typename
        }
        method
        paymentId
        status
        totalPrice
        totalProductsPrice
        totalShipping
        __typename
      }
      products {
        attribute
        certifications
        description
        discount {
          discountPercentage
          discountPrice
          __typename
        }
        format {
          boxType
          boxTypeQuantity
          boxWeight
          description
          formatQuantity
          formatType
          unit
          unitPrice
          unitQuantity
          __typename
        }
        isNew
        isSoldOut
        isThisYear
        line
        name
        price
        productId
        productType
        selectedQuantity
        sizeType
        users
        __typename
      }
      role
      startDate
      status
      tracking {
        status
        update
        __typename
      }
      userId
      __typename
    }
  }
`;
export const getProductByProductId = /* GraphQL */ `
  query GetProductByProductId($productId: String!) {
    getProductByProductId(productId: $productId) {
      attribute
      certifications
      description {
        en
        ita
        __typename
      }
      discount {
        discountPercentage
        discountPrice
        __typename
      }
      format {
        boxType
        boxTypeQuantity
        boxWeight
        description {
          en
          ita
          __typename
        }
        formatQuantity
        formatType
        unit
        unitPrice
        unitQuantity
        __typename
      }
      isNew
      isSoldOut
      isThisYear
      line
      name {
        en
        ita
        __typename
      }
      price
      productId
      productStatus
      productType
      roleType
      selectedQuantity
      sizeType
      users
      __typename
    }
  }
`;
export const getShippingCost = /* GraphQL */ `
  query GetShippingCost {
    getShippingCost {
      maxWeight
      minWeight
      shippingCostId
      shippingCostIta
      shippingCostOther
      __typename
    }
  }
`;
export const getUsersByCountryBack = /* GraphQL */ `
  query GetUsersByCountryBack($country: String!) {
    getUsersByCountryBack(country: $country) {
      country
      userId
      __typename
    }
  }
`;
