import {
  Cart,
  DeleteCartMutation,
  GetCartByUserIdQuery,
  LambdaUpdateCartMutation,
  Product,
} from "../../API";
import { deleteCart, lambdaUpdateCart } from "../../graphql/mutations";
import { getCartByUserId } from "../../graphql/queries";
import { CartSchema } from "../../models/cart/cart";
import { ArrayProductInputSchema } from "../../models/products";

import { getAppError } from "../../utils/error";
import { api, Api } from "../api";
import { z } from "zod";

interface CartApiInterface {
  updateCart: (i: { products: Product[]; role: string }) => Promise<Cart>;
  deleteCart: (i: { cartId: string; userId: string }) => Promise<Cart>;
  getCartByUserId: (i: {
    userId: string;
    lang: string;
  }) => Promise<Cart | null>;
}

const UpdateCartSchema = z.object({
  data: z.object({
    lambdaUpdateCart: CartSchema,
  }),
});

const DeleteCartSchema = z.object({
  data: z.object({
    deleteCart: CartSchema,
  }),
});

const GetCartByUserIdSchema = z.object({
  data: z.object({
    getCartByUserId: z.union([z.null(), CartSchema]),
  }),
});

export class CartApi implements CartApiInterface {
  // eslint-disable-next-line no-useless-constructor
  constructor(private api: Api) {}

  getCartByUserId = async (i: { userId: string; lang: string }) => {
    try {
      const queryResult = await this.api.query<GetCartByUserIdQuery>({
        query: getCartByUserId,
        variables: {
          userId: i.userId,
          lang: i.lang,
        },
      });

      // Validate the query result using Zod
      const validatedData = GetCartByUserIdSchema.parse(queryResult);

      return validatedData.data.getCartByUserId;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  updateCart = async (i: { products: Product[]; role: string }) => {
    try {
      const productsInput = ArrayProductInputSchema.parse(i.products);

      const queryResult = await this.api.query<LambdaUpdateCartMutation>({
        query: lambdaUpdateCart,
        variables: {
          products: productsInput,
          role: i.role,
        },
      });

      // Validate the query result using Zod
      const validatedData = UpdateCartSchema.parse(queryResult);

      return validatedData.data.lambdaUpdateCart;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  deleteCart = async (i: { cartId: string; userId: string }) => {
    try {
      const queryResult = await this.api.query<DeleteCartMutation>({
        query: deleteCart,
        variables: {
          cartId: i.cartId,
          userId: i.userId,
        },
      });

      // Validate the query result using Zod
      const validatedData = DeleteCartSchema.parse(queryResult);

      return validatedData.data.deleteCart;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };
}

export const cartApi = new CartApi(api);
