import React from "react";

export default interface IIcon {
  width?: number;
  height?: number;
  color?: string;
  strokeWidth?: number;
  "data-testid"?: string;
}

export interface ExtendedIIcon extends IIcon {
  strokeWidth?: number;
}

export const New: React.FC<IIcon> = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 505 505"
      fill="#000000"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />

      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <g id="SVGRepo_iconCarrier">
        {" "}
        <circle fill="#FFDD09" cx="252.5" cy="252.5" r="252.5" />{" "}
        <path
          fill="#FFFFFF"
          d="M412.3,277.1l15.6-15.6c5-5,5-13,0-18l-15.6-15.6c-3.6-3.6-4.7-9.1-2.8-13.8l8.5-20.4 c2.7-6.5-0.4-13.9-6.9-16.6l-20.4-8.5c-4.7-2-7.8-6.6-7.8-11.7v-22.1c0-7-5.7-12.7-12.7-12.7h-22.1c-5.1,0-9.8-3.1-11.7-7.8 l-8.5-20.4c-2.7-6.5-10.1-9.6-16.6-6.9l-20.4,8.5c-4.7,2-10.2,0.9-13.8-2.8L261.5,77c-5-5-13-5-18,0l-15.6,15.6 c-3.6,3.6-9.1,4.7-13.8,2.8l-20.4-8.5c-6.5-2.7-13.9,0.4-16.6,6.9l-8.5,20.4c-2,4.7-6.6,7.8-11.7,7.8h-22.1c-7,0-12.7,5.7-12.7,12.7 v22.1c0,5.1-3.1,9.8-7.8,11.7L93.9,177c-6.5,2.7-9.6,10.1-6.9,16.6l8.5,20.4c2,4.7,0.9,10.2-2.8,13.8L77,243.5c-5,5-5,13,0,18 l15.6,15.6c3.6,3.6,4.7,9.1,2.8,13.8l-8.5,20.4c-2.7,6.5,0.4,13.9,6.9,16.6l20.4,8.5c4.7,2,7.8,6.6,7.8,11.7v22.1 c0,7,5.7,12.7,12.7,12.7h22.1c5.1,0,9.8,3.1,11.7,7.8l8.5,20.4c2.7,6.5,10.1,9.6,16.6,6.9l20.4-8.5c4.7-2,10.2-0.9,13.8,2.8 l15.6,15.6c5,5,13,5,18,0l15.6-15.6c3.6-3.6,9.1-4.7,13.8-2.8l20.4,8.5c6.5,2.7,13.9-0.4,16.6-6.9l8.5-20.4c2-4.7,6.6-7.8,11.7-7.8 h22.1c7,0,12.7-5.7,12.7-12.7v-22.1c0-5.1,3.1-9.8,7.8-11.7l20.4-8.5c6.5-2.7,9.6-10.1,6.9-16.6l-8.5-20.4 C407.6,286.2,408.7,280.7,412.3,277.1z"
        />{" "}
        <circle fill="#FF7058" cx="252.5" cy="252.5" r="132.9" />{" "}
        <circle fill="#FFFFFF" cx="252.5" cy="252.5" r="115.4" />{" "}
        <g>
          {" "}
          <path
            fill="#FF7058"
            d="M199.8,226.7h11.5v51.6h-11.5L175.2,246v32.3h-11.5v-51.6h10.8l25.3,33.2V226.7z"
          />{" "}
          <path
            fill="#FF7058"
            d="M261.2,226.7V237h-25.7v10.6h23.1v9.8h-23.1v10.7H262v10.2h-38v-51.6L261.2,226.7L261.2,226.7z"
          />{" "}
          <path
            fill="#FF7058"
            d="M288.3,256.4l9.2-29.7h12l9.1,29.7l10.3-29.7h12.5l-18,51.6h-8.6l-11.3-35.9l-11.2,35.9h-8.6 l-18-51.6h12.5L288.3,256.4z"
          />{" "}
        </g>{" "}
      </g>
    </svg>
  );
};
