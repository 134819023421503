import { GetCatalogByUserIdQuery, Product } from "../../API";
import { getCatalogByUserId } from "../../graphql/queries";
import { ProductSchema } from "../../models/products";

import { getAppError } from "../../utils/error";
import { api, Api } from "../api";
import { z } from "zod";
import { Observable } from "zen-observable-ts";
import { onCatalogUpdated } from "../../graphql/subscriptions";

export type GraphQLSubscriptionResult<T> = {
  [key: string]: T;
};
interface CatalogApiInterface {
  getCatalogByUserId: (i: {
    lang: string;
    roleType: string;
  }) => Promise<Product[]>;
  onCatalogUpdated: () => Observable<{
    value: {
      data: GraphQLSubscriptionResult<"onCatalogUpdated">;
    };
  }>;
}

const GetCatalogByUserIdQuerySchema = z.object({
  data: z.object({
    getCatalogByUserId: z.array(ProductSchema),
  }),
});

export class CatalogApi implements CatalogApiInterface {
  // eslint-disable-next-line no-useless-constructor
  constructor(private api: Api) {}

  getCatalogByUserId = async (i: { lang: string; roleType: string }) => {
    try {
      // Fetch the data using your API
      const queryResult = await this.api.query<GetCatalogByUserIdQuery>({
        query: getCatalogByUserId,
        variables: i,
      });

      // Validate the query result using Zod
      const validatedData = GetCatalogByUserIdQuerySchema.parse(queryResult);

      return validatedData.data.getCatalogByUserId;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  onCatalogUpdated = () => {
    try {
      const result = this.api.subscription<
        GraphQLSubscriptionResult<"onCatalogUpdated">
      >({
        query: onCatalogUpdated,
      });
      return result;
    } catch (err) {
      const error = getAppError(err);
      console.log("[onCatalogUpdated] Error", error);
      throw new Error("[onCatalogUpdated] Error");
    }
  };
}

export const catalogApi = new CatalogApi(api);
