import React from "react";

export default interface IIcon {
  width?: number;
  height?: number;
  color?: string;
  strokeWidth?: number;
  "data-testid"?: string;
}

export interface ExtendedIIcon extends IIcon {
  strokeWidth?: number;
}

export const Shipping: React.FC<IIcon> = ({ width = 24, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="#C7CFD3"
        d="M504.505 377.843H171.057V115.342c0-26.6 21.563-48.163 48.163-48.163h237.123c26.6 0 48.163 21.563 48.163 48.163v262.501z"
      ></path>
      <path
        fill="#FF473E"
        d="M87.196 198.558h61.285c12.468 0 22.576 11.188 22.576 24.989v153.961H6.812v-75.8c0-8.864 2.664-17.477 7.573-24.481l44.302-63.211c6.834-9.752 17.358-15.458 28.509-15.458z"
      ></path>
      <path
        fill="#2B3B47"
        d="M15.467 420.116h482.79c7.354 0 13.315-5.961 13.315-13.315V384.22a6.712 6.712 0 0 0-6.712-6.712H6.908a4.756 4.756 0 0 0-4.756 4.755V406.8c0 7.354 5.962 13.316 13.315 13.316z"
      ></path>
      <path
        fill="#76DFFF"
        d="M37.741 282.906l38.847-55.394c.905-1.291 2.297-2.046 3.771-2.046h45.922c2.639 0 4.778 2.375 4.778 5.304v55.394c0 2.929-2.139 5.304-4.778 5.304H41.512c-3.977 0-6.214-5.079-3.771-8.562z"
      ></path>
      <path
        fill="#FFD469"
        d="M20.039 352.665H6.812v-35.062h13.227c6.202 0 11.229 5.027 11.229 11.229v12.603c0 6.203-5.027 11.23-11.229 11.23z"
      ></path>
      <path
        fill="#597B91"
        d="M128.69 432.253c0 22.227-18.018 40.245-40.245 40.245S48.2 454.48 48.2 432.253s18.018-40.245 40.245-40.245s40.245 18.019 40.245 40.245zm152.916-40.244c-22.227 0-40.245 18.018-40.245 40.245s18.018 40.245 40.245 40.245s40.245-18.018 40.245-40.245s-18.019-40.245-40.245-40.245zm112.35 0c-22.227 0-40.245 18.018-40.245 40.245s18.018 40.245 40.245 40.245c22.227 0 40.245-18.018 40.245-40.245s-18.018-40.245-40.245-40.245z"
      ></path>
      <path
        fill="#2B3B47"
        d="M114.823 432.253c0 14.568-11.81 26.378-26.378 26.378s-26.378-11.81-26.378-26.378s11.81-26.378 26.378-26.378s26.378 11.81 26.378 26.378zm166.783-26.377c-14.568 0-26.378 11.81-26.378 26.378s11.81 26.378 26.378 26.378s26.378-11.81 26.378-26.378s-11.81-26.378-26.378-26.378zm112.35 0c-14.568 0-26.378 11.81-26.378 26.378s11.81 26.378 26.378 26.378s26.378-11.81 26.378-26.378s-11.81-26.378-26.378-26.378z"
      ></path>
    </svg>
  );
};
