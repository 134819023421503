import {
  CloseButton,
  Flex,
  Text,
  HStack,
  Button,
  Input,
  Stack,
} from "@chakra-ui/react";
import { PriceTag } from "./price-tag";
import { CartProductMeta } from "./cart-product-meta";
import { calculatePrice } from "../../utils/utils";
import { Product } from "../../API";
import { useContextCatalog } from "../../contexts/catalog/catalog";

type CartItemProps = {
  product: Product;
};

const { REACT_APP_CDN } = process.env;

export const CartItem = (props: CartItemProps) => {
  const productId = props.product.productId;
  const { catalog, setCatalog } = useContextCatalog();
  const product = catalog.products.find(
    (item) => item.productId === productId && item.selectedQuantity > 0
  );

  if (!product) return null;

  const path = product.productType.toLowerCase() === "oil" ? "oil" : "olive";
  const image = `${REACT_APP_CDN}/${path}/${product.productId}.png`;

  return (
    <>
      <Stack>
        <CartProductMeta
          image={image}
          isGiftWrapping={false}
          product={product}
        />

        <Flex mb="4" align="center" width="full" justify="space-between">
          <HStack spacing={12}>
            <CloseButton
              aria-label={`Delete ${product.name} from cart`}
              onClick={() => {
                setCatalog((prevCatalog) => ({
                  ...prevCatalog,
                  products: prevCatalog.products.map((p) => {
                    if (p.productId === product.productId) {
                      return {
                        ...p,
                        selectedQuantity: 0,
                      };
                    }
                    return p;
                  }),
                }));
              }}
            />
            <PriceTag
              salePrice={
                product.discount
                  ? calculatePrice(product, !!product.discount)
                  : undefined
              }
              price={calculatePrice(product, false)}
              currency="€"
            />
          </HStack>
        </Flex>

        {product.isSoldOut ? (
          <Text>Out of stock</Text>
        ) : (
          <HStack maxW="200px" display="flex" flex={1}>
            <Button
              colorScheme="green.600"
              size="sm"
              _hover={{
                bg: "green.600",
                color: "white",
              }}
              variant="outline"
              isDisabled={product.isSoldOut || product.selectedQuantity === 0}
              onClick={() => {
                if (product.selectedQuantity > 0) {
                  setCatalog((prevCatalog) => ({
                    ...prevCatalog,
                    products: prevCatalog.products.map((p) => {
                      if (p.productId === product.productId) {
                        return {
                          ...p,
                          selectedQuantity: p.selectedQuantity - 1,
                        };
                      }
                      return p;
                    }),
                  }));
                }
              }}
            >
              -
            </Button>
            <Input
              textAlign={"center"}
              isDisabled={product.isSoldOut}
              borderColor="gray.500"
              onChange={(e) => {
                const value = parseInt(e.target.value);
                if (value >= 0 && value <= 100) {
                  setCatalog((prevCatalog) => ({
                    ...prevCatalog,
                    products: prevCatalog.products.map((p) => {
                      if (p.productId === product.productId) {
                        return {
                          ...p,
                          selectedQuantity: value,
                        };
                      }
                      return p;
                    }),
                  }));
                }
              }}
              value={product.selectedQuantity}
            />
            <Button
              colorScheme="teal"
              size="sm"
              _hover={{ bg: "green.600", color: "white" }}
              isDisabled={product.isSoldOut || product.selectedQuantity === 100}
              onClick={() => {
                setCatalog((prevCatalog) => ({
                  ...prevCatalog,
                  products: prevCatalog.products.map((p) => {
                    if (p.productId === product.productId) {
                      return {
                        ...p,
                        selectedQuantity: p.selectedQuantity + 1,
                      };
                    }
                    return p;
                  }),
                }));
              }}
            >
              +
            </Button>
          </HStack>
        )}
      </Stack>
    </>
  );
};
