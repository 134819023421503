import merge from "lodash/merge";
import sidebar from "./components/sidebar/it.json";
import adopt from "./pages/adopt/it.json";
import home from "./pages/home/it.json";
import history from "./pages/history/it.json";
import catalog from "./pages/catalog/it.json";
import cart from "./pages/cart/it.json";
import settings from "./pages/settings/it.json";
import app from "./pages/app/it.json";
import box from "./pages/box/it.json";

export const itTranslation = merge(
  sidebar,
  adopt,
  home,
  history,
  catalog,
  cart,
  settings,
  app,
  box
);
