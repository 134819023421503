import { createContext, useContext, useState } from "react";
import { Product } from "../../API";

export interface Catalog {
  products: Product[];
  cartId: string;
}

export const catalogDefaultData: Catalog = {
  products: [],
  cartId: "",
};

type CatalogContextProps = {
  catalog: Catalog;
  setCatalog: React.Dispatch<React.SetStateAction<Catalog>>;
};

const defaultState = {
  catalog: catalogDefaultData,
  setCatalog: () => null,
};

const CatalogContext = createContext<CatalogContextProps>(defaultState);

export const useContextCatalog = () => useContext(CatalogContext);

export const CatalogProvider = ({ children }: any) => {
  const [catalog, setCatalog] = useState(catalogDefaultData);

  return (
    <CatalogContext.Provider value={{ catalog, setCatalog }}>
      {children}
    </CatalogContext.Provider>
  );
};
