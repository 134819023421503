import {
  Address,
  AddressInput,
  GetAddressByUserIdQueryVariables,
  UpdateAddressMutationVariables,
} from "../../API";
import { lambdaUpdateAddress } from "../../graphql/mutations";
import { getAddressByUserId } from "../../graphql/queries";
import { AddressSchema } from "../../models/address";

import { getAppError } from "../../utils/error";
import { api, Api } from "../api";
import { z } from "zod";

interface AddressApiInterface {
  getAddressByUserId: (i: { userId: string }) => Promise<Address[]>;
  updateAddress: (address: AddressInput) => Promise<Address>;
}

const GetAddressByUserIdSchema = z.object({
  data: z.object({
    getAddressByUserId: z.array(AddressSchema),
  }),
});

const UpdateAddressSchema = z.object({
  data: z.object({
    lambdaUpdateAddress: AddressSchema,
  }),
});

export class AddressApi implements AddressApiInterface {
  // eslint-disable-next-line no-useless-constructor
  constructor(private api: Api) {}

  getAddressByUserId = async (i: { userId: string }) => {
    try {
      const queryResult =
        await this.api.query<GetAddressByUserIdQueryVariables>({
          query: getAddressByUserId,
          variables: i,
        });

      // Validate the query result using Zod
      const validatedData = GetAddressByUserIdSchema.parse(queryResult);

      return validatedData.data.getAddressByUserId;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  updateAddress = async (address: AddressInput) => {
    try {
      // Update the address
      const queryResult = await this.api.query<UpdateAddressMutationVariables>({
        query: lambdaUpdateAddress,
        variables: { address },
      });

      // Validate the query result using Zod
      const validatedData = UpdateAddressSchema.parse(queryResult);

      return validatedData.data.lambdaUpdateAddress;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };
}

export const addressApi = new AddressApi(api);
