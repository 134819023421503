/* eslint-disable no-useless-escape */
import { HomeCard, Info, Order, Product } from "../API";

export const addCardBasingOnType = (
  cards: HomeCard[],
  info: Info,
  type:
    | "order"
    | "newProduct"
    | "discount"
    | "interess"
    | "mostPurchase"
    | "info"
) => {
  const cardIndex = cards.findIndex((card) => card.type === type);

  if (cardIndex !== -1) {
    const card = cards[cardIndex];
    if (card.info) {
      if (!card.info.includes(info)) {
        card.info.push(info);
      }
    }
  }

  return cards;
};

export const joinHomeCards = (cards: HomeCard[]) => {
  const result: HomeCard[] = cards
    .sort((a, b) => {
      // Compare by users.length
      const usersA = a.users ? a.users.length : 0;
      const usersB = b.users ? b.users.length : 0;

      if (usersA > 0 && usersB === 0) {
        return -1; // a has users, b doesn't, a comes first
      } else if (usersA === 0 && usersB > 0) {
        return 1; // b has users, a doesn't, b comes first
      } else {
        return 0; // Both have users or both don't, maintain their original order
      }
    })
    .reduce((acc: HomeCard[], obj: HomeCard) => {
      const existingObj = acc.find((item) => item.type === obj.type);

      if (existingObj) {
        if (existingObj.info && obj.info) {
          existingObj.info.push(...obj.info);
        }
      } else {
        // If no existing object with the same type, add the current object to the accumulator
        acc.push(obj);
      }

      return acc;
    }, []);
  return result;
};

export const sortCardsByPriorityDescending = (cards: HomeCard[]) => {
  const sortedCards = cards.sort((a, b) => b.priority - a.priority);

  sortedCards.forEach((card) => {
    if (card.info) {
      card.info.sort((a, b) => b.priority - a.priority);
    }
  });

  return sortedCards;
};

export const fromClToL = (cl: number) => {
  return cl / 100;
};

export const formatPrice = (price: number) => {
  const cent = price / 100;
  return cent.toFixed(2);
};

export const calculatePrice = (product: Product, hasDiscount: boolean) => {
  const selectedQuantity =
    product.selectedQuantity === 0 ? 1 : product.selectedQuantity;
  const price =
    hasDiscount && product.discount
      ? product.discount.discountPrice
      : product.price;
  return formatPrice(selectedQuantity * price);
};

export const getImage = (product: Product) => {
  if (product.productType === "olive") {
    return `https://oleificiocuscunabucket.s3.eu-west-1.amazonaws.com/olive/${product.productId}.png`;
  }
  if (product.line === "Cuscunà") {
    const floatNumber = product.format.unitQuantity / 100;
    const formattedNumber = floatNumber.toString().replace(/[,\.]/g, "");
    return `https://oleificiocuscunabucket.s3.eu-west-1.amazonaws.com/cuscuna/${formattedNumber}/${product.productId}.png`;
  }
  if (product.line === "Natale") {
    const floatNumber = product.format.unitQuantity / 100;
    const formattedNumber = floatNumber.toString().replace(/[,\.]/g, "");
    return `https://oleificiocuscunabucket.s3.eu-west-1.amazonaws.com/natale/${formattedNumber}/${product.productId}.png`;
  }
  if (product.line === "Simone") {
    const floatNumber = product.format.unitQuantity / 100;
    const formattedNumber = floatNumber.toString().replace(/[,\.]/g, "");
    return `https://oleificiocuscunabucket.s3.eu-west-1.amazonaws.com/simone/${formattedNumber}/${product.productId}.png`;
  }
  if (product.line === "DOP") {
    const floatNumber = product.format.unitQuantity / 100;
    const formattedNumber = floatNumber.toString().replace(/[,\.]/g, "");
    return `https://oleificiocuscunabucket.s3.eu-west-1.amazonaws.com/dop/${formattedNumber}/${product.productId}.png`;
  }
  return "https://via.placeholder.com/300x300?text=Image+not+found";
};

export const sortOrdersByStartDate = (orders: Order[]): Order[] => {
  return orders.slice().sort((a, b) => {
    const startDateA = new Date(a.startDate).getTime();
    const startDateB = new Date(b.startDate).getTime();
    return startDateA - startDateB;
  });
};
