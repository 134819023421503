import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  Input,
  Spinner,
  Stack,
  Textarea,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { AddressCard } from "../../components/card/address-card/AddressCard";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addressApi } from "../../services/address-api/address-api";
import { Icons } from "../../images/svg";
import React, { useEffect, useState } from "react";
import AutoComplete from "react-google-autocomplete";
import { AddressInput } from "../../API";
import { useTranslation } from "react-i18next";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";

export const addressDefault: AddressInput = {
  street: "",
  number: "",
  email: "",
  city: "",
  region: "",
  zipCode: "",
  country: "",
  recipient: "",
  formattedAddress: "",
  lat: "",
  lng: "",
  info: "",
  addressId: "",
  userId: "",
  isDefault: false,
  phone: "",
};

const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env;

interface ISettingsProps {
  pt: boolean;
}

const Settings: React.FC<ISettingsProps> = (props) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const firstField = React.useRef();
  const queryClient = useQueryClient();
  const { user } = useAuthenticator((context) => [context.user]);

  const sub = user.username ? user.username : "";

  const [showAddressInfo, setShowAddressInfo] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const email = user?.attributes?.email;
  const [address, setAddress] = useState<AddressInput>({
    ...addressDefault,
    email: email ? email : "",
  });

  useEffect(() => {
    async function fetchData() {
      await Auth.currentAuthenticatedUser({ bypassCache: true });
    }
    fetchData();
  }, []);

  const { mutate: mutateUpdateAddress, isLoading: isLoadingUpdateAddress } =
    useMutation(
      ["updateAddress"],
      async (addressToUpdate: AddressInput) => {
        const finalAddress = {
          ...addressToUpdate,
          email: addressToUpdate.email ?? "",
        };
        const response = await addressApi.updateAddress(finalAddress);
        return response;
      },
      {
        onError: (error) => {
          console.log("[productCard-deleteCart]", error);
          toast({
            title: t("settings.updateAddressError.title"),
            description: t("settings.updateAddressError.message"),
            status: "error",
          });
        },
        onSuccess: async (response) => {
          setShowAddressInfo(false);
          setAddress(addressDefault);
          await queryClient.invalidateQueries(["getAddressByUserId", sub]);
          toast({
            title: t("settings.updateAddressSuccess.title"),
            description: t("settings.updateAddressSuccess.message"),
            status: "success",
          });
        },
      }
    );

  const addresses = useQuery(["getAddressByUserId", sub], () =>
    addressApi.getAddressByUserId({
      userId: sub,
    })
  );

  if (addresses.isLoading || isLoadingUpdateAddress) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Spinner size="xl" />
      </Box>
    );
  }

  if (addresses.isError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        pt={props.pt ? { base: "140px", md: "90px", xl: "90px" } : 0}
      >
        <Icons.Error width={500} height={500} />
      </Box>
    );
  }

  const addressesData = addresses.data ? addresses.data : [];

  return (
    <Box pt={props.pt ? { base: "140px", md: "90px", xl: "90px" } : 0}>
      <Stack>
        <HStack>
          <Heading>{t("settings.deliveryAddresses")}</Heading>
          <Button
            size="sm"
            borderRadius={24}
            colorScheme="blue"
            onClick={onOpen}
          >
            +
          </Button>
        </HStack>
        <AddressCard
          addresses={addressesData}
          chageDefaultAddress={mutateUpdateAddress}
        />
        <Drawer
          isOpen={isOpen}
          placement="right"
          initialFocusRef={firstField as any}
          onClose={onClose}
          size="md"
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader borderBottomWidth="1px">
              {t("settings.createNewAddress")}
            </DrawerHeader>

            <DrawerBody>
              <Stack spacing="24px">
                <Box>
                  <FormControl isRequired>
                    <FormLabel>{t("settings.nameAndSurname")}</FormLabel>
                    <Input
                      ref={firstField as any}
                      id="name"
                      placeholder={t("settings.setNameAndSurname") as string}
                      value={address.recipient}
                      onChange={(e) =>
                        setAddress((prev) => ({
                          ...prev,
                          recipient: e.target.value,
                        }))
                      }
                    />
                  </FormControl>
                </Box>
                <Box>
                  <FormControl isRequired>
                    <FormLabel>{t("settings.email")}</FormLabel>
                    <Input
                      ref={firstField as any}
                      id="email"
                      placeholder={t("settings.setEmail") as string}
                      value={address.email}
                      onChange={(e) =>
                        setAddress((prev) => ({
                          ...prev,
                          email: e.target.value,
                        }))
                      }
                    />
                  </FormControl>
                </Box>

                <Box>
                  <FormControl isRequired>
                    <FormLabel>{t("settings.address")}</FormLabel>
                    <AutoComplete
                      style={{
                        width: "100%",
                        height: "40px",
                        paddingLeft: 16,
                        marginTop: 2,
                        marginBottom: 2,
                        border: "1px solid #ccc",
                        borderRadius: 6,
                      }}
                      options={{
                        types: ["address"],
                      }}
                      placeholder={t("settings.setAddressWithNumber") as string}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                      apiKey={REACT_APP_GOOGLE_MAPS_API_KEY}
                      onPlaceSelected={(place) => {
                        setShowAddressInfo(true);
                        place.address_components.forEach((component: any) => {
                          if (component.types.includes("street_number")) {
                            setAddress((prev) => ({
                              ...prev,
                              number: component.long_name,
                            }));
                          }
                          if (component.types.includes("route")) {
                            setAddress((prev) => ({
                              ...prev,
                              street: component.long_name,
                            }));
                          }
                          if (component.types.includes("locality")) {
                            setAddress((prev) => ({
                              ...prev,
                              city: component.long_name,
                            }));
                          }
                          if (
                            component.types.includes(
                              "administrative_area_level_1"
                            )
                          ) {
                            setAddress((prev) => ({
                              ...prev,
                              region: component.long_name,
                            }));
                          }
                          if (component.types.includes("country")) {
                            setAddress((prev) => ({
                              ...prev,
                              country: component.long_name,
                            }));
                          }
                          if (component.types.includes("postal_code")) {
                            setAddress((prev) => ({
                              ...prev,
                              zipCode: component.long_name,
                            }));
                          }
                          if (component.types.includes("formatted_address")) {
                            setAddress((prev) => ({
                              ...prev,
                              formattedAddress: component.long_name,
                            }));
                          }
                        });
                      }}
                    />
                  </FormControl>
                </Box>
                {showAddressInfo && (
                  <>
                    <HStack>
                      <Input
                        ref={firstField as any}
                        id="street"
                        placeholder={t("settings.setAddress") as string}
                        value={address.street}
                        onChange={(e) =>
                          setAddress((prev) => ({
                            ...prev,
                            street: e.target.value,
                          }))
                        }
                      />
                      <Input
                        ref={firstField as any}
                        id="street_number"
                        placeholder={t("settings.setNumber") as string}
                        value={address.number}
                        onChange={(e) =>
                          setAddress((prev) => ({
                            ...prev,
                            number: e.target.value,
                          }))
                        }
                      />
                    </HStack>
                    <HStack>
                      <Input
                        ref={firstField as any}
                        id="zipCode"
                        placeholder={t("settings.setZipCode") as string}
                        value={address.zipCode}
                        onChange={(e) =>
                          setAddress((prev) => ({
                            ...prev,
                            zipCode: e.target.value,
                          }))
                        }
                      />
                      <Input
                        ref={firstField as any}
                        id="city"
                        placeholder={t("settings.setCity") as string}
                        value={address.city}
                        onChange={(e) =>
                          setAddress((prev) => ({
                            ...prev,
                            city: e.target.value,
                          }))
                        }
                      />
                    </HStack>
                    <HStack>
                      <Input
                        ref={firstField as any}
                        id="region"
                        placeholder={t("settings.setRegion") as string}
                        value={address.region}
                        onChange={(e) =>
                          setAddress((prev) => ({
                            ...prev,
                            region: e.target.value,
                          }))
                        }
                      />
                      <Input
                        ref={firstField as any}
                        id="country"
                        placeholder={t("settings.setCountry") as string}
                        value={address.country}
                        onChange={(e) =>
                          setAddress((prev) => ({
                            ...prev,
                            country: e.target.value,
                          }))
                        }
                      />
                    </HStack>
                    <Box>
                      <FormControl isRequired>
                        <FormLabel>{t("settings.phoneNumber")}</FormLabel>
                        <Input
                          ref={firstField as any}
                          id="phone"
                          placeholder={t("settings.setPhoneNumber") as string}
                          value={address.phone}
                          onChange={(e) =>
                            setAddress((prev) => ({
                              ...prev,
                              phone: e.target.value,
                            }))
                          }
                        />
                      </FormControl>
                    </Box>
                    <Box>
                      <FormLabel>{t("settings.info")}</FormLabel>
                      <Textarea
                        ref={firstField as any}
                        id="info"
                        placeholder={t("settings.setInfo") as string}
                        value={address.info}
                        onChange={(e) =>
                          setAddress((prev) => ({
                            ...prev,
                            info: e.target.value,
                          }))
                        }
                      />
                    </Box>
                  </>
                )}
              </Stack>
            </DrawerBody>

            <DrawerFooter borderTopWidth="1px">
              <Button
                variant="outline"
                mr={3}
                onClick={() => {
                  setShowAddressInfo(false);
                  setAddress(addressDefault);
                  onClose();
                }}
              >
                {t("settings.cancel")}
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => {
                  mutateUpdateAddress({
                    ...address,
                    userId: sub,
                    isDefault: false,
                    formattedAddress: `${address.street} ${address.number}, ${address.zipCode} ${address.city}, ${address.region}, ${address.country}`,
                  });
                  onClose();
                }}
                // recipient, street, number, city, region, zipCode, country, formattedAddress, phone must be filled
                isDisabled={
                  !(
                    address.recipient &&
                    address.street &&
                    address.number &&
                    address.city &&
                    address.region &&
                    address.zipCode &&
                    address.country &&
                    address.phone
                  )
                }
              >
                {t("settings.save")}
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </Stack>
    </Box>
  );
};

export default Settings;
