import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { enTranslation } from "./enTranslation";
import { itTranslation } from "./itTranslation";

export const resources = {
  en: {
    translation: enTranslation,
  },
  it: {
    translation: itTranslation,
  },
};

i18next.use(initReactI18next).init({
  debug: true,
  resources,
  fallbackLng: "it",
});
