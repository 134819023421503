import {
  Box,
  Flex,
  HStack,
  Link,
  Stack,
  useColorModeValue as mode,
  Button,
} from "@chakra-ui/react";
import { CartOrderSummary } from "./cart-order-summary";
import { useTranslation } from "react-i18next";
import Settings from "../../pages/settings/Settings";

interface ShoppingCartProps {
  removeAllToCart: () => void;
  addAllToCart: () => void;
  disableAddAllToCart: boolean;
}

export const ShoppingCart: React.FC<ShoppingCartProps> = ({
  removeAllToCart,
  addAllToCart,
  disableAddAllToCart,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      mx="auto"
      maxW={{ base: "3xl", lg: "7xl" }}
      px={{ base: "4", md: "8", lg: "12" }}
      py={{ base: "6", md: "8", lg: "12" }}
      bg="green.100"
      rounded="lg"
      // css={{
      //   "&::-webkit-scrollbar": {
      //     width: "0px",
      //     // background: "red",
      //   },
      //   // "&::-webkit-scrollbar-track": {
      //   //   background: "yellow",
      //   // },
      //   // "&::-webkit-scrollbar-thumb": {
      //   //   background: "blue",
      //   //   borderRadius: "6px",
      //   // },
      //   // "&::-webkit-scrollbar-thumb:hover": {
      //   //   background: "purple",
      //   // },
      // }}
    >
      <Stack
        direction={{ base: "column", lg: "row" }}
        align={{ lg: "flex-start" }}
        spacing={{ base: "8", md: "16" }}
      >
        <Stack spacing={{ base: "8", md: "10" }} flex="2">
          <Stack
            overflowY="scroll" // Set overflowY to "scroll"
            maxHeight="60vh" // Set a fixed height
            p={4}
            css={{
              "&::-webkit-scrollbar": {
                width: "0px",
                // background: "red",
              },
              // "&::-webkit-scrollbar-track": {
              //   background: "yellow",
              // },
              // "&::-webkit-scrollbar-thumb": {
              //   background: "blue",
              //   borderRadius: "6px",
              // },
              // "&::-webkit-scrollbar-thumb:hover": {
              //   background: "purple",
              // },
            }}
          >
            <Settings pt={false} />
          </Stack>

          <HStack
            position="fixed"
            bottom={0}
            left={{ base: 0, md: 60 }}
            right={0}
            p={4}
            zIndex="sticky"
            bgColor="green.200"
            px={{ base: "4", md: "20" }}
          >
            <Button
              flex={1}
              colorScheme="teal"
              variant="outline"
              onClick={removeAllToCart}
            >
              {t("cart.removeAllToCart") as string}
            </Button>
            <Button
              flex={1}
              colorScheme="teal"
              isDisabled={disableAddAllToCart}
              onClick={addAllToCart}
            >
              {t("cart.addToCart") as string}
            </Button>
          </HStack>
        </Stack>

        <Flex direction="column" align="center" flex="1">
          <CartOrderSummary />
          <HStack
            mt="6"
            fontWeight="semibold"
            mb={{
              base: "12",
              md: "0",
            }}
          >
            <p>{t("cart.or") as string}</p>
            <Link href="catalog" color={mode("green.500", "green.200")}>
              {t("cart.continueShopping") as string}
            </Link>
          </HStack>
        </Flex>
      </Stack>
    </Box>
  );
};
