import { GetHomeCardsByUserIdQueryVariables, HomeCard } from "../../API";
import { getHomeCardsByUserId } from "../../graphql/queries";
import { HomeCardSchema } from "../../models/home-card/home-card";
import { getAppError } from "../../utils/error";
import { api, Api } from "../api";
import { z } from "zod";
import { Observable } from "zen-observable-ts";
import { onHomeCardUpdated } from "../../graphql/subscriptions";

export type GraphQLSubscriptionResult<T> = {
  [key: string]: T;
};

interface RestaurantApiInterface {
  getHomeCardsByUserId: (i: { lang: string }) => Promise<HomeCard[]>;
  onHomeCardUpdated: () => Observable<{
    value: {
      data: GraphQLSubscriptionResult<"onHomeCardUpdated">;
    };
  }>;
}

const GetHomeCardsByUserIdQuerySchema = z.object({
  data: z.object({
    getHomeCardsByUserId: z.array(HomeCardSchema),
  }),
});

export class HomeApi implements RestaurantApiInterface {
  // eslint-disable-next-line no-useless-constructor
  constructor(private api: Api) {}

  getHomeCardsByUserId = async (i: { lang: string }) => {
    try {
      // Fetch the data using your API
      const queryResult =
        await this.api.query<GetHomeCardsByUserIdQueryVariables>({
          query: getHomeCardsByUserId,
          variables: i,
        });

      // Validate the query result using Zod
      const validatedData = GetHomeCardsByUserIdQuerySchema.parse(queryResult);

      return validatedData.data.getHomeCardsByUserId;
    } catch (error) {
      const errorMessage = getAppError(error);
      // Handle errors appropriately, e.g., logging and re-throwing
      console.error(errorMessage);
      throw new Error(errorMessage);
    }
  };

  onHomeCardUpdated = () => {
    try {
      const result = this.api.subscription<
        GraphQLSubscriptionResult<"onHomeCardUpdated">
      >({
        query: onHomeCardUpdated,
      });
      return result;
    } catch (err) {
      const error = getAppError(err);
      console.log("[onHomeCardUpdated] Error", error);
      throw new Error("[onHomeCardUpdated] Error");
    }
  };
}

export const homeApi = new HomeApi(api);
