// Chakra Imports
import { Box, Icon, Link } from "@chakra-ui/react";
import { useContext } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { LanguageContext } from "../../contexts/languageContext";

export default function FixedPlugin(props: { [x: string]: any }) {
  const { ...rest } = props;
  const { language } = useContext(LanguageContext);
  const whatsAppColor = "#25D366";
  const phone = "+393341149566";
  const textIta = "Ciao!%20Puoi%20aiutarmi?%20Ho%20una%20domanda.";
  const textEng = "Hello!%20Can%20you%20help%20me?%20I%20have%20a%20question.";
  const text = language === "en" ? textEng : textIta;
  const href = `https://wa.me/${phone}?text=${text}`;

  return (
    <Box
      {...rest}
      h="60px"
      w="60px"
      bg={whatsAppColor}
      zIndex="99"
      position="fixed"
      right={"35px"}
      bottom="80px"
      border="1px solid"
      borderColor="white"
      borderRadius="50px"
      display="flex"
      p="0px"
      alignItems="center"
      justifyContent="center"
    >
      <Link display="flex" href={href} isExternal>
        <Icon h="24px" w="24px" color="white" as={FaWhatsapp} />
      </Link>
    </Box>
  );
}
