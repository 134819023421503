import { PropsWithChildren, useState } from "react";
import {
  Authenticator as AmplifyAuthenticator,
  ThemeProvider as AmplifyThemeProvider,
  CheckboxField,
  Theme,
  useAuthenticator,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Box, Link, Select } from "@chakra-ui/react";

export const Authenticator: React.FC<PropsWithChildren> = ({ children }) => {
  const theme: Theme = {
    name: "Oleificio Cuscunà",
    tokens: {
      colors: {
        font: {
          secondary: { value: "{colors.brand.primary.90}" },
        },
        brand: {
          primary: {
            10: { value: "{colors.green.10}" },
            20: { value: "{colors.green.20}" },
            40: { value: "{colors.green.40}" },
            60: { value: "{colors.green.60}" },
            80: { value: "{colors.green.40}" },
            90: { value: "{colors.green.90}" },
            100: { value: "{colors.green.40}" },
          },
          secondary: {
            10: { value: "{colors.neutral.10}" },
            20: { value: "{colors.neutral.20}" },
            40: { value: "{colors.neutral.40}" },
            60: { value: "{colors.neutral.60}" },
            80: { value: "{colors.neutral.80}" },
            90: { value: "{colors.neutral.90}" },
            100: { value: "{colors.neutral.100}" },
          },
        },
      },
      radii: {
        small: { value: "0.75rem" },
      },
      components: {
        authenticator: {
          modal: {
            backgroundColor: { value: "{colors.green.20}" },
          },
          router: {
            borderWidth: { value: "0" },
          },
          state: {
            inactive: {
              backgroundColor: { value: "{colors.brand.primary.100}" },
            },
          },
        },
        tabs: {
          item: {
            borderColor: { value: "{colors.brand.primary.100}" },
            color: { value: "{colors.brand.secondary.20}" },
            _active: {
              backgroundColor: { value: "{colors.brand.primary.100}" },
              color: { value: "{colors.brand.secondary.10}" },
              borderColor: { value: "{colors.brand.secondary.40}" },
            },
            _hover: {
              color: { value: "{colors.brand.secondary.40}" },
            },
            _focus: {
              color: { value: "{colors.brand.secondary.40}" },
            },
          },
        },
      },
    },
  };

  return (
    <AmplifyThemeProvider theme={theme}>
      <AmplifyAuthenticator
        variation="modal"
        signUpAttributes={["given_name", "family_name", "phone_number"]}
        components={{
          Header: () => (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                background: "#77CA85",
                color: "#fff",
                py: "1rem",
                borderRadius: "1rem 1rem 0 0",
              }}
            ></Box>
          ),
          Footer: () => (
            <Box
              sx={{
                background: "#fff",
                borderRadius: " 0 0 1rem 1rem",
                py: "0.4rem",
              }}
            ></Box>
          ),
          SignUp: {
            FormFields() {
              const { validationErrors } = useAuthenticator();
              const [lang, setLang] = useState("ita");
              const privacyPolicyIta =
                "https://www.iubenda.com/privacy-policy/28264422";
              const privacyPolicyEng =
                "https://www.iubenda.com/privacy-policy/28730423";
              const cookiePolicyIta =
                "https://www.iubenda.com/privacy-policy/28264422/cookie-policy";
              const cookiePolicyEng =
                "https://www.iubenda.com/privacy-policy/28730423/cookie-policy";
              const termsConditionsIta =
                "https://www.iubenda.com/termini-e-condizioni/28264422";
              const termsConditionsEng =
                "https://www.iubenda.com/terms-and-conditions/28730423";

              return (
                <>
                  <AmplifyAuthenticator.SignUp.FormFields />

                  {/* Add select to choose language of policy */}
                  <Select
                    placeholder="Select language"
                    onChange={(e) => {
                      setLang(e.target.value);
                    }}
                  >
                    <option value="ita">Italiano</option>
                    <option value="eng">English</option>
                  </Select>

                  {/* Add link to policy */}
                  <Box
                    alignItems="center"
                    display="flex"
                    justifyItems="center"
                    justifyContent="space-around"
                    flex={1}
                  >
                    <Link
                      href={
                        lang === "ita" ? privacyPolicyIta : privacyPolicyEng
                      }
                      isExternal
                    >
                      Privacy Policy
                    </Link>

                    <Link
                      href={lang === "ita" ? cookiePolicyIta : cookiePolicyEng}
                      isExternal
                    >
                      Cookie Policy
                    </Link>

                    <Link
                      href={
                        lang === "ita" ? termsConditionsIta : termsConditionsEng
                      }
                      isExternal
                    >
                      Terms and Conditions
                    </Link>
                  </Box>

                  <CheckboxField
                    errorMessage={validationErrors.acknowledgement as string}
                    hasError={!!validationErrors.acknowledgement}
                    name="acknowledgement"
                    value="yes"
                    label="I agree with the Terms and Conditions"
                  />
                </>
              );
            },
          },
        }}
        services={{
          async validateCustomSignUp(formData) {
            if (!formData.acknowledgement) {
              return {
                acknowledgement: "You must agree to the Terms and Conditions",
              };
            }
          },
        }}
        formFields={{
          signIn: {
            username: {
              label: "Email",
              placeholder: "Enter your email",
            },
          },
          signUp: {
            username: {
              label: "Email",
              placeholder: "Enter your email",
              order: 4,
            },
            phone_number: {
              order: 3,
            },
            given_name: {
              label: "First name",
              placeholder: "Enter your first name",
              order: 1,
            },
            family_name: {
              label: "Last name",
              placeholder: "Enter your last name",
              order: 2,
            },
          },
        }}
      >
        {children}
      </AmplifyAuthenticator>
    </AmplifyThemeProvider>
  );
};
